import {configuration} from '../configuration'
import { ExternalUserForm, UserRoleApi } from '../models/administration';

// GET

export const getInternalRolesUrl = (): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/GetApplicationRoles` };
export const getExternalRolesUrl = (): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/GetApplicationRoles` };
export const getInternalUsersUrl = (projectCode: string, startWith?: string): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/ListAllDomainUser?projectCode=${projectCode}${(startWith) ? '&startWith=' + startWith + '&top=20' : '&startWith=&top=0'}` };
export const getExternalUsersUrl = (projectCode: string, startWith?: string): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/ListAllExternalUser?projectCode=${projectCode}${(startWith) ? '&startWith=' + startWith + '&top=20' : '&startWith=&top=0'}` };
export const getExternalUserDetailsUrl = (id: string): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/GetUserDetail?id=${id}` };
export const getSingleExternalUser = (projectCode: number, email: string): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/ListAllExternalUser?projectCode=${projectCode}${(email) ? '&startWith=' + email + '&top=20' : '&startWith=&top=0'}` };

// PUT

export const addRoleToInternalUserUrl = (userRole: UserRoleApi): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/AddRoleToUserApp?userId=${userRole.userId}&appRoleId=${userRole.appRoleId}${(userRole.appRoleAssignmentId) ? ('&appRoleAssignmentId=' + userRole.appRoleAssignmentId) : ''}` };
export const addRoleToExternalUserUrl = (userRole: UserRoleApi): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/AddRoleToUserApp?userId=${userRole.userId}&appRoleId=${userRole.appRoleId}${(userRole.appRoleAssignmentId) ? ('&appRoleAssignmentId=' + userRole.appRoleAssignmentId) : ''}` };

export const createInternalRoleUrl = (roleString: string): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/CreateAppRole?description=${roleString}&displayName=${roleString}&value=${roleString}` };
export const createExternalRoleUrl = (roleString: string): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/CreateAppRole?description=${roleString}&displayName=${roleString}&value=${roleString}` };


// POST

export const newExternalUserUrl = (userForm: ExternalUserForm ): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/CreateExternalUser?email=${userForm.email}&company=${userForm.company}&country=${userForm.country}` };
export const dowloadUsersUrl = (): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/DownloadAllUserReport` };

// DELETE
export const removeRoleToInternalUserUrl = (userRoleId: string): string => { return configuration.user_permissions_endpoint + `/ManageDomainUser/RemoveRoleToUserApp?appRoleAssignmentId=${userRoleId}` };
export const removeRoleToExternalUserUrl = (userRoleId: string): string => { return configuration.user_permissions_endpoint + `/ManageExternalUser/RemoveRoleToUserApp?appRoleAssignmentId=${userRoleId}` };
