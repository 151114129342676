import {DocumentActions, SET_ORDER_COLUMN} from "../actions/sort";
import _ from 'lodash';
import { SortColumn } from "../../models/sort";

interface SortState  {
    sorts: any
}

const initialState: SortState = {
    sorts:{
        default: { orderColumn: new SortColumn('firstColumn', 'desc') },
    }
}

export const sortReducer = (sort = initialState, action : DocumentActions): SortState => {
    switch (action.type) {
        
        case SET_ORDER_COLUMN:
            return {...sort, sorts: {...sort.sorts, [action.payload.feature]: {...sort.sorts[action.payload.feature],
                                         orderColumn: { 
                                             name: (action.payload.orderColumn.name) ? action.payload.orderColumn.name : sort.sorts[action.payload.feature].orderColumn.name,
                                             type: (action.payload.orderColumn.type) ? action.payload.orderColumn.type : sort.sorts[action.payload.feature].orderColumn.type,                                   
                                         }}}};
          
                                                       
        default:
            return sort;
    }
};

// select from state (read)
const selectSortState = (state: any) => state.sort as SortState;
export const getSorts= (state: any) => selectSortState(state).sorts;