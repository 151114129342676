import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom'

export interface PrivateRouteProps { 
  path: string 
  exact: boolean,
  classes?: any, 
  component?: any, 
  isAuthorized?: boolean
}

export default class PrivateRoute extends React.Component<PrivateRouteProps> {

  render() {
    const { component: Component, isAuthorized, ...rest} = this.props;

    if(isAuthorized === undefined || isAuthorized === true) {
      return <Route {...rest} component={Component} />
    }
    else {
      return <Redirect to={{pathname: "/landing"}}/>
    }
  }
}