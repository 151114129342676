import {getLoaders} from '../reducers/loader'
import _ from 'lodash';

export const getLoader = (name: string) => (state: any) => {
    let loaders = getLoaders(state);
    if(loaders[name])    
        return loaders[name];
    else
        return;
}
