export class EngPhase {
    id?: number
    name?: string
    code?: string

    constructor(object: any){
        this.id = object.engineeringPhaseId;
        this.name = object.engineeringPhaseName;
        this.code = object.engineeringPhaseCode;
    }
}