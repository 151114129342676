import {Actions, CommandActions, FETCH_GRID_OBJECTS, FETCH_STATUS_AND_LOAD_OBJECTS, FETCH_TYPES, FETCH_DISCIPINES_AND_DOCTYPES, FETCH_PURPOSES_CONFIG, FETCH_STAKEHOLDERS, FETCH_ALL_STAKEHOLDERS, FETCH_REPORTS, FETCH_PROJECT_REPORTS,
    GET_SHARED_DATA_AND_LOAD_PROJECTS, FETCH_PURPOSES, FETCH_ENTITIES, FETCH_ENG_PHASES, GET_PROJECT_SHARED_DATA_AND_LOAD_OBJECTS, FETCH_PBS, FETCH_FOLDERS, FETCH_RESERVE_NUMBERS_VALUES, FETCH_PROJECT_DATA } from "../../actions/sharedData";
import {GraphQlConfigActions, ApiConfigActions, ApiModels} from "@pwbapps/reduxcore";
import _ from 'lodash';
import { getHistoryName } from "../../../utils/functions";
import { CommandActions as LoaderCommandActions, DocumentActions as LoaderDocumentActions} from "../../actions/loader";
import { CommandActions as DocumentsCommandActions} from "../../actions/documents";
import { Status } from "../../../models/status";
import { CommandActions as ProjectsCommandActions, FETCH_PROJECTS, DocumentActions as ProjectsDocumentActions } from "../../actions/project";
import { getAllStakeHoldersUrl, getDisciplineAndDocTypesValuesUrl, getEngPhaseValuesUrl, getEntityValuesUrl, getFoldersUrl, getPbsValuesUrl, getProjectDataUrl, getProjectReportsUrl, getPurposeConfigUrl, getPurposeValuesUrl, getReportsUrl, getReserveNumberPbsUrl, getStakeHolderIdUrl, getStakeHoldersUrl, getStatusValuesUrl, getTypeValuesUrl } from "../../../utils/sharedUrls";
import { getSelectedProjectId } from "../../reducers/project";
import { Type } from "../../../models/type";
import { EngPhase } from "../../../models/engPhase";
import { Purpose, PurposeConfig } from "../../../models/purpose";
import { Entity } from "../../../models/entity";
import { Discipline, ReserveNumberDisciplineDocType } from "../../../models/discipline";
import { Equipment, Pbs, Plant, PlantSection, PlantUnit } from "../../../models/pbs";
import { CommandActions as MainCommandActions } from "../../actions/main";
import { getProjectsUrl } from "../../../utils/projectUrls";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { MessageBarType } from "office-ui-fabric-react";
import { getSelectedProject } from "../../selectors/project";
import { Folder, setFoldersPath } from "../../../models/folder";
import { ApplicationStakeHolder, StakeHolder } from "../../../models/stakeHolder";
import { getCompany } from "../../reducers/initialization";
import { GET_STAKEHOLDER_ID } from "../../actions/initialization";
import { getSelectedProjectUserActions } from "../../selectors/auth";
import { ModalCommandActions } from "../../actions/modal";
import { ReserveNumberForm } from "../../../models/document";
import { Report } from "../../../models/report";
import { CommandActions as NotifySettingsCommandActions } from "../../actions/notifySettings";
import { getLoader } from "../../selectors/loader";

export const sharedDataMiddlewareHistory = (history: any) => {
return sharedDataMiddleware(history);
}

export const sharedDataMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | GraphQlConfigActions.EventActions | ApiConfigActions.EventActions) => {
    next(action);
    let selectedProjectId: number | undefined = undefined; let historyName = null;

    switch (action.type) {

        case GET_SHARED_DATA_AND_LOAD_PROJECTS: {         
            selectedProjectId = getSelectedProjectId(getState());
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: true}));
            next(LoaderDocumentActions.setSharedLoaders({loading: true}));
            let requests: ApiModels.Request[] = [
                { method: 'GET', url: getProjectsUrl(), feature: FETCH_PROJECTS},
                { method: 'GET', url: getStatusValuesUrl(), feature: FETCH_STATUS_AND_LOAD_OBJECTS, returnObject: {fetchObjects: (selectedProjectId) ? true : false}},
                //{ method: 'GET', url: getTypeValuesUrl(), feature: FETCH_TYPES},
                { method: 'GET', url: getAllStakeHoldersUrl(), feature: FETCH_ALL_STAKEHOLDERS},
                { method: 'GET', url: getEngPhaseValuesUrl(), feature: FETCH_ENG_PHASES},
                { method: 'GET', url: getDisciplineAndDocTypesValuesUrl(), feature: FETCH_DISCIPINES_AND_DOCTYPES},
                { method: 'GET', url: getReportsUrl(), feature: FETCH_REPORTS}
            ];
            dispatch(ApiConfigActions.CommandActions.apiMultipleTokenRequest({requests, externalFeature: GET_SHARED_DATA_AND_LOAD_PROJECTS}));
            break;
        }

        case GET_PROJECT_SHARED_DATA_AND_LOAD_OBJECTS: {
            next(LoaderDocumentActions.setProjectSharedLoaders({loading: true}));
            let selectedProject = getSelectedProject(getState());
            let company = getCompany(getState());
            let requests: ApiModels.Request[] = [
                { method: 'GET', url: getProjectDataUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PROJECT_DATA, returnObject: {fetchObjects: action.payload.fetchObjects}},
                { method: 'GET', url: getPurposeValuesUrl(), feature: FETCH_PURPOSES},
                ...((company) ? [ { method: 'GET', url: getStakeHolderIdUrl(getSelectedProjectId(getState()) as number, getCompany(getState()) as string), feature: GET_STAKEHOLDER_ID} as ApiModels.Request]: []), 
            ];
            dispatch(LoaderCommandActions.setLoadingFolders({ feature: "projectShared", loading: false}));
            dispatch(ApiConfigActions.CommandActions.apiMultipleTokenRequest({requests, externalFeature: GET_PROJECT_SHARED_DATA_AND_LOAD_OBJECTS}));
            break;
        }

        case FETCH_GRID_OBJECTS: {
            historyName = getHistoryName(history);
            if(historyName === 'home'){
                dispatch(MainCommandActions.fetchMainData());
            }
            else if(historyName === 'projectsConfiguration'){
                dispatch(ProjectsCommandActions.fetchProjects({isConfigurationPage: true}));
            }
            else{
                dispatch(DocumentsCommandActions.fetchDocuments({setDefaultFilters: action.payload.setDefaultFilters}));    
            }
            break;
        }

        case FETCH_STATUS_AND_LOAD_OBJECTS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getStatusValuesUrl(), feature: FETCH_STATUS_AND_LOAD_OBJECTS, returnObject: {fetchObjects: action.payload.fetchObjects}}}));  
            break;

        case FETCH_TYPES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getTypeValuesUrl(), feature: FETCH_TYPES}}));
            break;
            
        case FETCH_ENG_PHASES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getEngPhaseValuesUrl(), feature: FETCH_ENG_PHASES}}));
            break;

        case FETCH_DISCIPINES_AND_DOCTYPES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getDisciplineAndDocTypesValuesUrl(), feature: FETCH_DISCIPINES_AND_DOCTYPES}}));
            break;

        case FETCH_PURPOSES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getPurposeValuesUrl(), feature: FETCH_PURPOSES, returnObject: {fetchObjects: action.payload.fetchObjects}}}));
            break;

        case FETCH_REPORTS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getReportsUrl(), feature: FETCH_REPORTS}}));
            break;
            
        case FETCH_ALL_STAKEHOLDERS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getAllStakeHoldersUrl(), feature: FETCH_ALL_STAKEHOLDERS}}));
            break;

        case FETCH_PROJECT_DATA:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getProjectDataUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PROJECT_DATA}}));
            break;

        case FETCH_PURPOSES_CONFIG:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getPurposeConfigUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PURPOSES_CONFIG, returnObject: {allPurposes: action.payload.allPurposes}}}));
            break;

        case FETCH_ENTITIES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getEntityValuesUrl(getSelectedProjectId(getState()) as number), feature: FETCH_ENTITIES}}));
            break;
        
        case FETCH_PBS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getPbsValuesUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PBS}}));
            break;

        case FETCH_STAKEHOLDERS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getStakeHoldersUrl(getSelectedProjectId(getState()) as number), feature: FETCH_STAKEHOLDERS}}));
            break;

        case FETCH_PROJECT_REPORTS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getProjectReportsUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PROJECT_REPORTS}}));
            break;

        case FETCH_RESERVE_NUMBERS_VALUES:
            historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getReserveNumberPbsUrl(), feature: FETCH_RESERVE_NUMBERS_VALUES}}));
            break;

        case FETCH_FOLDERS:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getFoldersUrl(getSelectedProjectId(getState()) as number), feature: FETCH_FOLDERS}}));
            break;


        case ApiConfigActions.API_SUCCESS:   
            apiSuccessMiddleware(history, dispatch, getState, next, action);        
            break;

        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, next, action);
            break;

        default:
            break;
    }
};


const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        case FETCH_STATUS_AND_LOAD_OBJECTS: {
            let fetchObjects = action.meta.returnObject.fetchObjects as boolean;
            let statusValuesPayload = (action.payload) ? action.payload.value: [];
            let statusValues = _.map(statusValuesPayload, svp => new Status(svp));
            next(CommandActions.setStatusValues({value: statusValues}));
            dispatch(LoaderCommandActions.setLoadingStatus({ feature: "shared", loading: false}));
            if(fetchObjects)
                dispatch(CommandActions.getProjectSharedDataAndLoadObjects({fetchObjects: true}));
            break;
        }

        // case FETCH_TYPES:
        //     let typesValuesPayload = (action.payload) ? action.payload.value: [];
        //     let typesValues = _.map(typesValuesPayload, t => new Type(t));
        //     next(CommandActions.setTypes({value: typesValues}));
        //     dispatch(LoaderCommandActions.setLoadingTypes({ feature: "shared", loading: false}));
        //     break;

        case FETCH_ENG_PHASES:
            let engPhaseValuesPayload = (action.payload) ? action.payload.value: [];
            let engPhaseValues = _.map(engPhaseValuesPayload, t => new EngPhase(t));
            next(CommandActions.setEngPhases({value: engPhaseValues}));
            dispatch(LoaderCommandActions.setLoadingEngPhases({ feature: "shared", loading: false}));
            break;

        case FETCH_REPORTS:
            let reportsPayload = (action.payload) ? action.payload.value: [];
            let reportsValues = _.map(reportsPayload, t => new Report(t));
            next(CommandActions.setAllReports({value: reportsValues}));
            dispatch(LoaderCommandActions.setLoadingAllReports({ feature: "shared", loading: false}));
            break;

        case FETCH_DISCIPINES_AND_DOCTYPES:
            let disciplineAndDocTypesPayload = (action.payload) ? action.payload.value: [];
            let disciplineAndDocTypesValues = _.map(disciplineAndDocTypesPayload, t => new Discipline(t));
            next(CommandActions.setDisciplinesAndDocTypes({value: disciplineAndDocTypesValues}));
            dispatch(LoaderCommandActions.setLoadingDisciplinesAndDocTypes({ feature: "shared", loading: false}));
            break;

        case FETCH_PURPOSES: {
            let purposeValuesPayload = (action.payload) ? action.payload.value: [];
            let purposeValues = _.map(purposeValuesPayload, t => new Purpose(t));
            next(CommandActions.setAllPurposes({value: purposeValues}));
            dispatch(CommandActions.fetchPurposeConfig({allPurposes: purposeValues}));
            break;
        }

        case FETCH_PURPOSES_CONFIG: { // aspettare che finisca questo prima di caricare i documenti
            let allPurposes = action.meta.returnObject.allPurposes as Purpose[];
            let purposeConfigPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].projectPurposes: [];
            let purposeConfigMapped= _.map(purposeConfigPayload, t => new PurposeConfig(t));
            let purposes: Purpose[] = []
            _.forEach(allPurposes, p => {
                let purposeConfig = _.find(purposeConfigMapped, pc => pc.purposeId === p.id);
                purposes = (purposeConfig) ? [...purposes, {...p, isWithApproval: purposeConfig.isWithApproval} ] : purposes;
            });
            next(CommandActions.setPurposes({value: purposes}));
            dispatch(LoaderCommandActions.setLoadingPurposesConfig({ feature: "projectShared", loading: false}));
            dispatch(LoaderCommandActions.setLoadingPurposes({ feature: "projectShared", loading: false}));
            break;
        }

        case FETCH_PROJECT_DATA: {
            let plantValuesPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].plants: [];
            let plantValues = _.map(plantValuesPayload, t => new Plant(t));
            let equipment: Equipment[] = [];
            let plantSections: PlantSection[] = [];
            let plantUnit: PlantUnit[] = [];
            let plant: Plant[] = [...plantValues];
            _.forEach(plantValues, p => {
                plantUnit =  [...plantUnit, ...p.plantUnits];
                _.forEach(p.plantUnits, pu => {
                    plantSections =  [...plantSections, ...pu.plantSections]
                    _.forEach(pu.plantSections, ps => {
                        equipment =  [...equipment, ...ps.equipment]
                    })
                })
            });
            let pbs: Pbs[] = [..._.map(plant, p => new Pbs(p)), ..._.map(plantUnit, p => new Pbs(p)), ..._.map(plantSections, p => new Pbs(p)), ..._.map(equipment, p => new Pbs(p))];
            next(CommandActions.setPbs({value: pbs}));
            let entityValuesPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].entities: [];
            let entityValues = _.map(entityValuesPayload, t => new Entity(t));
            next(CommandActions.setEntities({value: entityValues}));
            let stakeholdersPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].projectStakeHolders: []; 
            let stakeholdersMapped= _.map(stakeholdersPayload, t => new StakeHolder(t));
            next(CommandActions.setStakeHolders({value: stakeholdersMapped}));
            let reportsPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].reports: []; 
            let reportsMapped= _.map(reportsPayload, t => new Report(t));
            next(CommandActions.setReports({value: reportsMapped}));
            dispatch(NotifySettingsCommandActions.fetchEventTypes());

            dispatch(LoaderCommandActions.setLoadingProjectReports({ feature: "projectShared", loading: false}));
            dispatch(LoaderCommandActions.setLoadingStakeHolders({ feature: "projectShared", loading: false}));
            dispatch(LoaderCommandActions.setLoadingEntities({ feature: "projectShared", loading: false}));
            dispatch(LoaderCommandActions.setLoadingPbs({ feature: "projectShared", loading: false}));

            // fetch if loaders are disabled
            tryToFetchObjects(dispatch, getState, action);
            break;
        }

        case FETCH_ENTITIES: {
            let entityValuesPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].entities: [];
            let entityValues = _.map(entityValuesPayload, t => new Entity(t));
            next(CommandActions.setEntities({value: entityValues}));
            dispatch(LoaderCommandActions.setLoadingEntities({ feature: "projectShared", loading: false}));
            break;
        }

        case FETCH_PBS: {
            let plantValuesPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].plants: [];
            let plantValues = _.map(plantValuesPayload, t => new Plant(t));
            let equipment: Equipment[] = [];
            let plantSections: PlantSection[] = [];
            let plantUnit: PlantUnit[] = [];
            let plant: Plant[] = [...plantValues];
            _.forEach(plantValues, p => {
                plantUnit =  [...plantUnit, ...p.plantUnits];
                _.forEach(p.plantUnits, pu => {
                    plantSections =  [...plantSections, ...pu.plantSections]
                    _.forEach(pu.plantSections, ps => {
                        equipment =  [...equipment, ...ps.equipment]
                    })
                })
            });
            let pbs: Pbs[] = [..._.map(plant, p => new Pbs(p)), ..._.map(plantUnit, p => new Pbs(p)), ..._.map(plantSections, p => new Pbs(p)), ..._.map(equipment, p => new Pbs(p))];
            next(CommandActions.setPbs({value: pbs}));
            dispatch(LoaderCommandActions.setLoadingPbs({ feature: "projectShared", loading: false}));
            break;
        }

        case FETCH_RESERVE_NUMBERS_VALUES: {
            const historyName = getHistoryName(history);
            let plantValuesPayload = (action.payload) ? action.payload.plants : [];
            let plantUnitsValuesPayload = (action.payload) ? action.payload.plantUnits : [];
            let plantSectionsValuesPayload = (action.payload) ? action.payload.plantSections: [];
            let equipmentsValuesPayload = (action.payload) ? action.payload.equipments : [];

            let pbs: Pbs[] = [];
            pbs =  [
                ..._.map(plantValuesPayload, p => new Pbs(undefined, {object: p, type: 'Plant', payload: action.payload})),
                ..._.map(plantUnitsValuesPayload, p => new Pbs(undefined, {object: p, type: 'Plant Unit', payload: action.payload})),
                ..._.map(plantSectionsValuesPayload, p => new Pbs(undefined, {object: p, type: 'Plant Section', payload: action.payload})), 
                ..._.map(equipmentsValuesPayload, p => new Pbs(undefined, {object: p, type: 'Equipment', payload: action.payload}))
            ];
            next(CommandActions.setReserveNumbersPbs({value: pbs}));
    
            let discDocTypesValuesPayload = (action.payload) ? action.payload.discDocTypes : [];
            let discDocTypesMapped = _.map(discDocTypesValuesPayload, d => new ReserveNumberDisciplineDocType(d));
            next(CommandActions.setReserveNumbersDisciplines({value: discDocTypesMapped}));

            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            dispatch(ModalCommandActions.openModal({id: 'reserveNumber', contextItem: new ReserveNumberForm(1)}))
            break;
        }

        case FETCH_FOLDERS: {
            let foldersValuesPayload = (action.payload && action.payload.length > 0) ? action.payload : [];
            let foldersValues = _.map(foldersValuesPayload, t => new Folder(t));
            setFoldersPath(foldersValues);
            next(CommandActions.setFolders({value: foldersValues}));
            dispatch(LoaderCommandActions.setLoadingFolders({ feature: "projectShared", loading: false}));
            break;
        }

        case FETCH_STAKEHOLDERS: {
            let stakeholdersPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].projectStakeHolders: []; 
            let stakeholdersMapped= _.map(stakeholdersPayload, t => new StakeHolder(t));
            next(CommandActions.setStakeHolders({value: stakeholdersMapped}));
            dispatch(LoaderCommandActions.setLoadingStakeHolders({ feature: "projectShared", loading: false}));
            break;
        }

        case FETCH_ALL_STAKEHOLDERS: {
            let stakeholdersPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value : []; 
            let stakeholdersMapped= _.map(stakeholdersPayload, t => new ApplicationStakeHolder(t));
            next(CommandActions.setAllStakeHolders({value: stakeholdersMapped}));
            dispatch(LoaderCommandActions.setLoadingAllStakeHolders({ feature: "shared", loading: false}));
            break;
        }

        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case FETCH_STATUS_AND_LOAD_OBJECTS: {
            setTimeout(() => { dispatch(CommandActions.fetchStatusAndLoadObjects({fetchObjects: action.meta.returnObject.fetchObjects}))}, 5000);
            break;
        }

        case FETCH_TYPES:
            setTimeout(() => { dispatch(CommandActions.fetchTypes())}, 5000);
            break;

        case FETCH_REPORTS:
            setTimeout(() => { dispatch(CommandActions.fetchReports())}, 5000);
            break;

        case FETCH_PROJECT_DATA:
            setTimeout(() => { dispatch(CommandActions.fetchProjectData({fetchObjects: action.meta.returnObject.fetchObjects}))}, 5000);
            break;

        case FETCH_ENG_PHASES:
            setTimeout(() => { dispatch(CommandActions.fetchEngPhases())}, 5000);
            break;

        case FETCH_DISCIPINES_AND_DOCTYPES:
            setTimeout(() => { dispatch(CommandActions.fetchDisciplineAndDocTypes())}, 5000);
            break;

        case FETCH_PURPOSES: {
            setTimeout(() => { dispatch(CommandActions.fetchPurposes({}))}, 5000);
            break;
        }

        case FETCH_PURPOSES_CONFIG: {
            setTimeout(() => { dispatch(CommandActions.fetchPurposes({}))}, 5000);
            break;
        }

        case FETCH_ENTITIES: {
            setTimeout(() => { dispatch(CommandActions.fetchEntities())}, 5000);
            break;
        }

        case FETCH_FOLDERS: {
            setTimeout(() => { dispatch(CommandActions.fetchFolders())}, 5000);
            break;
        }

        case FETCH_STAKEHOLDERS: {
            setTimeout(() => { dispatch(CommandActions.fetchStakeHolders())}, 5000);
            break;
        }

        case FETCH_ALL_STAKEHOLDERS: {
            setTimeout(() => { dispatch(CommandActions.fetchAllStakeHolders())}, 5000);
            break;
        }

        case FETCH_PBS: {
            setTimeout(() => { dispatch(CommandActions.fetchPbs())}, 5000);
            break;
        }

        case FETCH_RESERVE_NUMBERS_VALUES: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            break;
        }

        case GET_SHARED_DATA_AND_LOAD_PROJECTS: {
            const historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: historyName, loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: action.payload}));
            break;
        }

        
        default:
            break;
    }
}

const tryToFetchObjects = (dispatch: any, getState: any, action: any) => {
    if(action.meta.returnObject.fetchObjects){       
        if(canFetchObjects(getState))
            dispatch(CommandActions.fetchGridObjects({setDefaultFilters: true}));                   
        else{
            var refreshIntervalId = setInterval(() => {
                if(canFetchObjects(getState)){
                    dispatch(CommandActions.fetchGridObjects({setDefaultFilters: true}));     
                    clearInterval(refreshIntervalId);
                }
            }, 500);     
        }      
    }
}

const canFetchObjects = (getState: any): boolean => {
    if(!getLoader('projectShared')(getState()).loadingPurposesConfig)
        return true;
    else
        return false;
}