export class Entity {
    id?: number
    name?: string

    constructor(object: any){
        this.id = object.entityId;
        this.name = object.entityName;
    }
}


export class EntityConfig {
    entityId?: number
    constructor(object: any){
        this.entityId = object.entityId;
    }
}