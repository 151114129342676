import _ from 'lodash'
import { InternalRolesType, ExternalRolesType } from '../utils/costants'
import { NotifyRule } from './notifyRule'

export class AppUser {
    id?: string
    name?: string
    email?: string
    company?: string
    country?: string
    role?: Role
    stakeHolderId?: number
    notifications?: NotifyRule[]
    notificationsToBeRemoved?: NotifyRule[]

    constructor( object: any, roles: Role[]){
        this.id = object.id;
        this.name = object.displayName;
        this.email = object.mail;
        this.company = object.company;
        this.country = object.country;
        this.role = (object.roles && object.roles.length > 0 && object.roles[0].roleValue) ? {..._.find(roles, r => r.azureName === object.roles[0].roleValue), userRoleId: object.roles[0].rolePrincipalId} : undefined;
        this.notifications = [];
        this.notificationsToBeRemoved = [];
    }  
}

export class Role {
    id?: string
    projectId?: string
    azureName?: string
    name?: InternalRolesType | ExternalRolesType
    description?: string
    userRoleId?: string
    constructor (object: any) { 
        this.id = object.id;
        this.azureName = object.displayName;
        this.projectId = (object.displayName && object.displayName.includes('_')) ? object.displayName.split('_')[0] : undefined;
        this.name = (object.displayName && object.displayName.includes('_')) ? object.displayName.split('_')[1] : undefined;
        this.description = object.description;
        this.userRoleId = object.userRoleId;
    }
}


export class UserRoleApi {
    userId?: string
    appRoleId?: string
    appRoleAssignmentId?: string

    constructor(object: AppUser){
        this.userId = object.id;
        this.appRoleId = (object.role) ? object.role.id : undefined;   
        this.appRoleAssignmentId = (object.role) ? object.role.userRoleId : undefined;      
    }
}

export class ExternalUserForm {
    email?: string
    company?: string
    country?: string
    role?: Role
    notifications?: NotifyRule[]
    errors?: {
        email?: string
        company?: string
        country?: string
        role?: string
    }
    constructor(object?: any) {
        this.email = object.email;
        this.company = object.company;
        this.country = object.country;
        this.role = object.role;
        this.notifications = [];
        this.errors = (object.errors) ? object.errors : { email: undefined, country: undefined, company: undefined, role: undefined};
    }
}

