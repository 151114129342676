import {FEATURE, DocumentActions, SET_VALUE} from '../actions/documents';
import _ from 'lodash';
import { Document } from '../../models/document';
import { GridDataConfigReducer, GridDataConfigActions,  } from '@pwbapps/reduxcore';
import { v4 as uuidv4 } from 'uuid';

interface DocumentsState extends GridDataConfigReducer.GridDataState<Document>  {
    fetchGuid: string
    documentsToUpload: number
    documentsToUploadTotal: number
    total: number,
    skip: number
}

let initialState = {
    ...GridDataConfigReducer.initialState as GridDataConfigReducer.GridDataState<Document>,  
    orderColumn: {name: 'documentId', direction: 'desc'},
    documentsToUpload: 0,
    documentsToUploadTotal: 0,
    fetchGuid: uuidv4(),
    total: 0,
    skip: 0
} 

export const documentsReducer = (documents = initialState, action : DocumentActions): DocumentsState => {
    switch (action.type) {
        case GridDataConfigActions.SET_DATA:
        case GridDataConfigActions.SET_SORT_COLUMN:
        case GridDataConfigActions.SET_SELECTED_ITEMS_IDS:
                return {...documents, ...GridDataConfigReducer.gridDataReducer(documents, action, FEATURE) };
    

        case SET_VALUE:
            return {...documents, [action.payload.name]: action.payload.value };

        default:
            return documents;
    }
};

//select from state (read) 
const selectDocumentsState = (state: any) => state.documents as DocumentsState;
export const getDocuments = (state: any) => selectDocumentsState(state).data;
export const getOrderColumn = (state: any) => GridDataConfigReducer.getOrderColumn(selectDocumentsState(state));
export const getSelectedItemsIds = (state: any) => GridDataConfigReducer.getSelectedItemsIds(selectDocumentsState(state));
export const getDocumentsToUpload = (state: any) => selectDocumentsState(state).documentsToUpload;
export const getDocumentsToUploadTotal = (state: any) => selectDocumentsState(state).documentsToUploadTotal;
export const getFetchGuid = (state: any) => selectDocumentsState(state).fetchGuid;
export const getTotal = (state: any) => selectDocumentsState(state).total;
export const getSkip = (state: any) => selectDocumentsState(state).skip;