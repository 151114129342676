import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { getColumns } from './stakeHolders.columns';
import { ComboBox, ConstrainMode, DefaultButton, DetailsList, DetailsListLayoutMode, FontIcon, FontSizes, IComboBoxOption, IDetailsHeaderProps, IRenderFunction, ScrollablePane, ScrollbarVisibility, SelectionMode, Sticky, StickyPositionType, Text, Toggle} from 'office-ui-fabric-react';
import _ from 'lodash';
import { DocumentActions as ProjectDocumentActions, ProjectsCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getDarkModeEnabled, getIsEdit, getProjectFormPurposes, getProjectFormStakeholders, getSelectedProjectId } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';
import { ApplicationStakeHolder, StakeHolder } from '../../../../models/stakeHolder';
import { getSelectedProjectSelectableStakeHolders } from '../../../../redux/selectors/project';
import { getAllStakeHolders } from '../../../../redux/reducers/sharedData';
import { getTheme } from '../../../../utils/costants';

export interface BodyState  { 
    selectedStakeHolder?: ApplicationStakeHolder,
}

export interface BodyProps extends RouteComponentProps { 
    isEdit: boolean,
    selectedProjectId?: number,
    stakeHolders: StakeHolder[],
    allStakeHolders: ApplicationStakeHolder[],
    selectableStakeHolders: StakeHolder[],
    darkModeEnabled: boolean,
    openGenericModal: typeof ModalCommandActions.openModal,
    setProjectFormStakeholders: typeof ProjectsCommandActions.setProjectFormStakeholders,
}

class Body extends React.Component<BodyProps, BodyState> {
    constructor(props: BodyProps) {
        super(props); 
        this.state = {
            selectedStakeHolder: undefined
        }
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }

    render(){
        const {selectedStakeHolder} = this.state
        const { openGenericModal, selectedProjectId, stakeHolders, selectableStakeHolders, allStakeHolders, darkModeEnabled, setProjectFormStakeholders } = this.props;
        const styles = getClassNames();
        const columns = getColumns(openGenericModal, getTheme(darkModeEnabled));
        const stakeholder_options: IComboBoxOption[] =  _.map(selectableStakeHolders, (c) => {return {key: c.stakeHolderId as number, text: c.companyName as string}});
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{display: 'flex', alignItems: 'center', gap: 20, marginBottom: 30}}>
                        <Text variant='xLarge' nowrap >Stakeholders</Text>
                        <FontIcon 
                            iconName={'Add'} 
                            title={'New Stakeholder'} 
                            onClick={() => {openGenericModal({id: 'newStakeHolder', contextItem: new StakeHolder({projectId: selectedProjectId, isActive: true})})}}
                            style={{cursor: 'pointer', fontSize: FontSizes.large, color: getTheme(darkModeEnabled).palette.themePrimary}}>
                        </FontIcon>
                        <ComboBox
                            disabled={stakeholder_options.length === 0}
                            placeholder="Select a stakeholder..."
                            selectedKey={selectedStakeHolder ? selectedStakeHolder.stakeHolderId : undefined}
                            options={stakeholder_options}
                            onChange={(e, value) => {
                                let stak =  _.find(allStakeHolders, as => as.stakeHolderId === ((value as IComboBoxOption).key as number)) as ApplicationStakeHolder;
                                this.setState({selectedStakeHolder: value ? stak : undefined})
                            }}                          
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 320}}
                            styles={{root: {marginLeft: 40}}}
                        /> 
                        <DefaultButton
                            primary
                            text="Import Stakeholder" 
                            disabled={!selectedStakeHolder || _.some(stakeHolders, s => selectedStakeHolder && s.stakeHolderId === selectedStakeHolder.stakeHolderId)}
                            onClick={() => {
                                setProjectFormStakeholders({value: (selectedStakeHolder && !_.some(stakeHolders, s => s.stakeHolderId === selectedStakeHolder.stakeHolderId)) ? _.orderBy([ 
                                    {
                                        stakeHolderId: selectedStakeHolder.stakeHolderId,
                                        projectId: selectedProjectId,
                                        address: selectedStakeHolder.address,
                                        companyName: selectedStakeHolder.companyName,
                                        country: selectedStakeHolder.country,
                                        initials: selectedStakeHolder.initials,
                                        isCustomer: false,
                                        isPartner: false,
                                        isSupplier: false,
                                        isActive: true,
                                    }, ...stakeHolders], s => (s.companyName as string).toLocaleLowerCase()) : [...stakeHolders]})
                            }}
                        />
                    </div>  
                    {stakeHolders && stakeHolders.length > 0 && 
                        <div className={styles.wrapper}>
                            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                                <DetailsList                              
                                    items={stakeHolders}
                                    columns={columns}
                                    setKey="id"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.unconstrained}
                                    selectionPreservedOnEmptyClick={true}
                                    selectionMode={SelectionMode.none}
                                    onRenderDetailsHeader={this.onRenderDetailsHeader}
                                    styles={{headerWrapper: {paddingTop: 0}}}                   
                                />
                            </ScrollablePane>               
                        </div>                 
                    }
                    
                </div>                                                                                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    isEdit: getIsEdit,
    selectedProjectId: getSelectedProjectId,
    stakeHolders: getProjectFormStakeholders,
    allStakeHolders: getAllStakeHolders,
    selectableStakeHolders: getSelectedProjectSelectableStakeHolders,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        openGenericModal: ModalCommandActions.openModal,
        setProjectFormStakeholders: ProjectsCommandActions.setProjectFormStakeholders,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));