export class DocType {
    id?: number
    code?: string
    name?: string
    
    constructor(object: any){
        this.id = object.docTypeId;
        this.code = object.docTypeCode;
        this.name = object.docTypeName;
    }
}