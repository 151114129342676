import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { Checkbox, Label, Text, TextField} from 'office-ui-fabric-react';
import _ from 'lodash';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';
import { CommandActions as ProjectCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getIsEdit, getProjectFormAutomaticApprovalDays} from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import NumberFormat from 'react-number-format';

export interface BodyProps extends RouteComponentProps { 
    automaticApprovalDays?: number,
    allProjects: Project[],
    isEdit: boolean,
    setProjectFormAutomaticApprovalDays: typeof ProjectCommandActions.setProjectFormAutomaticApprovalDays,
}

class Body extends React.Component<BodyProps> {
    constructor(props: BodyProps) {
        super(props); 
    }


    render(){
        const styles = getClassNames();
        const { automaticApprovalDays, setProjectFormAutomaticApprovalDays, } = this.props;
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >Configurations</Text></div>   
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        <NumberFormat
                            label={'Automatic approval days'}
                            customInput={TextField}
                            decimalScale={0}
                            value={(automaticApprovalDays) ? automaticApprovalDays : 0}
                            autoComplete={'off'}
                            onValueChange={(values) => { setProjectFormAutomaticApprovalDays({value: values.floatValue})}}
                            styles={{root: {minWidth: 256}}}
                        />     
                    </div>                                         
                </div>                                                                                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    isEdit: getIsEdit,
    automaticApprovalDays: getProjectFormAutomaticApprovalDays
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        setProjectFormAutomaticApprovalDays: ProjectCommandActions.setProjectFormAutomaticApprovalDays,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));