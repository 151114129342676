import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './internal.classNames';
import { CommandActions, DocumentActions } from '../../../../redux/actions/administrationModal';
import { DetailsList, DetailsListLayoutMode, IColumn, ConstrainMode, IDetailsHeaderProps, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import {IRenderFunction, SearchBox} from 'office-ui-fabric-react';
import _ from 'lodash';
import { getInternalAppUsers, getInternalFilter } from '../../../../redux/reducers/administrationModal';
import {AppUser, Role} from '../../../../models/administration';
import {getColumns} from './columnsInternal'
import handleViewport from 'react-in-viewport';
import ErrorBox from '../../../../components/error_box/index';
import debounce from 'debounce';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { getLoader } from '../../../../redux/selectors/loader';
import { getAllAdminNotifications, getSelectedProjectInternalAppRoles } from '../../../../redux/selectors/administration';
import { getEventTypes, getUiRules } from '../../../../redux/reducers/notifySettings';
import { NotifyRule } from '../../../../models/notifyRule';
import { EventType } from '../../../../models/eventType';

export interface IndexProps { 
    internalAppUsers: AppUser[], 
    loading: boolean, 
    loadingInternal: boolean,
    loadingInternalRemove: boolean,
    loadingInternalNotification: boolean,
    loadingInternalNotificationRemove: boolean,
    canAuthorize: any,
    filter?: string,
    selectedProjectAppRoles: Role[],
    allNotifications: NotifyRule[],
    eventTypes: EventType[],
    setInternalUserFieldValue: typeof DocumentActions.setInternalUserFieldValue,
    setInternalFilter: typeof CommandActions.setInternalFilter, 
    fetchInternalUsers: typeof CommandActions.fetchInternalUsers
}

interface IndexState {
    isDebouncing: boolean
}

class Index extends React.Component<IndexProps, IndexState> {
    debouncedGetItems: Function
    constructor(props: IndexProps) {
        super(props);

        this.state = {
            isDebouncing: false
        }

        this.debouncedGetItems = debounce((val: string) => {
            this.setState({isDebouncing: false});
            this.getUsers();
		}, 500);
    }

    setFilterAndSearch = (newFilter?: string) => {
        const { setInternalFilter } = this.props;
        this.setState({isDebouncing: true});
        setInternalFilter({value: newFilter});
        this.debouncedGetItems(newFilter);
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }
    
    getKey = (item: any, index?: number): string => {
        return item.email;
    }

    getUsers = () => {
		const { fetchInternalUsers} = this.props;
		fetchInternalUsers()   		
	}

    render() {
        const { isDebouncing } = this.state;
        const { internalAppUsers, loading, loadingInternalRemove, setInternalUserFieldValue, selectedProjectAppRoles, filter, loadingInternal, allNotifications, eventTypes, loadingInternalNotification, loadingInternalNotificationRemove} = this.props;
        const styles = getClassNames();
        const colums = getColumns(setInternalUserFieldValue, selectedProjectAppRoles, allNotifications, eventTypes );
        return (
            <div className={styles.tabContainer}>    
                <Text variant='xLarge' nowrap>Internal users</Text>
                <div style={{paddingBottom: 10, marginTop: 20}}>
                    <SearchBox
                        placeholder="Search"
                        value={filter as string}
                        onChange={(e, value) => this.setFilterAndSearch(value)}
                        autoComplete={'off'}
                    />
                </div> 
                <div className={styles.wrapper}>
                    {(loading || loadingInternal || loadingInternalRemove || loadingInternalNotification || loadingInternalNotificationRemove) && <Spinner size={SpinnerSize.large}></Spinner>}
                    {!loading && !loadingInternal && !loadingInternalRemove && !loadingInternalNotification && !loadingInternalNotificationRemove && !isDebouncing && internalAppUsers.length === 0 && <ErrorBox text={"No results found."} grey={true} marginTop={40} />}	
                    {!loading && !loadingInternal && !loadingInternalRemove && !loadingInternalNotification && !loadingInternalNotificationRemove && !isDebouncing && internalAppUsers.length > 0 &&	                      
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsList                              
                                items={internalAppUsers}
                                columns={colums}
                                getKey={this.getKey}
                                setKey="email"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                selectionPreservedOnEmptyClick={true}
                                selectionMode={SelectionMode.none}                             
                            />
                        </ScrollablePane>                 
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    internalAppUsers: getInternalAppUsers,
    loadingInternal: (state) => getLoader('administration')(state).loadingInternal,
    loadingInternalRemove: (state) => getLoader('administration')(state).loadingInternalRemove,
    loadingInternalNotification: (state) => getLoader('administration')(state).loadingInternalNotification,
    loadingInternalNotificationRemove: (state) => getLoader('administration')(state).loadingInternalNotificationRemove,
    loading: (state) => getLoader('administration')(state).loading,
    filter: getInternalFilter,
    selectedProjectAppRoles: getSelectedProjectInternalAppRoles,
    allNotifications: getAllAdminNotifications,
    eventTypes: getEventTypes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setInternalUserFieldValue: DocumentActions.setInternalUserFieldValue, 
        setInternalFilter: CommandActions.setInternalFilter, 
        saveUsers: CommandActions.saveUsers, 
        fetchInternalUsers: CommandActions.fetchInternalUsers
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(Index);
