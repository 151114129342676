import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions, FEATURE} from '../../redux/actions/documents';
import {CommandActions as DocumentCommandActions} from '../../redux/actions/document';
import {CommandActions as DocumentsCommandActions} from '../../redux/actions/documents';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getFilterPanelOpened } from '../../redux/reducers/ui';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, ScrollablePane, ScrollbarVisibility, SelectionMode, Selection, IDetailsHeaderProps, IRenderFunction, Sticky, StickyPositionType, IColumn, DetailsRow, DetailsRowFields, IDetailsRowFieldsProps, Text, IDetailsListProps, Spinner, SpinnerSize, FontIcon, IDetailsRowProps, IDetailsRowStyles } from 'office-ui-fabric-react';
import { getClassNames } from './index.classNames';
import _ from 'lodash';
import { getDocuments, getFetchGuid, getSelectedItemsIds, getSkip, getTotal } from '../../redux/reducers/documents';
import { Document } from '../../models/document';
import handleViewport from 'react-in-viewport';
import { documentsTop, HistoryPathType, isExternalApp } from '../../utils/costants';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import Warning from '../documents/functionalities/warning';
import { Project } from '../../models/project';
import { getHistoryName } from '../../utils/functions';
import { getSelectedProject } from '../../redux/selectors/project';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../redux/reducers/project';
let GenericModal = GenericModalGenerator();

export interface DocumentsGridProps extends RouteComponentProps, WithTranslation {
    columns: IColumn[],
    documents: Document[],
    selectedItemIds: string[],
    filterPanelOpened: boolean, 
    fetchGuid: string,
    total: number,
    skip: number,
    loadingScroll: boolean,
    selection: Selection,
    project?: Project,
    darkModeEnabled: boolean,
    setSelectedItemsIds: typeof DocumentActions.setSelectedItemsIds,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
}

// export interface DocumentsGridState {
//     columns: IColumn[]
// }

class DocumentsGrid extends React.Component<DocumentsGridProps> {
    constructor(props: DocumentsGridProps) {
        super(props);   

        // this.state = {
        //     columns: getColumns()
        // }
    }

    componentDidUpdate = (previousProps: DocumentsGridProps) => {
        const { fetchGuid, setSelectedItemsIds, selection } = this.props;
        if(fetchGuid !== previousProps.fetchGuid){
            setSelectedItemsIds({ids: [], feature: FEATURE});
            selection.setAllSelected(false);
        }
    }
    componentDidMount = () => {
        const { setSelectedItemsIds, selection } = this.props;
        setSelectedItemsIds({ids: [], feature: FEATURE});
        selection.setAllSelected(false);    
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }

    onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        return <DetailsRow {...props as any}  rowFieldsAs={this.renderRowFields} />;
    }
    
    renderRowFields = (props: IDetailsRowFieldsProps) => {
        return (
          <span data-selection-disabled={true}>
            <DetailsRowFields {...props} />
          </span>
        );
    }



    resetSelection = () => {
        const {setSelectedItemsIds, selection} = this.props;
        setSelectedItemsIds({ids: [], feature: FEATURE});
        selection.setAllSelected(false);
    }

    // onColumnDrop = (details: IColumnDragDropDetails) => {
    //     const { columns } = this.state;
    //     var newColumns = [...columns];
    //     var aux = newColumns[details.draggedIndex];
    //     newColumns[details.draggedIndex] =  newColumns[details.targetIndex];
    //     newColumns[details.targetIndex] = aux;
    //     this.setState({columns: [...newColumns]});
    // }

    infiniteScroll = () => {
		const {skip, total, loadingScroll, fetchDocuments} = this.props;
		let hv: any = handleViewport;
		const ViewportBlock = hv((props: any) => {
				const { forwardedRef } = props;
				return (<div ref={forwardedRef} style={{width: '100%'}}> <Spinner size={SpinnerSize.large} /></div>)
		});
		if(total > 0)
			return <ViewportBlock onEnterViewport={() => 
                {  
                    if(!loadingScroll)
                        fetchDocuments({skip: (documentsTop + skip), adding: true})
                }          
            } onLeaveViewport={() => {}} />;
		else
			return;
    }

    onTableRenderRow = (props: IDetailsRowProps | undefined): JSX.Element => {
        const {history, project} = this.props
        const historyName = getHistoryName(history);
        const customStyles: Partial<IDetailsRowStyles> = {};   
        if(project && historyName === 'reservedNumbers' && isExternalApp){
            if(props && props.item && props.item.type && props.item.type.name === 'DWG'){
                let mandatoryColums = (project.mandatoryDrawingColumns && project.mandatoryDrawingColumns.length > 0) ? [...project.mandatoryDrawingColumns] : [];
                if(mandatoryColums.length > 0 && _.some(mandatoryColums, c => !(props.item as any)[c])){
                    customStyles.checkCell = { backgroundColor: '#F2EAD9' };            
                    customStyles.cell = { backgroundColor: '#F2EAD9' };    
                }
            }
            else if(props && props.item && props.item.type && props.item.type.name !== 'DWG'){
                let mandatoryColums = (project.mandatoryDocumentColumns && project.mandatoryDocumentColumns.length > 0) ? [...project.mandatoryDocumentColumns] : [];
                if(mandatoryColums.length > 0 && _.some(mandatoryColums, c => !(props.item as any)[c])){
                    customStyles.checkCell = { backgroundColor: '#F2EAD9' };   
                    customStyles.cell = { backgroundColor: '#F2EAD9' };
                }
            }
        }        
        return <DetailsRow {...props as any} styles={customStyles} />;
    };

    render(){
        const {documents, filterPanelOpened, columns, total, selectedItemIds, selection, darkModeEnabled, t} = this.props;
        const styles = getClassNames(filterPanelOpened);
        //const columnReorderOptions: IColumnReorderOptions  = { onColumnDrop: this.onColumnDrop };
        return (
            <>
                {documents && 
                    <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                        <Text variant='large' style={{marginLeft: 20, color: (darkModeEnabled) ? '#f7f7f7' : undefined}}>
                            {documents.length + '/' + total + ' documents' + ((selectedItemIds.length > 0) ? (' (' + selectedItemIds.length + ' selected)') : '') }
                        </Text>
                        {selectedItemIds.length > 0 && <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red', marginTop: 2 }}  onClick={() => {this.resetSelection()}}/> }
                    </div>
                }
                <div className={styles.wrapper}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <DetailsList                              
                            items={documents}
                            columns={columns}
                            setKey="id"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            constrainMode={ConstrainMode.unconstrained}
                            onRenderRow={this.onTableRenderRow}
                            onRenderDetailsHeader={this.onRenderDetailsHeader}
                            selectionPreservedOnEmptyClick={true}
                            selection={selection}
                            selectionMode={SelectionMode.multiple}  
                            styles={{headerWrapper: {paddingTop: 0}}}
                            //columnReorderOptions={columnReorderOptions}                            
                        />
                        {((documents && documents.length < total) || total == 0) && this.infiniteScroll()}
                    </ScrollablePane>               
                </div>     
                <GenericModal 
                    id={'warning'}
                    title={'Attention'}        
                    onClose={() => {}}
                    btnCloseText={t('Cancel')} 
                    customComponent={<Warning/>}
                    width={420}  
                />     
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    documents: getDocuments,
    selectedItemIds: getSelectedItemsIds,
    fetchGuid: getFetchGuid,
    total: getTotal,
    skip: getSkip,
    project: getSelectedProject,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setSelectedItemsIds: DocumentActions.setSelectedItemsIds,
        setOpenModal: DocumentCommandActions.setOpenModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsGrid)));