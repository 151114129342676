import moment from "moment";

export class Comment {
    id?: number
    text?: string
    creationDate?: Date
    createdBy?: string
    type?: string = 'comment'
    
    constructor(object: any){
        this.id = object.commentId;
        this.text = object.textComment;
        this.creationDate = (object.creationDate) ? moment((object.creationDate && object.creationDate.includes('.')) ? object.creationDate.split('.')[0] + 'Z' : object.creationDate).toDate() : undefined;
        this.createdBy = object.createdBy;
    }
}

export class CommentForm {
    documentId?: number
    commentText?: string
    createdBy?: string
    d3DocumentId?: string
    
    constructor(){
        this.documentId = undefined;
        this.commentText = undefined;
        this.createdBy = undefined;
        this.d3DocumentId = undefined;
    }
}