import _ from "lodash"

export class Folder {   
    id: string
    parentId?: string 
    name: string 
    count: number
    folderName: string
    description: string
    projectId: number
    type: 'root' | 'subfolder'

    constructor(object?: any, folder?: Folder) {
        if(object){
            this.id = object.folderId;
            this.parentId = object.parentId;
            this.folderName = object.name;
            this.count = (object.documentCount) ? object.documentCount : 0;
            this.name = (object.parentId) ? (this.folderName + ' (' + this.count + ')') : this.folderName;
            this.description = (object.description) ? object.description : '';
            this.projectId = object.projectId;
            this.type = (this.parentId) ? 'subfolder' : 'root';
        }
        else if (folder){
            this.id = folder.id;
            this.parentId = folder.parentId;
            this.folderName = folder.folderName;
            this.count = folder.count;
            this.name = folder.name;
            this.description = folder.description;
            this.projectId = folder.projectId;
            this.type = folder.type;
        }
        else{
            this.id = '';
            this.folderName = '';
            this.count = 0;
            this.name = '';
            this.description = '';
            this.projectId = -1;
            this.type = 'root';
        }
    }   
}

export const setFoldersPath = (folders: Folder[]): Folder[] => {
    _.forEach(folders, f => {
        f.description = (f.description === '') ? getFolderPath(f, folders) : f.description;
    });
    return folders;
}

const getFolderPath = (currentFolder: Folder, folders: Folder[]): string => {
    if(!currentFolder.parentId)
        return currentFolder.folderName;
    else {
        let parentFolder = _.find(folders, f => f.id === currentFolder.parentId) as Folder;
        return getFolderPath(parentFolder, folders) + ' \\ ' + currentFolder.folderName;
    }
}