import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { DirectionalHint, Dropdown, FontIcon, FontSizes, IDropdownOption, IRenderFunction, ITextFieldProps, Label, TextField, TooltipHost } from 'office-ui-fabric-react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { getClassNames } from './index.classNames';
import { ReserveNumberForm } from '../../../models/document';
import NumberFormat from 'react-number-format';
import { getAllPurposes, getDisciplines, getEngPhases, getEntities, getPurposes, getReserveNumbersDisciplines, getReserveNumbersPbs, getTypes } from '../../../redux/reducers/sharedData';
import { EngPhase } from '../../../models/engPhase';
import { getDisciplineDropdownOptions, getDocTypesDropdownOptions, getEngPhaseDropdownOptions, getEntityDropdownOptions, getHistoryName, getPurposeDropdownOptions, getPurposeLabel, getReservedNumbersDisciplineDropdownOptions, getReservedNumbersDocTypesDropdownOptions, getTypesDropdownOptions } from '../../../utils/functions';
import { Purpose } from '../../../models/purpose';
import { Entity } from '../../../models/entity';
import { Discipline, ReserveNumberDisciplineDocType } from '../../../models/discipline';
import { Type } from '../../../models/type';
import { Pbs } from '../../../models/pbs';
import { Popup as HierarchyExplorer } from '@pwbapps/hierarchyexplorer';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { PbsLevelsType } from '../../../utils/costants';

export interface ReserveNumbersProps extends RouteComponentProps, WithTranslation {
    reserveNumberForm?: ReserveNumberForm,
    engPhases: EngPhase[],
    allPurposes: Purpose[],
    entities: Entity[],
    types: Type[],
    reservedNumberPbs: Pbs[],
    reservedNumberDisciplines: ReserveNumberDisciplineDocType[],
    setOptional: typeof ModalDocumentAction.setOptional,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty
}
class ReserveNumbers extends React.Component<ReserveNumbersProps> {
    constructor(props: ReserveNumbersProps) {
        super(props);   
        this.state = {

        }
    }

    _onRenderCategoryOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNames();
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon && <FontIcon style={{marginRight: 10, fontSize: FontSizes.xLarge, color: (option) ? (option as any).color : undefined}} iconName={(option) ? (option as any).icon : undefined} />}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderCategoryTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderCategoryOption(option);
    };

    onRenderPbsSuffix: IRenderFunction<ITextFieldProps> = (props, defaultRender) => {
        const { reserveNumberForm } = this.props;
        return (      
            <TooltipHost directionalHint={DirectionalHint.rightCenter} content={(reserveNumberForm && reserveNumberForm.pbs) ? "Remove PBS" : ""}>
                <div onClick={(reserveNumberForm && reserveNumberForm.pbs) ? (e) => {this.setPbsValueFunction(undefined); e.stopPropagation()} : () => {}}>
                    <FontIcon iconName={"Cancel"} style={{cursor: (reserveNumberForm && reserveNumberForm.pbs) ? "pointer" : "default" }}  /> 
                </div>
            </TooltipHost>          
        )
    };

    setPbsValueFunction = (item?: any, checked?: any) =>{
        const { setContextItemProperty } = this.props;
        if(item)
            setContextItemProperty({id: 'reserveNumber', name: 'pbs', value: (checked) ? item : undefined});
        else 
        
        setContextItemProperty({id: 'reserveNumber', name: 'pbs', value: undefined});
    }

    isSelectablePbs = (item: PbsLevelsType): boolean => {
        return (item === 'Plant Section' || item === 'Equipment') ? true : false;
    }

    render(){
        const { reserveNumberForm, engPhases, allPurposes, entities, types, history, reservedNumberPbs, reservedNumberDisciplines, t, setContextItemProperty } = this.props;
        let engPhases_options: {key: number, text: string}[] = [...getEngPhaseDropdownOptions(engPhases, true)];
        let purposes_options: {key: number, text: string}[] = [...getPurposeDropdownOptions(allPurposes, getHistoryName(history))];
        let entities_options: {key: number, text: string}[] = [...getEntityDropdownOptions(entities)];
        let type_options: {key: number, text: string}[] = [...getTypesDropdownOptions(types)];

        let typeDisciplines = (reserveNumberForm && reserveNumberForm.type) ?_.uniqBy(_.filter(reservedNumberDisciplines, d => reserveNumberForm.type && d.type === reserveNumberForm.type.name) as ReserveNumberDisciplineDocType[], ud => [ud.code, ud.name].join()) : [] ;
        let disciplines_options = (typeDisciplines.length > 0) ? [...getReservedNumbersDisciplineDropdownOptions(typeDisciplines)] : [];
        
        let docType_options = (reserveNumberForm && reserveNumberForm.discipline) ? [...getReservedNumbersDocTypesDropdownOptions(reservedNumberDisciplines, reserveNumberForm.discipline)] : [];
        const historyName = getHistoryName(history);
        return (
            <>      
                { reserveNumberForm &&
                    <>
                        <div style={{display: 'flex', gap: 10}}>                          
                            <Dropdown
                                required
                                label={t("Type")}
                                placeholder='Select a type...'
                                disabled={type_options.length === 0}
                                options={type_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'type', value: (value) ? _.find(types, p => p.id === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.type) ? reserveNumberForm.type.id : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.type : undefined}
                            />     
                            <Dropdown
                                required
                                label={t("Eng.phase")}
                                placeholder={t('Select an Eng.phase') + '...'}
                                disabled={engPhases_options.length === 0}
                                options={engPhases_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'engPhase', value: (value) ? _.find(engPhases, p => p.id === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.engPhase) ? reserveNumberForm.engPhase.id : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.engPhase : undefined}
                            />      
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <HierarchyExplorer 
                                readOnly={false}
                                title={"PBS selector"}
                                items={reservedNumberPbs}
                                onSelect={(item, checked) => this.setPbsValueFunction(item, checked)}                                             
                                selected={(reserveNumberForm.pbs) ? [reserveNumberForm.pbs.id] : []}
                                selectedProperty={"id"}
                                isSelectable={this.isSelectablePbs}
                                >
                                {(ref, onClick) => (
                                    <>
                                        <div style={{width: '100%'}}>
                                            <Label>PBS<b style={{color: '#A4262C'}}> *</b></Label>
                                            <div ref={ref} onClick={onClick} onFocus={onClick}>
                                                <TextField 
                                                    readOnly={true} 
                                                    autoComplete={'off'} 
                                                    value={(reserveNumberForm.pbs) ? reserveNumberForm.pbs.name : undefined} 
                                                    title={(reserveNumberForm.pbs) ? reserveNumberForm.pbs.name : undefined} 
                                                    onRenderSuffix={this.onRenderPbsSuffix}
                                                />      
                                            </div>
                                            {reserveNumberForm && reserveNumberForm.errors && reserveNumberForm.errors.pbs && <span data-automation-id="error-message" style={{fontSize: 12, fontWeight: 400, fontFamily: "Segoe UI", color: '#A4262C', paddingTop: 5}} >{reserveNumberForm.errors.pbs}</span>}
                                        </div>
                                    </>
                                )}
                            </HierarchyExplorer>  
                            {/* <Dropdown
                                required
                                label={t("Entity")}
                                placeholder={t('Select an entity') + '...'}
                                disabled={entities_options.length === 0}
                                options={entities_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'entity', value: (value) ? _.find(entities, p => p.id === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.entity) ? reserveNumberForm.entity.id : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.entity : undefined}
                            />   */}
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <Dropdown
                                required
                                label={t(getPurposeLabel(historyName))}
                                placeholder={t('Select a ' + getPurposeLabel(historyName)) + '...'}
                                disabled={purposes_options.length === 0}
                                options={purposes_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'purpose', value: (value) ? _.find(allPurposes, p => p.id === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.purpose) ? reserveNumberForm.purpose.id : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.purpose : undefined}
                            />                               
                            <Dropdown
                                required
                                label={t("Discipline")}
                                placeholder={t('Select a discipline...')}
                                disabled={disciplines_options.length === 0 || reserveNumberForm.type === undefined}
                                options={disciplines_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'discipline', value: (value) ? _.find(typeDisciplines, p => p.code === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.discipline) ? reserveNumberForm.discipline.code : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.discipline : undefined}
                            />      
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <Dropdown
                                required
                                 label={t("DocType")}
                                placeholder={t('Select a doc type...')}
                                disabled={typeDisciplines.length === 0 || docType_options.length === 0 || !reserveNumberForm.discipline}
                                options={docType_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'reserveNumber', name: 'docType', value: (value) ? _.find(reservedNumberDisciplines, p => p.docTypeCode === value.key) : undefined});
                                }}
                                selectedKey={(reserveNumberForm.docType) ? reserveNumberForm.docType.docTypeCode : null}                              
                                styles={{root: {width: '100%'}, dropdownItemsWrapper: {maxHeight: 360}}}
                                errorMessage={(reserveNumberForm && reserveNumberForm.errors) ? reserveNumberForm.errors.docType : undefined}
                            />      
                            <TextField 
                                label={t("Title") + " 1"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine1Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine1Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />      
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <TextField 
                                label={t("Title") + " 2"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine2Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine2Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />                                                                               
                            <TextField 
                                label={t("Title") + " 3"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine3Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine3Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />      
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <TextField 
                                label={t("Title") + " 4"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine4Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine4Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />                                                                               
                            <TextField 
                                label={t("Title") + " 5"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine5Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine5Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />      
                        </div>
                        <div style={{display: 'flex', gap: 10}}>
                            <TextField 
                                label={t("Title") + " 6"} 
                                autoComplete={'off'} 
                                value={reserveNumberForm.titleLine6Pl || ''} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'reserveNumber', name: 'titleLine6Pl', value: (value) ? value : undefined});
                                }}
                                styles={{root: {width: '100%'}}}
                            />                                                                               
                            <div>
                                <Label>{t('Quantity')} <b style={{color: '#A4262C'}}>*</b></Label>
                                <NumberFormat
                                    customInput={TextField}
                                    decimalScale={0}
                                    min={1}
                                    //max={9999}
                                    value={(reserveNumberForm.number) ? reserveNumberForm.number : ''}
                                    autoComplete={'off'}
                                    onValueChange={(values) => {
                                        setContextItemProperty({id: 'reserveNumber', name: 'number', value: values.floatValue});
                                    }}
                                    styles={{root: {width: 260}}}
                                />   
                            </div>
                        </div>
                    </>
                    
                }  
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    reserveNumberForm: (state) => (getGenericModal('reserveNumber')(state)) ? (getGenericModal('reserveNumber')(state) as any).contextItem as ReserveNumberForm : undefined,
    engPhases: getEngPhases,
    allPurposes: getAllPurposes,
    entities: getEntities,
    types: getTypes,
    reservedNumberPbs: getReserveNumbersPbs,
    reservedNumberDisciplines: getReserveNumbersDisciplines
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setOptional: ModalDocumentAction.setOptional,
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(ReserveNumbers)));