import * as React from 'react';
import { Panel, PanelType, IPanelProps } from 'office-ui-fabric-react/lib/Panel';
import {bindActionCreators} from 'redux';
import { getPanelOpened, getPanelContent, getPanelTitle, getLoading } from '../../redux/reducers/ui';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DocumentActions as UiDocumentActions, CommandActions as UiCommandActions } from '../../redux/actions/ui';
import { getClassNames } from './index.classNames';
import RingerContent from '../../components/ringer/index';
import ChangelogContent from '../../components/changelog/index';
import FeedbackContent from '../feedback/index';
import AccountContent from '../login/user';
import { IRenderFunction } from 'office-ui-fabric-react/lib/Utilities';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { DefaultButton} from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

export interface PanelProps { 
    panelOpened: boolean, 
    panelContent?: string, 
    panelTitle?: string, 
    loading: boolean,
    setPanelStatus: typeof UiDocumentActions.setPanelStatus, 
    sendFeedback: typeof UiCommandActions.sendFeedback 
}

const styles = getClassNames();

class RightPanel extends React.Component<PanelProps> {
    constructor(props: PanelProps) {
        super(props);
    }

    hidePanel = () => {
        const {setPanelStatus,} = this.props;
        setPanelStatus({open: false, title: undefined, content: undefined});
    };

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
        let title = this.props.panelTitle;  
        return (      
        <>   
            <Text className={styles.headerLabel} variant='xLarge' nowrap>{title}</Text>
            {// This custom navigation still renders the close button (defaultRender).
            // If you don't use defaultRender, be sure to provide some other way to close the panel.
            defaultRender!(props)}
        </>
        )
      });

      onRenderFooterContent = () => {
        const { panelContent, sendFeedback, loading } = this.props;
        return (
            <>
                {panelContent === 'feedback' &&
                    <div style={{display: 'flex', justifyContent: 'flex-end' }}>               
                        { !loading && <DefaultButton styles={{root: {width: 54}}} text="Submit" onClick={() => sendFeedback()}/>}
                        { loading  && <DefaultButton styles={{root: {width: 54}}}><Spinner size={SpinnerSize.small}></Spinner></DefaultButton>}
                    </div>             
                }
            </>
        );
      };

    render() {
        const {panelOpened, panelContent} = this.props;
        return (
            <Panel
                className={styles.panel}
                styles={{navigation: styles.header, scrollableContent: styles.scrollableContent, content:{ paddingLeft: 0, paddingRight: 0}}}
                isOpen={panelOpened}
                isBlocking={false}
                type={PanelType.smallFixedFar}
                onDismiss={this.hidePanel}
                headerText=""
                closeButtonAriaLabel="Close"
                onRenderNavigationContent={this.onRenderNavigationContent}
                isHiddenOnDismiss={true}
                onRenderFooterContent={panelContent === 'feedback' ? this.onRenderFooterContent : undefined}
            >  
                {panelContent === 'ringer' &&
                    <RingerContent/>
                }
                {panelContent === 'changelog' &&
                    <ChangelogContent/>
                }   
                {panelContent === 'feedback' &&
                    <FeedbackContent/>
                }     
                {panelContent === 'account' &&
                    <AccountContent/>
                }             
            </Panel>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    panelOpened: getPanelOpened,
    panelContent: getPanelContent,
    panelTitle: getPanelTitle,
    loading: getLoading
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ setPanelStatus: UiDocumentActions.setPanelStatus, sendFeedback: UiCommandActions.sendFeedback}, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)((RightPanel));
