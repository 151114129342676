import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    tabContainer: string
    contentContainer: string
    footerContainer: string
    container: string
    button: string
    title: string
    header: string,
    content: string,
    scrollableContent: string,
    footer: string,
    contentInner: string,
    notifyHeader: string,
    notify: string
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        container:{
            padding: 10
        },
        tabContainer: {
            marginBottom: 60
        },
        contentContainer: {
            margin: 10
        },
        footerContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 40
        },
        button: {
            marginLeft: 10
        },
        title:{
            fontWeight: 'bold'
        },
        header:{
            justifyContent: 'space-between',
            alignItems: 'center', 
            paddingLeft: 15,
            height: 80
        },
        content:{
           paddingBottom: 0,
           display: 'flex',
           flexDirection: 'column',
           flexGrow: 1,
        },
        scrollableContent:{
            flexGrow: 1,
            overflowY: 'hidden',
            display: 'flex'
        },
        footer:{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 30
        },
        contentInner:{

        },
        notifyHeader:{
            overflow: "auto",
            position: "relative",
            height: 'calc(100%)'
        },
        notify:{
            paddingLeft: 30,
            paddingBottom: 10
        }
    });
};