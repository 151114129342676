 import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { FileForm } from '../../../models/document';
import { ProgressIndicator, TextField, Text, Icon, FontIcon, Dropdown, IDropdownOption } from 'office-ui-fabric-react';
import { ModalDocumentAction, ModalCommandActions } from '../../../redux/actions/modal';
import { getLoader } from '../../../redux/selectors/loader';
import { getDocumentsToUpload, getDocumentsToUploadTotal } from '../../../redux/reducers/documents';
import { Type } from '../../../models/type';
import { getSelectedProjectDocColumns, getSelectedProjectDwgColumns, getSelectedProjectTemplateTitles } from '../../../redux/selectors/project';
import { capitalizeFirstLetter, getFileExtension } from '../../../utils/functions';
import { getClassNames } from './index.classNames';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { withTranslation, WithTranslation  } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { languages } from '../../../translations';
import { getTheme } from '../../../utils/costants';
import { getClassNames as getClassNamesLanguage} from '../../login/languageSelector.classNames';
export interface UploadParentProps extends RouteComponentProps, WithTranslation {
    type: Type,
    parentFileForm: {
        fileForm: FileForm,
        files?: any[]
    },
    loadingContent: boolean,
    loading: boolean,
    documentsToUploadTotal: number,
    documentsToUpload: number,
    dwgColumns: string[],
    docColumns: string[],
    templateTitles: {name: string, value: string}[],
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty,
    openGenericModal: typeof ModalCommandActions.openModal
}

class UploadParent extends React.Component<UploadParentProps> {
    constructor(props: UploadParentProps) {
        super(props);   
        this.state = {

        }
    }

    _onRenderFlagOption = (option?: IDropdownOption): JSX.Element => {
        const styles = getClassNamesLanguage(getTheme(false));
        return (
            <div key={(option) ? option.key : undefined} className={styles.dropDownItem}>
                {option && (option as any).icon}
                <span>{(option) ? option.text : undefined}</span>
            </div>     
        );
    };

    _onRenderFlagTitle = (options?: IDropdownOption[]): JSX.Element => {
        const option = (options && options.length > 0) ? options[0] : undefined;
        return this._onRenderFlagOption(option);
    };

    onDrop = (accepted: any, rejected: any) => {
        const {t, openGenericModal, setContextItemProperty} = this.props;     
        if(accepted && accepted.length === 1)
            setContextItemProperty({id: 'uploadParent', name: 'files', value: accepted})
        else if(accepted.length > 1)
            openGenericModal({id: 'warning', contextItem: t('Only one file can be uploaded')});
    }

    render(){
        const { parentFileForm, loadingContent, loading, documentsToUpload, documentsToUploadTotal, type, dwgColumns, docColumns, templateTitles, t, setContextItemProperty } = this.props;
        const currentDoc = (documentsToUpload > 0) ? (documentsToUploadTotal - documentsToUpload + 1) : undefined;
        let language_options: {key: string, text: string}[] = _.map(languages, l => {return { key: l.code, text: l.description }});
        const styles = getClassNames();
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <ProgressIndicator description={(currentDoc) ? (currentDoc + " of " + documentsToUploadTotal) : undefined} styles={{root: {height: 35, marginBottom: 10}}} progressHidden={ !loadingContent && !loading } percentComplete={(currentDoc) ? ((currentDoc) / documentsToUploadTotal) : 0}/> 
                { parentFileForm && parentFileForm.fileForm && type && type.name === 'DWG' && dwgColumns.length > 0 &&
                   <div className={styles.columns}>
                        {_.map(dwgColumns, (c, index) => {
                            return (
                                <TextField 
                                    required
                                    key={index + 'dwg_col'}
                                    label={t(capitalizeFirstLetter(c))} 
                                    autoComplete={'off'} 
                                    value={(parentFileForm.fileForm as any)[c] || ''} 
                                    onChange={(e, value) => { 
                                        setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, [c] : value}})
                                    }}
                                    styles={{root: {minWidth: 256}}}
                                /> 
                            );
                        })}
                   </div>           
                }
                { parentFileForm && parentFileForm.fileForm && type && type.name === 'DOC' && docColumns.length > 0 &&
                    <div className={styles.columns}>
                        {_.map(docColumns, (c, index) => {
                            return (
                                <TextField 
                                    required
                                    key={index + 'doc_col'}
                                    label={t(capitalizeFirstLetter(c))} 
                                    autoComplete={'off'} 
                                    value={(parentFileForm.fileForm as any)[c] || ''} 
                                    onChange={(e, value) => { 
                                        setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, [c] : value}})
                                    }}
                                    styles={{root: {minWidth: 256}}}
                                /> 
                            );
                        })}
                   </div>           
                }
                { parentFileForm && parentFileForm.fileForm && templateTitles.length > 0 &&
                    <div className={styles.columns}>
                        {_.map(templateTitles, (ti, index) => {
                            return (
                                <TextField 
                                    key={index + 'templ_titles'}
                                    label={t(ti.value as string)} 
                                    autoComplete={'off'} 
                                    value={(parentFileForm.fileForm as any)[ti.name] || ''} 
                                    onChange={(e, value) => { 
                                        setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, [ti.name] : value}})
                                    }}
                                    styles={{root: {minWidth: 256}}}
                                /> 
                            );
                        })}
                   </div>           
                }
                {parentFileForm && parentFileForm.fileForm && 
                    <div className={styles.columns}>
                        <TextField 
                            label={t('Supplier doc number')} 
                            autoComplete={'off'} 
                            value={parentFileForm.fileForm.supplierDocumentNumber || ''} 
                            onChange={(e, value) => { 
                                setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, supplierDocumentNumber : value}})
                            }}
                            styles={{root: {minWidth: 256}}}
                        /> 
                        <TextField 
                             label={t('Supplier doc index')} 
                            autoComplete={'off'} 
                            value={parentFileForm.fileForm.supplierDocumentIndex || ''} 
                            onChange={(e, value) => { 
                                setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, supplierDocumentIndex : value}})
                            }}
                            styles={{root: {minWidth: 256}}}
                        /> 
                        <Dropdown
                            label={'Language'}
                            placeholder='Select a language'
                            options={language_options}
                            onChange={(e, value) => { setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, language : value ? value.key as string : undefined} }) }}
                            selectedKey={(parentFileForm.fileForm.language) ? parentFileForm.fileForm.language : null}     
                            styles={{ dropdownItemsWrapper: {maxHeight: 360}, root: {minWidth: 256} }}
                        />                                     
                    </div>           
                }
                <div style={{display: 'flex', gap: 20, alignSelf: 'center', marginTop: 20}}>
                    <Dropzone
                        onDrop={this.onDrop}
                        disabled={loadingContent}
                    >
                        <div style={{padding: 5}}>  
                            <p>{t('Drop the file here, or click to select file to upload.')}</p>                                      
                        </div>
                    </Dropzone>
                    {parentFileForm && parentFileForm.files && parentFileForm.files.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5, maxHeight: 200, overflowY: 'auto'}}>
                            {_.map(parentFileForm.files, (f, index) => {
                                return( 
                                    <div key={index + '_imported_file_parent'} style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                                        <Icon {...getFileTypeIconProps({extension: (f.name) ? getFileExtension(f.name) : '', size: 16}) }/>
                                        <Text  variant="smallPlus" style={{}}>{f.name}</Text> 
                                        {!loadingContent && <FontIcon iconName={"Cancel"} style={{cursor: "pointer", color: 'red' }}  onClick={() => { setContextItemProperty({id: 'uploadParent', name: 'files', value: _.filter(parentFileForm.files, of => of.name !== f.name)}) }} />}
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    parentFileForm: (state) => (getGenericModal('uploadParent')(state)) ? (getGenericModal('uploadParent')(state) as any).contextItem : undefined,
    loading: (state) => getLoader('document')(state).loading,
    documentsToUpload: getDocumentsToUpload,
    documentsToUploadTotal: getDocumentsToUploadTotal,
    dwgColumns: getSelectedProjectDwgColumns,
    docColumns: getSelectedProjectDocColumns,
    templateTitles: getSelectedProjectTemplateTitles
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty,
        openGenericModal: ModalCommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(UploadParent)));