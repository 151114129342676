 import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { FileForm } from '../../../models/document';
import { ProgressIndicator, TextField, Text, Icon, FontIcon, Dropdown } from 'office-ui-fabric-react';
import { ModalDocumentAction, ModalCommandActions } from '../../../redux/actions/modal';
import { getLoader } from '../../../redux/selectors/loader';
import { getDocumentsToUpload, getDocumentsToUploadTotal } from '../../../redux/reducers/documents';
import { Type } from '../../../models/type';
import { getEngPhaseDropdownOptions, getEntityDropdownOptions, getFileExtension, getHistoryName, getPurposeDropdownOptions, getPurposeLabel, getTypesDropdownOptions } from '../../../utils/functions';
import { getClassNames } from './index.classNames';
import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getEngPhases, getPurposes, getEntities, getTypes } from '../../../redux/reducers/sharedData';
import { EngPhase } from '../../../models/engPhase';
import { Entity } from '../../../models/entity';
import { Purpose } from '../../../models/purpose';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface UploadParentProps extends RouteComponentProps, WithTranslation {
    parentFileForm: {
        fileForm: FileForm,
        files?: any[]
    },
    engPhases: EngPhase[],
    purposes: Purpose[],
    entities: Entity[],
    types: Type[],
    loadingContent: boolean,
    loading: boolean,
    documentsToUploadTotal: number,
    documentsToUpload: number,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty,
    openGenericModal: typeof ModalCommandActions.openModal
}

class UploadParent extends React.Component<UploadParentProps> {
    constructor(props: UploadParentProps) {
        super(props);   
        this.state = {

        }
    }

    onDrop = (accepted: any, rejected: any) => {
        const { t, openGenericModal, setContextItemProperty} = this.props;     
        if(accepted && accepted.length === 1)
            setContextItemProperty({id: 'uploadParent', name: 'files', value: accepted})
        else if(accepted.length > 1)
            openGenericModal({id: 'warning', contextItem: t('Only one file can be uploaded')});
    }

    render(){
        const { parentFileForm, loadingContent, loading, documentsToUpload, documentsToUploadTotal, engPhases, purposes, entities, types, history, t, setContextItemProperty } = this.props;
        const currentDoc = (documentsToUpload > 0) ? (documentsToUploadTotal - documentsToUpload + 1) : undefined;
        const styles = getClassNames();
        let engPhases_options: {key: number, text: string}[] = [...getEngPhaseDropdownOptions(engPhases, true)];
        let purposes_options: {key: number, text: string}[] = [...getPurposeDropdownOptions(purposes, getHistoryName(history))];
        let entities_options: {key: number, text: string}[] = [...getEntityDropdownOptions(entities)];
        let type_options: {key: number, text: string}[] = [...getTypesDropdownOptions(types)];
        const historyName = getHistoryName(history);
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <ProgressIndicator description={(currentDoc) ? (currentDoc + " of " + documentsToUploadTotal) : undefined} styles={{root: {height: 35, marginBottom: 10}}} progressHidden={ !loadingContent && !loading } percentComplete={(currentDoc) ? ((currentDoc) / documentsToUploadTotal) : 0}/> 

                {parentFileForm && parentFileForm.fileForm && 
                    <div className={styles.columns}>
                        <>
                            <Dropdown
                                required
                                label={t("Type")}
                                placeholder={t('Select a type') + '...'}
                                disabled={type_options.length === 0}
                                options={type_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, type : (value) ? _.find(types, p => p.id === value.key) : undefined}})
                                }}
                                selectedKey={(parentFileForm.fileForm.type) ? parentFileForm.fileForm.type.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                    
                            />     
                            <Dropdown
                                required
                                label={t("Eng.phase")}
                                placeholder={t('Select an Eng.phase') + '...'}
                                disabled={engPhases_options.length === 0}
                                options={engPhases_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, engPhase : (value) ? _.find(engPhases, p => p.id === value.key) : undefined}})
                                }}
                                selectedKey={(parentFileForm.fileForm.engPhase) ? parentFileForm.fileForm.engPhase.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                         
                            />      
                    
                        
                            <Dropdown
                                required
                                label={t(getPurposeLabel(historyName))}
                                placeholder={t('Select a ' + getPurposeLabel(historyName)) + '...'}
                                disabled={purposes_options.length === 0}
                                options={purposes_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, purpose : (value) ? _.find(purposes, p => p.id === value.key) : undefined}})
                                }}
                                selectedKey={(parentFileForm.fileForm.purpose) ? parentFileForm.fileForm.purpose.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                           
                            />  
                            <Dropdown
                                required
                                 label={t("Entity")}
                                placeholder={t('Select an entity') + '...'}
                                disabled={entities_options.length === 0}
                                options={entities_options}
                                onChange={(e, value) => {
                                    setContextItemProperty({id: 'uploadParent', name: 'fileForm', value: {...parentFileForm.fileForm, entity : (value) ? _.find(entities, p => p.id === value.key) : undefined}})
                                }}
                                selectedKey={(parentFileForm.fileForm.entity) ? parentFileForm.fileForm.entity.id : null}                              
                                styles={{root: {minWidth: 260}, dropdownItemsWrapper: {maxHeight: 360}}}
                            />  
                       
                        </>
                    </div>           
                }
                <div style={{display: 'flex', gap: 20, alignSelf: 'center', marginTop: 20}}>
                    <Dropzone
                        onDrop={this.onDrop}
                        disabled={loadingContent}
                    >
                        <div style={{padding: 5}}>  
                            <p>{t('Drop the file here, or click to select file to upload.')}</p>                                      
                        </div>
                    </Dropzone>
                    {parentFileForm && parentFileForm.files && parentFileForm.files.length > 0 &&
                        <div style={{display: 'flex', flexDirection: 'column', gap: 5, maxHeight: 200, overflowY: 'auto'}}>
                            {_.map(parentFileForm.files, (f, index) => {
                                return( 
                                    <div key={index + '_imported_file_parent'} style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                                        <Icon {...getFileTypeIconProps({extension: (f.name) ? getFileExtension(f.name) : '', size: 16}) }/>
                                        <Text  variant="smallPlus" style={{}}>{f.name}</Text> 
                                        {!loadingContent && <FontIcon iconName={t("Cancel")} style={{cursor: "pointer", color: 'red' }}  onClick={() => { setContextItemProperty({id: 'uploadParent', name: 'files', value: _.filter(parentFileForm.files, of => of.name !== f.name)}) }} />}
                                    </div>
                                );
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    parentFileForm: (state) => (getGenericModal('uploadParent')(state)) ? (getGenericModal('uploadParent')(state) as any).contextItem : undefined,
    loading: (state) => getLoader('document')(state).loading,
    documentsToUpload: getDocumentsToUpload,
    documentsToUploadTotal: getDocumentsToUploadTotal,
    engPhases: getEngPhases,
    purposes: getPurposes,
    entities: getEntities,
    types: getTypes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty,
        openGenericModal: ModalCommandActions.openModal
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(UploadParent)));