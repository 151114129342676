import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    mainContainer: string,
    bodyContainer: string
}
  
export const getClassNames = (darkModeEnabled: boolean): IComponentClassNames => {
    return mergeStyleSets({
        mainContainer:{
            display: 'flex',
            justifyContent: 'flex-start',
            paddingTop: 48
        },
        bodyContainer: {
            flexGrow: 1,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: (darkModeEnabled) ? '#1c1c1c' : undefined,
            height: '100vh'
        }
    });
};