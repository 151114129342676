export class Purpose {
    id?: number
    name?: string
    description?: string
    isWithApproval: boolean

    constructor(object: any){
        this.id = object.purposeId;
        this.name = object.purposeCode;
        this.description = object.purposeName;
        this.isWithApproval = object.isWithApproval;
    }
}

export class PurposeConfig {
    purposeId?: number
    isWithApproval: boolean

    constructor(object: any){
        this.purposeId = object.purposeId;
        this.isWithApproval = object.isWithApproval;
    }
}