import {configuration} from '../configuration'

// GET
export const getPublicationChartUrl = (projectId: number): string => {
     return `${configuration.api_endpoint}/GetPublicationStatus?projectId=${projectId}` ;
}; 
export const getClientChartUrl = (projectId: number): string => {
    return `${configuration.api_endpoint}/GetStatuses?projectId=${projectId}` ;
}; 
export const getFinalPurposeChartUrl = (projectId: number): string => {
    return `${configuration.api_endpoint}/GetFinalPurposes?projectId=${projectId}` ;
}; 
