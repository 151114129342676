import { configuration } from "../configuration";
import { getUserRulesOdataQuery, getUsersRulesOdataQuery } from "./odataDocumentQueries";

export const getEventTypesUrl = configuration.notify + "notificationwebapi/api/EventApplications?$expand=eventTypes&$filter=Application eq 'EXOD2'";
//export const getUserRulesUrl = (email: string): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRuleUsers?$expand=EventTypeRule($expand=EventType($expand=EventApplication($filter=Application eq 'EXOD2')))&$filter=UserId eq '${email}'`;}
export const getUserRulesUrl = (projectId: number, email: string): string => {
    const query = getUserRulesOdataQuery(projectId, email) as string;
    return `${configuration.notify}notificationwebapi/api/EventTypeRuleUsers${(query) ? query : ''}`;
}; 
export const getUsersRulesUrl = (projectId: number, emails: string[]): string => {
    const query = getUsersRulesOdataQuery(projectId, emails) as string;
    return `${configuration.notify}notificationwebapi/api/EventTypeRuleUsers${(query) ? query : ''}`;
}; 

export const createRuleUrl = (): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRules/PostEventTypeRule`;}
export const editRuleUrl = (id: number): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRules/${id}`;}
export const deleteRuleUrl = (id: number, email: string): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRules/DeleteEventTypeRule?EventTypeRuleId=${id}&userMail=${email}`;}
export const createMultipleRulesUrl = (): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRules/PostMultipleEventTypeRule`;}
export const deleteMultipleRulesUrl = (): string => {return `${configuration.notify}notificationwebapi/api/EventTypeRules/DeleteMultipleEventTypeRuleByUser`;}
