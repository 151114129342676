import {Actions, CommandActions, DocumentActions, RESET_AND_CLOSE_MODAL, SAVE_USERS, FETCH_INTERNAL_USERS, FETCH_EXTERNAL_USERS, REMOVE_INTERNAL_USERS, REMOVE_EXTERNAL_USERS, GET_STAKEHOLDER_IDS,
        FETCH_INTERNAL_ROLES, FETCH_EXTERNAL_ROLES, SAVE_INTERNAL_USERS, SAVE_EXTERNAL_USERS, FETCH_USERS, SAVE_NEW_EXTERNAL_USER, DOWNLOAD_USERS, CREATE_PROJECT_ROLES, CHECK_EXTERNAL_USER} from "../../actions/administrationModal";
import {ApiConfigActions, ApiModels} from "@pwbapps/reduxcore";
import {DocumentActions as ModalDocumentActions, CommandActions as ModalCommandActions} from "../../actions/modal";
import {CommandActions as LoaderCommandActions} from "../../actions/loader";
import {getInternalAppRoles, getExternalAppRoles, getInternalEditUsers, getExternalFilter, getInternalFilter, getExternalEditUsers, getExternalAppUsers} from "../../reducers/administrationModal";
import _ from 'lodash';
import { MessageBarType } from "office-ui-fabric-react";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { AppUser, ExternalUserForm, Role, UserRoleApi } from "../../../models/administration";
import {addRoleToExternalUserUrl, addRoleToInternalUserUrl, getExternalUsersUrl, getInternalUsersUrl, getInternalRolesUrl, getExternalRolesUrl, newExternalUserUrl, dowloadUsersUrl, removeRoleToInternalUserUrl, removeRoleToExternalUserUrl, createInternalRoleUrl, createExternalRoleUrl, getSingleExternalUser} from '../../../utils/administrationUrls';
import { getSelectedProjectId } from "../../reducers/project";
import { getLoader } from "../../selectors/loader";
import { getEmail } from "@pwbapps/reduxcore/dist/redux/reducers/auth";
import { getGenericModal } from "../../selectors/ui";
import { Modal } from "../../../models/modal";
import { isProdEnv, validateEmail } from "../../../utils/functions";
import saveAs from "file-saver";
import { CommandActions as ProjectsCommandActions, DocumentActions as ProjectsDocumentActions } from "../../actions/project";
import { CommandActions as NotifySettingsCommandActions, EventActions as NotifySettingsEventActions } from "../../actions/notifySettings";
import { NotifyRule } from "../../../models/notifyRule";
import { getReports } from "../../reducers/sharedData";
import { getEventTypes } from "../../reducers/notifySettings";
import { createMultipleRulesUrl } from "../../../utils/urls";
import { getStakeholderIdsByCompaniesUrl } from "../../../utils/documentsUrls";
import { getSelectedProjectCodeword, getSelectedProjectName } from "../../selectors/project";

export const administrationModalMiddlewareHistory = (history: any) => {
    return administrationModalMiddleware(history);
}

export const administrationModalMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions ) => {
    next(action);
    switch (action.type) {
        case FETCH_INTERNAL_ROLES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getInternalRolesUrl(), feature: FETCH_INTERNAL_ROLES}}));    
            break;

        case FETCH_EXTERNAL_ROLES:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getExternalRolesUrl(), feature: FETCH_EXTERNAL_ROLES}}));    
            break;

        case FETCH_USERS:
            dispatch(CommandActions.fetchInternalUsers());
            dispatch(CommandActions.fetchExternalUsers());
            if(action.payload.openModal)
                dispatch(CommandActions.setOpenModal({value: true}));
            break;

        case FETCH_INTERNAL_USERS: {
            let internalFilter = getInternalFilter(getState());      
            let codeword = _.replace(getSelectedProjectCodeword(getState()) as string, /\s/g, '');  
            if(!getLoader('administration')(getState()).loadingInternal)
                next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getInternalUsersUrl(codeword, internalFilter), feature: FETCH_INTERNAL_USERS}}));       
            break;
        }

        case FETCH_EXTERNAL_USERS: {
            let externalFilter = getExternalFilter(getState());     
            let codeword = _.replace(getSelectedProjectCodeword(getState()) as string, /\s/g, '');  
            if(!getLoader('administration')(getState()).loadingExternal)
                next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getExternalUsersUrl(codeword, externalFilter), feature: FETCH_EXTERNAL_USERS}}));          
            break;
        }

        case RESET_AND_CLOSE_MODAL:
            next(CommandActions.setOpenModal({value: false}));
            next(DocumentActions.setUsersArray({arrayName: 'internalEditedUsers', users: []}));
            next(DocumentActions.setUsersArray({arrayName: 'internalAppUsers', users: []})); 
            next(DocumentActions.setUsersArray({arrayName: 'externalEditedUsers', users: []}));
            next(DocumentActions.setUsersArray({arrayName: 'externalAppUsers', users: []})); 
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
            next(CommandActions.setInternalFilter({value: undefined}));
            next(CommandActions.setExternalFilter({value: undefined}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: false, type: MessageBarType.info, message: ''})); 
            break;

        case SAVE_USERS: {
            const internalEditedUsers = getInternalEditUsers(getState());
            const externalEditedUsers = getExternalEditUsers(getState());
            if(internalEditedUsers.length > 0 || externalEditedUsers.length > 0){
                next(LoaderCommandActions.setLoading({feature: 'administration', loading: true}));
                const internalEditUsersToAdd = _.filter(internalEditedUsers, iu => iu.role && iu.role.name !== 'NONE') as AppUser[];
                const internalEditUsersToRemove = _.filter(internalEditedUsers, iu => iu.role && iu.role.name === 'NONE') as AppUser[];
                if(internalEditUsersToAdd.length > 0){
                    next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: true}));
                    dispatch(CommandActions.saveInternalUsers({editedUsers: internalEditUsersToAdd}));
                    if(isProdEnv()){
                        dispatch(NotifySettingsEventActions.saveMultipleRules({users: internalEditUsersToAdd as AppUser[], isExternal: false}));
                        dispatch(NotifySettingsEventActions.deleteMultipleRules({users: internalEditUsersToAdd, all: false, isExternal: false}));
                    }
                }
                if(internalEditUsersToRemove.length > 0){
                    next(LoaderCommandActions.setLoadingInternalRemove({feature: 'administration', loading: true}));
                    dispatch(CommandActions.removeInternalUsers({editedUsers: internalEditUsersToRemove}));
                    if(isProdEnv())
                        dispatch(NotifySettingsEventActions.deleteMultipleRules({users: internalEditUsersToRemove, all: true, isExternal: false}));
                }
                const externalEditUsersToAdd = _.filter(externalEditedUsers, eu => eu.role && eu.role.name !== 'NONE') as AppUser[];
                const externalEditUsersToRemove = _.filter(externalEditedUsers, eu => eu.role && eu.role.name === 'NONE') as AppUser[];
                if(externalEditUsersToAdd.length > 0){
                    next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: true}));
                    dispatch(CommandActions.saveExternalUsers({editedUsers: externalEditUsersToAdd}));   
                    if(isProdEnv()){
                        dispatch(NotifySettingsEventActions.saveMultipleRules({users: externalEditUsersToAdd as AppUser[], isExternal: true}));
                        dispatch(NotifySettingsEventActions.deleteMultipleRules({users: externalEditUsersToAdd, all: false, isExternal: true}));
                    }               
                }
                if(externalEditUsersToRemove.length > 0){
                    next(LoaderCommandActions.setLoadingExternalRemove({feature: 'administration', loading: true}));
                    dispatch(CommandActions.removeExternalUsers({editedUsers: externalEditUsersToRemove}));
                    if(isProdEnv())
                        dispatch(NotifySettingsEventActions.deleteMultipleRules({users: externalEditUsersToRemove, all: true, isExternal: true}));
                }
            }
            break;
        }

        case SAVE_INTERNAL_USERS: {
            const internalEditedUsers = action.payload.editedUsers;
            if(internalEditedUsers.length > 0){
                const ieu = internalEditedUsers[0];
                const userRole: UserRoleApi = new UserRoleApi(ieu);       
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'PUT', url: addRoleToInternalUserUrl(userRole), feature: SAVE_INTERNAL_USERS, returnObject: {internalEditedUsers: _.filter(internalEditedUsers, u => u.id !== ieu.id)}}}));
            }          
            break;
        }

        case REMOVE_INTERNAL_USERS: {
            const internalEditedUsers = action.payload.editedUsers;
            if(internalEditedUsers.length > 0){
                const ieu = internalEditedUsers[0];
                const userRoleId = (ieu.role) ? ieu.role.userRoleId : undefined;  
                if(userRoleId)     
                    dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'DELETE', url: removeRoleToInternalUserUrl(userRoleId), feature: REMOVE_INTERNAL_USERS, returnObject: {internalEditedUsers: _.filter(internalEditedUsers, u => u.id !== ieu.id)}}}));
            }          
            break;
        }

        case SAVE_EXTERNAL_USERS: {
            const externalEditedUsers = action.payload.editedUsers;
            if(externalEditedUsers.length > 0){
                const ieu = externalEditedUsers[0];
                const userRole: UserRoleApi = new UserRoleApi(ieu);     
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'PUT', url: addRoleToExternalUserUrl(userRole), feature: SAVE_EXTERNAL_USERS, returnObject: {externalEditedUsers: _.filter(externalEditedUsers, u => u.id !== ieu.id)}}}));
            }          
            break;
        }

        case REMOVE_EXTERNAL_USERS: {
            const externalEditedUsers = action.payload.editedUsers;
            if(externalEditedUsers.length > 0){
                const ieu = externalEditedUsers[0];
                const userRoleId = (ieu.role) ? ieu.role.userRoleId : undefined;   
                if(userRoleId) 
                    dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'DELETE', url: removeRoleToExternalUserUrl(userRoleId), feature: REMOVE_EXTERNAL_USERS, returnObject: {externalEditedUsers: _.filter(externalEditedUsers, u => u.id !== ieu.id)}}}));
            }          
            break;
        }
       
        case SAVE_NEW_EXTERNAL_USER: {
            const newUserForm = (getGenericModal('newExternalUser')(getState())) ? (getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm : undefined;
            if(newUserForm){
                if(newUserForm.email && newUserForm.company && newUserForm.country && newUserForm.role){
                    next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: true}));
                    dispatch(CommandActions.checkExternalUser({newUserForm}));
                } 
                else
                   validateExternalUser(newUserForm, next, getState);            
            }          
            break;
        }

        case CHECK_EXTERNAL_USER: {
            const newUserForm = action.payload.newUserForm;
            if(newUserForm && newUserForm.email){    
                let vaildEmail = (newUserForm.email) ? (validateEmail(newUserForm.email) ? true : false) : false;
                if(vaildEmail)
                    dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getSingleExternalUser(getSelectedProjectId(getState()) as number, newUserForm.email as string), feature: CHECK_EXTERNAL_USER, returnObject: {newUserForm}}}));    
                else
                    next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, email: (!newUserForm.email) ? 'This field is mandatory' : ((vaildEmail) ? undefined : 'Invalid email format')}}));
            }          
            break;
        }

        case DOWNLOAD_USERS:
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                request: { 
                    method: 'POST',  
                    headers: [{name: 'Accept', value: 'application/octet-stream'}],
                    responseType: 'arraybuffer',
                    body: {
                        projectIds: [getSelectedProjectCodeword(getState())],
                        fileName: getSelectedProjectCodeword(getState()) + '_Users' 
                    },
                    url: dowloadUsersUrl(), 
                    feature: DOWNLOAD_USERS,
                    returnObject: {fileName: getSelectedProjectCodeword(getState()) + '_Users' }
                }
            }));    
            break;

        case CREATE_PROJECT_ROLES:
            let projectId = action.payload.projectId;
            projectId = _.replace(projectId, /\s/g, '');
            let requests: ApiModels.Request[] = [
                { method: 'POST', url: createInternalRoleUrl(projectId + '_ADMINISTRATOR'), feature: 'CREATE INTERNAL ADMINISTRATOR'},
                { method: 'POST', url: createInternalRoleUrl(projectId + '_EEOHANDLER'), feature: 'CREATE INTERNAL EEOHANDLER'},
                { method: 'POST', url: createInternalRoleUrl(projectId + '_PUBLISHER'), feature: 'CREATE INTERNAL PUBLISHER'},
                { method: 'POST', url: createInternalRoleUrl(projectId + '_READER'), feature: 'CREATE INTERNAL READER'},
                { method: 'POST', url: createExternalRoleUrl(projectId + '_SUPPLIER'), feature: 'CREATE EXTERNAL SUPPLIER'},
                { method: 'POST', url: createExternalRoleUrl(projectId + '_CHECKER'), feature: 'CREATE EXTERNAL CHECKER'},
                { method: 'POST', url: createExternalRoleUrl(projectId + '_APPROVER'), feature: 'CREATE EXTERNAL APPROVER'},
                { method: 'POST', url: createExternalRoleUrl(projectId + '_PARTNER'), feature: 'CREATE EXTERNAL PARTNER'},
                { method: 'POST', url: createExternalRoleUrl(projectId + '_READER'), feature: 'CREATE EXTERNAL READER'}
            ];
            dispatch(ApiConfigActions.CommandActions.apiMultipleTokenRequest({requests, externalFeature: CREATE_PROJECT_ROLES}));
            break;

        case GET_STAKEHOLDER_IDS:
            const companies = action.payload.companies;
            if(companies.length > 0)
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getStakeholderIdsByCompaniesUrl(companies), feature: GET_STAKEHOLDER_IDS}}));    
            else{

            }
            break;

        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(dispatch, getState, next, action);         
            break;
       
        default:
            break;
    }
};


const apiSuccessMiddleware = (dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){
        case FETCH_INTERNAL_ROLES: {
            let roles = (action.payload) ? action.payload : undefined;
            let rolesMapped = _.map(roles, p => {return new Role(p)});
            next(CommandActions.setInternalRoles({value: [{id: 'NONE', name: 'NONE', azureName: 'NONE'}, ...rolesMapped]}));
            break;
        }

        case FETCH_EXTERNAL_ROLES: {
            let roles = (action.payload) ? action.payload : undefined;
            let rolesMapped = _.map(roles, p => {return new Role(p)});
            next(CommandActions.setExternalRoles({value: [{id: 'NONE', name: 'NONE', azureName: 'NONE'}, ...rolesMapped]}));
            break;
        }

        case FETCH_INTERNAL_USERS: {
            let internalUsers = (action.payload) ? action.payload : undefined;
            let internalUsersMapped = _.map(internalUsers, p => {return new AppUser(p, getInternalAppRoles(getState()))});
            internalUsersMapped = _.filter(internalUsersMapped, iu => iu.email) as AppUser[]; // filtro utenti senza email
            let internalEditUsers = getInternalEditUsers(getState());
            if(internalEditUsers.length > 0){
                internalUsersMapped = _.map(internalUsersMapped, (u) => { 
                    let userEdited = _.find(internalEditUsers, (eu) => { return u.email === eu.email});
                    return (userEdited) ? userEdited : u;
                });
            }
            if(internalUsersMapped.length > 0)
                dispatch(NotifySettingsCommandActions.fetchUsersRules({users: internalUsersMapped, isExternal: false}));   
            else        
                next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: false}));
            break;
        }

        case FETCH_EXTERNAL_USERS: {
            let externalUsers = (action.payload) ? action.payload : undefined;
            let externalUsersMapped = _.map(externalUsers, p => {return new AppUser(p, getExternalAppRoles(getState()))});
            externalUsersMapped = _.filter(externalUsersMapped, eu => eu.email) as AppUser[]; // filtro utenti senza email
            let externalEditUsers = getExternalEditUsers(getState());
            if(externalEditUsers.length > 0){
                externalUsersMapped = _.map(externalUsersMapped, (u) => { 
                    let userEdited = _.find(externalEditUsers, (eu) => { return u.email === eu.email});
                    return (userEdited) ? userEdited : u;
                });
            }
            if(externalUsersMapped.length > 0){
                dispatch(NotifySettingsCommandActions.fetchUsersRules({users: externalUsersMapped, isExternal: true}));
                dispatch(CommandActions.getStakeHolderIds({companies: _.uniq(_.map(externalUsersMapped, u => u.company as string))}));
            }
            else
                next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
            break;
        }

        case SAVE_INTERNAL_USERS: {
            let internalEditedUsers: AppUser[] = action.meta.returnObject.internalEditedUsers;
            if(internalEditedUsers.length > 0){
                dispatch(CommandActions.saveInternalUsers({editedUsers: internalEditedUsers}));
            }
            else {
                next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingInternalRemove && !getLoader('administration')(getState()).loadingInternalNotification && !getLoader('administration')(getState()).loadingInternalNotificationRemove){
                    next(DocumentActions.setUsersArray({arrayName: 'internalEditedUsers', users: []}));
                    dispatch(CommandActions.fetchInternalUsers());
                    const externalEditUsers = getExternalEditUsers(getState());
                    if(externalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved successfully'}));  
                    }
                }
            }
            break;
        }

        case REMOVE_INTERNAL_USERS: {
            let internalEditedUsers: AppUser[] = action.meta.returnObject.internalEditedUsers;
            if(internalEditedUsers.length > 0){
                dispatch(CommandActions.removeInternalUsers({editedUsers: internalEditedUsers}));
            }
            else {
                next(LoaderCommandActions.setLoadingInternalRemove({feature: 'administration', loading: false}));
                if(!getLoader('administration')(getState()).loadingInternal && !getLoader('administration')(getState()).loadingInternalNotification && !getLoader('administration')(getState()).loadingInternalNotificationRemove){
                    next(DocumentActions.setUsersArray({arrayName: 'internalEditedUsers', users: []}));
                    dispatch(CommandActions.fetchInternalUsers());
                    const externalEditUsers = getExternalEditUsers(getState());
                    if(externalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved successfully'}));  
                    }
                }
            }
            break;
        }

        case SAVE_EXTERNAL_USERS: {
            let externalEditedUsers: AppUser[] = action.meta.returnObject.externalEditedUsers;
            if(externalEditedUsers.length > 0){
                dispatch(CommandActions.saveExternalUsers({editedUsers: externalEditedUsers}));
            }
            else {         
                let newUser = action.meta.returnObject.new;
                next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));  
                if(newUser){
                    dispatch(CommandActions.fetchExternalUsers());
                    dispatch(ModalCommandActions.closeModal({id: 'newExternalUser'}));
                    next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User created successfully'}));  
                }
                else if(!getLoader('administration')(getState()).loadingExternalRemove && !getLoader('administration')(getState()).loadingExternalNotification && !getLoader('administration')(getState()).loadingExternalNotificationRemove){  
                    next(DocumentActions.setUsersArray({arrayName: 'externalEditedUsers', users: []}));
                    dispatch(CommandActions.fetchExternalUsers());
                    const internalEditUsers = getInternalEditUsers(getState());
                    if(internalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved successfully'}));  
                    }
                }
            }
            break;
        }

        case REMOVE_EXTERNAL_USERS: {
            let externalEditedUsers: AppUser[] = action.meta.returnObject.externalEditedUsers;
            if(externalEditedUsers.length > 0){
                dispatch(CommandActions.removeExternalUsers({editedUsers: externalEditedUsers}));
            }
            else {   
                next(LoaderCommandActions.setLoadingExternalRemove({feature: 'administration', loading: false}));   
                if(!getLoader('administration')(getState()).loadingExternal && !getLoader('administration')(getState()).loadingExternalNotification && !getLoader('administration')(getState()).loadingExternalNotificationRemove){
                    next(DocumentActions.setUsersArray({arrayName: 'externalEditedUsers', users: []}));
                    dispatch(CommandActions.fetchExternalUsers());
                    const internalEditUsers = getInternalEditUsers(getState());
                    if(internalEditUsers.length === 0){
                        next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'User permissions saved successfully'}));  
                    }
                }      
            }
            break;
        }

        case SAVE_NEW_EXTERNAL_USER: {
            let email = action.meta.returnObject.email as string;
            let role = action.meta.returnObject.role as Role;
            let userId = (action.payload) ? action.payload.idUser : undefined;
            let externalUserMapped: AppUser =  {id: userId, email, role };
            const userRole: UserRoleApi = new UserRoleApi(externalUserMapped);     
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'PUT', url: addRoleToExternalUserUrl(userRole), feature: SAVE_EXTERNAL_USERS, returnObject: {externalEditedUsers: [], new: true}}}));         
            break;
        }

        case CHECK_EXTERNAL_USER: {
            let newUserForm = action.meta.returnObject.newUserForm as ExternalUserForm;
            let externalUser = (action.payload && action.payload.length > 0) ? action.payload[0] : undefined;
            let externalUserMapped = (externalUser) ? new AppUser(externalUser, getExternalAppRoles(getState())) : undefined;
            if(!externalUserMapped){
                next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: { email: undefined, country: undefined}}));
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'POST', url: newExternalUserUrl(newUserForm), feature: SAVE_NEW_EXTERNAL_USER, returnObject: {email: newUserForm.email, role: newUserForm.role}}}));
                if(isProdEnv())
                    dispatch(NotifySettingsEventActions.saveMultipleRules({users: [{company: newUserForm.company, country: newUserForm.country, name: newUserForm.email, email: newUserForm.email, role: newUserForm.role, notifications: newUserForm.notifications}], isExternal: true }));
            }
            else{
                next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
                validateExternalUser(newUserForm, next, getState);   
                next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, email: 'This email is already used' }}));
            }
            
            break;
        }

        case DOWNLOAD_USERS: {
            let xlsx = action.payload;
            let fileName = action.meta.returnObject.fileName as string;
            if(xlsx) 
                saveAs(new Blob([xlsx]),`${fileName}.xlsx`);
            dispatch(LoaderCommandActions.setLoading({ feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.success, message: 'Download completed'}));  
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: false, type: MessageBarType.info, message: ''})); }, 5000);
            break;
        }

        case 'CREATE EXTERNAL READER':
            dispatch(CommandActions.fetchInternalRoles());
            dispatch(CommandActions.fetchExternalRoles());
            dispatch(ProjectsCommandActions.fetchProjects({isConfigurationPage: true}));
            dispatch(ProjectsCommandActions.fetchProjects({}));
            next(ProjectsDocumentActions.setModalOpened({value: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'project', show: true, type: MessageBarType.success, message: 'Project saved successfully'})); 
            setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: 'project', show: false, type: MessageBarType.info, message: ''})); }, 5000);  
            break;

        case GET_STAKEHOLDER_IDS: {
            let stakeHoldersPayload = (action.payload && action.payload.value) ? action.payload.value : undefined;
            let externalUsersUpdated = _.map(getExternalAppUsers(getState()), u => {return {...u, stakeHolderId: _.find(stakeHoldersPayload, s => s.companyName && u.company && s.companyName === u.company) ? ((_.find(stakeHoldersPayload, s => s.companyName && u.company && s.companyName === u.company) as any).stakeHolderId as number) : undefined} }) as AppUser[];
            next(CommandActions.setExternalUsers({value: _.orderBy(externalUsersUpdated, iu => iu.name)}));
            break;
        }
            
        default:
            break;
    }
}

const apiErrorMiddleware = (dispatch: any, history: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){
        case FETCH_INTERNAL_ROLES: 
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot fetch internal app roles. Please refresh the page'}));  
            break;

        case FETCH_EXTERNAL_ROLES: 
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot fetch external app roles. Please refresh the page'}));  
            break;

        case FETCH_INTERNAL_USERS: 
            next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot fetch internal users. Please retry'}));  
            break;

        case FETCH_EXTERNAL_USERS: 
            next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot fetch external users. Please retry'}));  
            break;

        case SAVE_USERS:
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot save users. Please retry'}));  
            break;

        case SAVE_INTERNAL_USERS:
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
            next(LoaderCommandActions.setLoadingInternal({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot save internal users. Please retry'}));  
            break;

        case SAVE_EXTERNAL_USERS:
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
            next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot save external users. Please retry'}));  
            break;

        case SAVE_NEW_EXTERNAL_USER:
            next(LoaderCommandActions.setLoadingExternal({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot create new external user. Please retry'}));  
            break;

        case DOWNLOAD_USERS: {
            next(LoaderCommandActions.setLoading({feature: 'administration', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'administration', show: true, type: MessageBarType.error, message: 'Cannot download user report. Please retry'}));  
            break;
        }
    

        default:
            break;
    }
}

const validateExternalUser = (newUserForm: ExternalUserForm, next: any, getState: any) => {
    let vaildEmail = (newUserForm.email) ? (validateEmail(newUserForm.email) ? true : false) : false;
    next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, email: (!newUserForm.email) ? 'This field is mandatory' : ((vaildEmail) ? undefined : 'Invalid email format')}}));
    next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, company: (!newUserForm.company) ? 'This field is mandatory' : undefined}}));
    next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, country: (!newUserForm.country) ? 'This field is mandatory' : undefined}}));
    next(ModalDocumentActions.setContextItemProperty({id: 'newExternalUser', name: 'errors', value: {...((getGenericModal('newExternalUser')(getState()) as Modal).contextItem as ExternalUserForm).errors, role: (!newUserForm.role) ? 'This field is mandatory' : undefined}}));
}
