import _ from "lodash";
import { Purpose } from "./purpose";
import { Report } from "./report";
import { StakeHolder } from "./stakeHolder";


export class Project {
    projectId: number
    projectName?: string;
    projectCodeword?: string
    isFolderPageActive: boolean = false
    isClientNumGeneratorPageActive: boolean = false
    isInternalCheckActive: boolean = false
    isExternalCheckActive: boolean = false
    isApproveWithCommentsStatusActive: boolean = false
    isAgileTdCoverPageInternalUse: boolean = false
    automaticApprovalDays?: number
    mandatoryDrawingColumns: string[]
    mandatoryDocumentColumns: string[]
    template?:  'Title45' | 'Title45ML' | 'Title456' | 'Title456ML'
    templateTitles: {name: string, value: string}[]
    isActivatedInAgile: boolean = false
    isEnabled: boolean = false
    eeoConfigurationId?: number
    projectConfigurationId?: number

    constructor(object: any){
        this.projectId = object.projectId;
        this.projectName = object.projectCodeword;
        this.isActivatedInAgile = object.isActivatedInAgile;
        this.isEnabled = object.isEnabled;
        this.projectCodeword = object.projectCodeword;
        if(object.projectConfigurations && object.projectConfigurations.length > 0){
            this.projectConfigurationId = object.projectConfigurations[0].projectConfigurationId;
            this.isFolderPageActive = object.projectConfigurations[0].isFolderPageActive;
            this.isClientNumGeneratorPageActive = object.projectConfigurations[0].isClientNumGeneratorPageActive;
            this.isInternalCheckActive = object.projectConfigurations[0].isInternalCheckActive;
            this.isExternalCheckActive = object.projectConfigurations[0].isExternalCheckActive;
            this.isApproveWithCommentsStatusActive = object.projectConfigurations[0].isApproveWithCommentsStatusActive;
            this.isAgileTdCoverPageInternalUse = object.projectConfigurations[0].isAgileTdCoverPageInternalUse;
            this.automaticApprovalDays = object.projectConfigurations[0].automaticApprovalDays;
        }
        if(object.eeoConfigurations && object.eeoConfigurations.length > 0){
            this.eeoConfigurationId = object.eeoConfigurations[0].eeoConfigurationId;
            let mdrc = object.eeoConfigurations[0].mandatoryDrawingColumns;
            if(mdrc)
                this.mandatoryDrawingColumns = (mdrc && mdrc.includes(';')) ? _.map(mdrc.split(';'), c => c.toLocaleLowerCase()) : [mdrc.toLocaleLowerCase()];
            else
                this.mandatoryDrawingColumns = [];
            let mdoc = object.eeoConfigurations[0].mandatoryDocumentColumns;
            if(mdoc)
                this.mandatoryDocumentColumns = (mdoc && mdoc.includes(';')) ? _.map(mdoc.split(';'), c => c.toLocaleLowerCase()) : [mdoc.toLocaleLowerCase()];
            else
                this.mandatoryDocumentColumns = [];
            this.template = object.eeoConfigurations[0].template;
            if(this.template === 'Title45')
                this.templateTitles = [{name: 'title4Pl', value: 'Title 1'}, {name: 'title5Pl', value: 'Title 2'}];
            else if(this.template === 'Title45ML')
                this.templateTitles = [{name: 'title4Pl', value: 'Title 1'}, {name: 'title4', value: 'Title 1 En'}, {name: 'title5Pl', value: 'Title 2'}, {name: 'title5', value: 'Title 2 En'}];
            else if(this.template === 'Title456')
                this.templateTitles = [{name: 'title4Pl', value: 'Title 1'}, {name: 'title5Pl', value: 'Title 2'}, {name: 'title6Pl', value: 'Title 3'}];
            else if(this.template === 'Title456ML')
                this.templateTitles = [{name: 'title4Pl', value: 'Title 1'}, {name: 'title4', value: 'Title 1 En'}, {name: 'title5Pl', value: 'Title 2'}, {name: 'title5', value: 'Title 2 En'}, {name: 'title6Pl', value: 'Title 3'}, {name: 'title6', value: 'Title 3 En'}];
            else
                this.templateTitles = [];
        }
        else{
            this.mandatoryDrawingColumns = [];
            this.mandatoryDocumentColumns = [];
            this.templateTitles = [];            
        }
    }
}

export class ProjectForm {
    projectId?: number
    projectName?: string
    projectCodeword?: string
    isEnabled: boolean

    projectConfigurationId?: number
    automaticApprovalDays?: number

    eeoConfigurationId?: number
    mandatoryDrawingColumns: string[]
    mandatoryDocumentColumns: string[]
    template?: string

    purposeList: { purposeId?: number, name?: string, description?: string, isWithApproval: boolean}[] 
    stakeHolders: StakeHolder[]
    reports: Report[]

    constructor(purposes: Purpose[], stakeHolders?: StakeHolder[], object?: Project, reports?: Report[]){
        this.mandatoryDrawingColumns = [];
        this.mandatoryDocumentColumns = [];
        if(object){
            this.projectId = object.projectId;
            this.projectName = object.projectName;
            this.projectCodeword = object.projectCodeword;
            this.isEnabled = object.isEnabled;
            this.projectConfigurationId = object.projectConfigurationId;
            this.automaticApprovalDays = object.automaticApprovalDays;
            this.eeoConfigurationId = object.eeoConfigurationId;
            this.mandatoryDrawingColumns = object.mandatoryDrawingColumns;
            this.mandatoryDocumentColumns = object.mandatoryDocumentColumns;
            this.template = object.template;
            this.purposeList = _.map(purposes, p => {return {purposeId: p.id as number, name: p.name, description: p.description, isWithApproval: p.isWithApproval}});
            this.stakeHolders = (stakeHolders && stakeHolders.length > 0) ? [...stakeHolders] : [];
            this.reports = (reports && reports.length > 0) ? [...reports] : [];
        }
        else{
            this.isEnabled = true;
            this.mandatoryDrawingColumns = ['format', 'scale'];
            this.purposeList = _.map(purposes, p => {
                return {purposeId: p.id as number, name: p.name, description: p.description, isWithApproval: (p.name === 'FA' || p.name === 'FAB' || p.name === 'FC' || p.name === 'FCB') ? true : false} 
            });
            this.stakeHolders = [];
            this.reports = [];
        }
    }
}