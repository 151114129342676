import { Modal } from "../../models/modal";
import * as _ from 'lodash';
import {DocumentActions, SET_NEW_MODAL, SET_VISIBILITY, SET_LOADING, SET_OPTIONAL, SET_NOTES, SET_CONTEXT_ITEM, SET_CONTEXT_ITEM_PROPERTY} from "../actions/modal";


interface ModalState  {
    modals: Modal[]
}

const initialState: ModalState | undefined = {
    modals: []
}

export const modalReducer = (modal: ModalState | undefined = initialState, action : DocumentActions): ModalState => {
    switch (action.type) {
        
        case SET_NEW_MODAL:
            return {...modal, modals: !_.find(modal.modals, m => m.id === action.payload.id) ? [...modal.modals, new Modal(action.payload.id)] : [...modal.modals]};

        case SET_VISIBILITY:
            return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, visible: action.payload.visible } : m})]};

        case SET_LOADING:
            return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, loading: action.payload.loading } : m})]};

        case SET_OPTIONAL:
                return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, optional: action.payload.optional } : m})]};

        case SET_NOTES:
            return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, notes: action.payload.notes } : m})]};

        case SET_CONTEXT_ITEM:
            return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, contextItem: action.payload.item } : m})]};
        
        case SET_CONTEXT_ITEM_PROPERTY:
            return {...modal, modals: [..._.map(modal.modals, m => {return (m.id === action.payload.id) ? {...m, contextItem: {...m.contextItem, [action.payload.name] : action.payload.value} } : m})]};

     
        default:
            return modal;
    }
};

// select from state (read)
const selectModalState = (state: any) => state.modal as ModalState;
export const getModals = (state: any) => selectModalState(state).modals;