import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {DocumentActions} from '../../redux/actions/ui';
import {getClassNames} from './index.classNames';
import Scrollbars from 'react-custom-scrollbars';
import { getFeedbackType, getFeedbackText } from '../../redux/reducers/ui';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { DefaultButton, IIconProps } from 'office-ui-fabric-react';
import Notification from '../../containers/notification/index';
import { MessageBarType } from 'office-ui-fabric-react';
import { getNotification } from '../../redux/selectors/notification';
import { DocumentActions as NotificationDocumentActions } from '../../redux/actions/notification';

export interface FeedbackProps { 
    feedbackType: string, 
    feedbackText?: string, 
    showNotification: boolean, 
    type: MessageBarType, 
    message: string,
    setFeedbackType: typeof DocumentActions.setFeedbackType, 
    setFeedbackText: typeof DocumentActions.setFeedbackText,
    setNotificationStatus: typeof NotificationDocumentActions.setNotificationStatus
}

const styles = getClassNames();

class Feedback extends React.Component<FeedbackProps> {
    constructor(props: FeedbackProps) {
        super(props);   
    }

    componentDidMount = () => {
        const {setNotificationStatus} = this.props;
        setNotificationStatus({show: false, name: 'rightPanel', type: MessageBarType.info, message: ''})
    };

    render(){
        const { feedbackType, feedbackText, setFeedbackType, showNotification, type, message, setFeedbackText } = this.props;
        const iconSmile: IIconProps = { iconName: 'Emoji2' };
        const iconSad: IIconProps = { iconName: 'Sad' };
        const iconIdea: IIconProps = { iconName: 'Lightbulb' };
        const iconBug: IIconProps = { iconName: 'Bug' };
        return (
            <Scrollbars style={{ height: '89vh' }}>
                <div className={styles.container}>
                    <div className={styles.notification}><Notification name={'rightPanel'} show={showNotification} type={type} message={message}/></div>
                    <Text variant='large' nowrap>What kind of feedback do you have?</Text>
                    <div className={styles.buttonsContainer}>
                        <DefaultButton   
                            primary={feedbackType === 'like'}                        
                            styles={{root: styles.button, flexContainer: styles.flexContainer}}
                            text="I like something"
                            iconProps={iconSmile}
                            onClick={ () => setFeedbackType({type: 'like'}) }
                        />
                        <DefaultButton   
                            primary={feedbackType === 'dontlike'}                        
                            styles={{root: styles.button, flexContainer: styles.flexContainer}}
                            text="I don't like something"
                            iconProps={iconSad}
                            onClick={ () => setFeedbackType({type: 'dontlike'}) }
                        />
                        <DefaultButton
                            primary={feedbackType === 'suggestion'}
                            styles={{root: styles.button, flexContainer: styles.flexContainer}}
                            text="I have a suggestion"
                            iconProps={iconIdea}
                            onClick={ () => setFeedbackType({type: 'suggestion'}) }
                        />
                        <DefaultButton
                            primary={feedbackType === 'bug'}
                            styles={{root: styles.button, flexContainer: styles.flexContainer}}
                            text="File bug"
                            iconProps={iconBug}
                            onClick={ () => setFeedbackType({type: 'bug'}) }
                        />
                    </div>
                    <div className={styles.formContainer}>
                        {feedbackType === 'like' && <Text variant='large' nowrap>What did you like?</Text>}
                        {feedbackType === 'dontlike' && <Text variant='large' nowrap>What did you not like?</Text>}
                        {feedbackType === 'suggestion' && <Text variant='large' nowrap>What do you suggest?</Text>}
                        {feedbackType === 'bug' && <Text variant='large' nowrap>What is the bug?</Text>}
                        <TextField 
                            value={feedbackText} 
                            styles={{root: styles.textBox}} 
                            placeholder={'Please type in your comment'} 
                            multiline 
                            rows={7} 
                            autoAdjustHeight 
                            onChange={ (e, value) => setFeedbackText({text: value}) }
                        />
                    </div>
                </div>
            </Scrollbars>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    feedbackType: getFeedbackType,
    feedbackText: getFeedbackText,
    showNotification: (state) => getNotification('rightPanel')(state).show,
    type: (state) => getNotification('rightPanel')(state).type,
    message: (state) => getNotification('rightPanel')(state).message,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ setFeedbackType: DocumentActions.setFeedbackType, setFeedbackText: DocumentActions.setFeedbackText, setNotificationStatus: NotificationDocumentActions.setNotificationStatus }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(Feedback);