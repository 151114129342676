 import React from 'react';
import { IColumn, IDetailsColumnProps } from 'office-ui-fabric-react/lib/DetailsList';
import {CommandActions as FiltersCommandActions} from '../../redux/actions/filters';
import _ from 'lodash';
import { DirectionalHint, FontIcon, FontSizes, Icon, IconButton, IContextualMenuListProps, IContextualMenuProps, Link, Selection, Text, TooltipHost } from 'office-ui-fabric-react';
import { getClassNames } from './index.classNames';
import { Project } from '../../models/project';
import {CommandActions as ProjectCommandActions} from '../../redux/actions/project';

export const getColumns = ( openOrCloseProjectModal: typeof ProjectCommandActions.openOrCloseProjectModal) => {
      const styles = getClassNames(false);


      const colums: IColumn[] = [
          
        {
          key: 'projectId',
          name: 'Code',
          fieldName: 'projectId',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,
          onRender: (item: Project) => {
            return (
              <Link onClick={() => {
                openOrCloseProjectModal({open: true, projectId: item.projectId})
              }}>{item.projectId}</Link>   
            );                  
          }
           
        },
        {
          key: 'projectName',
          name: 'Number',
          fieldName: 'projectName',
          minWidth: 200,
          maxWidth: 200,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
        },
        {
          key: 'projectCodeword',
          name: 'Project Codeword',
          fieldName: 'projectCodeword',
          minWidth: 140,
          maxWidth: 140,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
        },
        {
          key: 'isEnabled',
          name: 'Enabled',
          fieldName: 'isEnabled',
          minWidth: 90,
          maxWidth: 90,
          isResizable: true,
          //onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: false,   
          onRender: (item: Project) => {
              return (
                <div> {item.isEnabled ? 'Yes' : 'No'}</div>
              );                  
          }
        },
            
        
    ];
    return colums;
}