import { t } from "i18next";

export class EventType {
    eventTypeId?: number
    name?: string
    description?: string

    constructor(object: any){
        this.eventTypeId = object.EventTypeId;
        this.name = object.Name;
        this.description = t(object.Description);
    }
}