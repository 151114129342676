import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from '../index.classNames';
import { Checkbox, ComboBox, Label, Text, TextField} from 'office-ui-fabric-react';
import _ from 'lodash';
import { CommandActions as ModalCommandActions } from '../../../../redux/actions/modal';
import { CommandActions as ProjectCommandActions } from '../../../../redux/actions/project';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getAllProjects, getIsEdit, getProjectFormProjectCodeword, getProjectFormIsEnabled, getProjectFormName, getProjectFormReports } from '../../../../redux/reducers/project';
import { Project } from '../../../../models/project';
import NumberFormat from 'react-number-format';
import { getAllReports, getReports } from '../../../../redux/reducers/sharedData';
import { getReportsDropdownOptions } from '../../../../utils/functions';
import { Report } from '../../../../models/report';

export interface BodyProps extends RouteComponentProps { 
    projectName?: string,
    projectCodeword?: string,
    isEnabled: boolean,
    allProjects: Project[],
    isEdit: boolean,
    allReports: Report[],
    reports: Report[],
    openGenericModal: typeof ModalCommandActions.openModal,
    setProjectFormId: typeof ProjectCommandActions.setProjectFormId,
    setProjectFormName: typeof ProjectCommandActions.setProjectFormName,
    setProjectFormProjectCodeword: typeof ProjectCommandActions.setProjectFormProjectCodeword,
    setProjectFormIsEnabled: typeof ProjectCommandActions.setProjectFormIsEnabled,
    setProjectFormIsActivateInAgile: typeof ProjectCommandActions.setProjectFormIsActivateInAgile,
    setProjectFormReports: typeof ProjectCommandActions.setProjectFormReports,
}

export interface BodyState  { 
    oldProjectName?: string,
}

class Body extends React.Component<BodyProps, BodyState> {
    constructor(props: BodyProps) {
        super(props); 
        this.state = {
            oldProjectName: undefined
        }
    }

    componentDidMount = () => {
        const { projectName, isEdit } = this.props;
        this.setState({oldProjectName: (isEdit) ? projectName : undefined});
    }

    render(){
        const styles = getClassNames();
        const { allProjects, projectName, isEnabled, allReports, reports, projectCodeword, setProjectFormId, setProjectFormName, setProjectFormProjectCodeword, setProjectFormIsEnabled, setProjectFormReports, isEdit } = this.props;
        const { oldProjectName } = this.state;
        const alreadyIn = (!isEdit) ? _.some(allProjects, p => p.projectName === projectName) : ((oldProjectName && _.some(allProjects, p => p.projectName === projectName && projectName !== oldProjectName)));
        const report_options = getReportsDropdownOptions(allReports);
        return ( 
            <>         
                <div className={styles.tabContainer}>    
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap >General</Text></div>   
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>
                        {/* <NumberFormat
                            disabled={isEdit}
                            required
                            label={'Project Id'}
                            customInput={TextField}
                            decimalScale={0}
                            value={(projectId) ? projectId : ''}
                            autoComplete={'off'}
                            onValueChange={(values) => { setProjectFormId({value: values.floatValue})}}
                            styles={{root: {minWidth: 256}}}
                            errorMessage={(alreadyIn) ? 'This project Id is already in Exod' : ''}
                        />      */}
                        <TextField 
                            label="Project Number" 
                            autoComplete={'off'} 
                            value={(projectName) || ''} 
                            onChange={(e, value) => {setProjectFormName({value})}}
                            styles={{root: {minWidth: 256, marginRight: 15}}}
                            errorMessage={(alreadyIn) ? 'This project number is already in Exod' : ''}
                        />
                         <TextField 
                            required
                            label="Project Codeword" 
                            autoComplete={'off'} 
                            value={(projectCodeword) || ''} 
                            onChange={(e, value) => {setProjectFormProjectCodeword({value})}}
                            styles={{root: {minWidth: 256, marginRight: 15}}}
                        />
                    </div>    
                    <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center'}}>                     
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 28}}>
                            <Checkbox 
                                checked={(isEnabled) ? true : false} 
                                onChange={(e, value) => { setProjectFormIsEnabled({value: (value) ? true : false})}} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is enabled</Label>
                        </div>       
                    </div>  
                    <div style={{marginTop: 20}}>
                        <ComboBox
                            multiSelect
                            label={"Reports"} 
                            options={report_options}
                            placeholder={"Select a report..."}
                            onChange={(e, value) => {
                                const alredyIn = (value && reports) ? _.find(reports, s => s.id  === value.key) : undefined;
                                setProjectFormReports({value: (!alredyIn && reports) ? 
                                    [...reports as Report[], _.find(allReports, s => value && s.id === value.key) as Report ] : 
                                    [... _.filter(reports, s => alredyIn && s.id !== alredyIn.id) as Report[]]
                                })
                            }}
                            selectedKey={(reports) ? _.map(reports, s => s.id as number) : []}      
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 220}}
                            styles={{root: {maxWidth: 845}}}
                        />                                                                                
                    </div>
                </div>                        
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allProjects: getAllProjects,
    projectProjectCodeword: getProjectFormProjectCodeword,
    projectName: getProjectFormName,
    projectCodeword: getProjectFormProjectCodeword,
    isEnabled: getProjectFormIsEnabled,
    isEdit: getIsEdit,
    allReports: getAllReports,
    reports: getProjectFormReports
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        openGenericModal: ModalCommandActions.openModal,
        setProjectFormId: ProjectCommandActions.setProjectFormId,
        setProjectFormName: ProjectCommandActions.setProjectFormName,
        setProjectFormProjectCodeword: ProjectCommandActions.setProjectFormProjectCodeword,
        setProjectFormIsEnabled: ProjectCommandActions.setProjectFormIsEnabled,
        setProjectFormIsActivateInAgile: ProjectCommandActions.setProjectFormIsActivateInAgile,
        setProjectFormReports: ProjectCommandActions.setProjectFormReports,
     }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Body));