export class Modal {
    id: ModalIds
    visible: boolean
    contextItem?: any
    optional?: any
    notes?: string

    constructor(id: ModalIds) {
        this.id = id;
        this.visible = false;
        this.contextItem = undefined;
        this.optional = undefined;
        this.notes = undefined;
    }
}

export const ModalIdsValues = [
    "administration",
    "alert",
    "newExternalUser",
    "newStakeHolder",
    "uploadDocuments",
    "changeStatus",
    "editDocument",
    "newComment",
    "fileName",
    "deleteFile",
    "deleteDocument",
    "fileName",
    "comments",
    "excelReport",
    "oneFileWarning",
    "uploadParent",
    "parentWarning",
    "template",
    "importZip",
    "emptyFile",
    "warning",
    "reserveNumber",
    "revision",
    "sheet",
    "folder",
    "newEditFolder",
    "removeFolder",
    "preview",
    "selectLanguage",
    "selectFileType",
    "reserveNumberSupplier",
    "generateNumbAndPart"
] as const;
export type ModalIds = (typeof ModalIdsValues)[number];