import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
 
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({

    });
};