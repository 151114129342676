import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { Dropdown, IDropdownOption, Text } from 'office-ui-fabric-react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { languages } from '../../../translations';
import { withTranslation, WithTranslation  } from 'react-i18next';
import i18n from '../../../i18n';
import { getClassNames } from '../index.classNames';
import { getClassNames as getClassNamesLanguage} from '../../login/languageSelector.classNames';
import { getTheme } from '../../../utils/costants';
import { getContextualDocuments } from '../../../redux/selectors/documents';
import { Document } from '../../../models/document';
import { t } from 'i18next';
import Scrollbars from 'react-custom-scrollbars';
import { getFileTypesDropdownOptions } from '../../../utils/functions';

export interface SelectFileTypeProps extends RouteComponentProps, WithTranslation {
    fileType?: string,
    isNewFileType: boolean,
    selectedDocuments: Document[],
    setContextItem: typeof ModalDocumentAction.setContextItem
}
class SelectFileType extends React.Component<SelectFileTypeProps> {
    constructor(props: SelectFileTypeProps) {
        super(props);   
    }

    render(){
        const { fileType, selectedDocuments, isNewFileType, setContextItem } = this.props;
        let fileTypes_options: {key: string, text: string}[] = [...getFileTypesDropdownOptions()];
        return (
            <>
                {!isNewFileType && <Text variant='mediumPlus' nowrap>{t('You are going to change the file type of the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:'))}</Text>}
                {isNewFileType && <Text variant='mediumPlus' nowrap>{t('You are going to add a new file type for the following ' + ((selectedDocuments.length === 1) ? 'document:' : 'documents:'))}</Text>}
                <Scrollbars style={{ marginTop: 10, marginBottom: 20}} autoHeight autoHeightMax={420}>
                    {_.map(selectedDocuments, (sd, index) => {
                        return <div key={index}><Text nowrap>{sd.documentNumber}</Text></div>
                    })}
                </Scrollbars>                                   
                <Dropdown
                    label={'File type'}
                    placeholder='Select a type'
                    options={fileTypes_options}
                    onChange={(e, value) => { setContextItem({id: 'selectFileType', item: value ? value.key as string : undefined}) }}
                    selectedKey={(fileType) ? fileType : null}     
                    styles={{ dropdownItemsWrapper: {maxHeight: 360} }}
                />                                                   
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    fileType: (state) => (getGenericModal('selectFileType')(state)) ? (getGenericModal('selectFileType')(state) as any).contextItem as string : undefined,
    isNewFileType: (state) => (getGenericModal('selectFileType')(state)) ? (getGenericModal('selectFileType')(state) as any).optional as boolean : false,
    selectedDocuments: getContextualDocuments
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(SelectFileType)));