import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { getClassNames } from './index.classNames';
import { CommandActions as ProjectCommandActions} from '../../../redux/actions/project';
import _ from 'lodash'
import { getLoader } from '../../../redux/selectors/loader';

export interface ContactsProps  {
    loadingContent: boolean, 
    fetchProjects: typeof ProjectCommandActions.fetchProjects,
    openOrCloseProjectModal: typeof ProjectCommandActions.openOrCloseProjectModal,
}

class DocumentsCommandBar extends React.Component<ContactsProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { loadingContent, fetchProjects, openOrCloseProjectModal } = this.props;
        let buttons: ICommandBarItemProps[] = [];
        buttons = [    
            {
                key: 'new',
                name: 'New',
                disabled: (loadingContent),
                onClick: () => { openOrCloseProjectModal({open: true}) },
                iconProps: {
                    iconName: 'Add',
                },
            } ,    
            {
                key: 'refresh',
                name: 'Refresh',
                disabled: (loadingContent),
                onClick: () => { fetchProjects({isConfigurationPage: true}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }                           
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {} = this.props;
      return [         
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    loadingContent: (state) => getLoader('project')(state).loadingContent,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        fetchProjects: ProjectCommandActions.fetchProjects,
        openOrCloseProjectModal: ProjectCommandActions.openOrCloseProjectModal,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)((DocumentsCommandBar));
