import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {AuthConfigActions, AuthConfigReducer, RoleModels} from '@pwbapps/reduxcore';
import { Persona, PersonaSize, IPersonaProps } from 'office-ui-fabric-react/lib/Persona';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { getClassNames } from './settings.classNames';
import { DefaultButton, IconButton, IIconProps, Toggle} from 'office-ui-fabric-react';
import { DocumentActions as UiDocumentActions, CommandActions as UiCommandActions } from '../../redux/actions/ui';
import {CommandActions as AdministrationCommandActions } from '../../redux/actions/administrationModal';
import {DocumentActions as ProjectDocumentActions } from '../../redux/actions/project';
import { TeachingBubble } from 'office-ui-fabric-react/lib/TeachingBubble';
import { DirectionalHint } from 'office-ui-fabric-react/lib/Callout';
import { getShowTeachingBubble, getPanelContent } from '../../redux/reducers/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AccountInfo } from '@azure/msal-browser';
import { getDarkModeEnabled, getSelectedProjectId } from '../../redux/reducers/project';
import { getExternalAppRoles, getInternalAppRoles } from '../../redux/reducers/administrationModal';
import { Role } from '../../models/administration';
import { getSelectedProjectUserRole } from '../../redux/selectors/auth';
import _ from 'lodash';
import { getTheme, isExternalApp } from '../../utils/costants';
import { getRoles } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { getCompany } from '../../redux/reducers/initialization';
import LanguageSelector from './languageSelector'
import { withTranslation, WithTranslation  } from 'react-i18next';
import { loadTheme } from 'office-ui-fabric-react';
import { isProdEnv } from '../../utils/functions';
export interface SettingsProps extends RouteComponentProps, WithTranslation { 
    account?: AccountInfo, 
    username?: string, 
    persona?: IPersonaProps, 
    showTeachingBubble: boolean, 
    panelContent?: string,
    selectedProjectId?: number,
    internalAppRoles: Role[],
    externalAppRoles: Role[],
    userProjectRole?: RoleModels.Role,
    roles: RoleModels.Role[],
    company?: string,
    darkModeEnambled: boolean,
    setShowTeachingBubble: typeof UiDocumentActions.setShowTeachingBubble, 
    changeDrawerPage: typeof UiCommandActions.changeDrawerPage,
    setPanelStatus: typeof UiDocumentActions.setPanelStatus,
    doLogout: typeof AuthConfigActions.CommandActions.doLogout,
    fetchUsers: typeof AdministrationCommandActions.fetchUsers,
    setDarkMode: typeof ProjectDocumentActions.setDarkMode
}

class Settings extends React.Component<SettingsProps> {
    private _elementRef = React.createRef<HTMLDivElement>();

    constructor(props: SettingsProps) {
        super(props);   
    }

    openPanel = (title: string, content: string) =>{
        const { setPanelStatus } = this.props;
        setPanelStatus({open: true, content: content, title: title});
    }


    render(){
        const { account, persona, username, showTeachingBubble, roles, company, setShowTeachingBubble, doLogout, panelContent, fetchUsers, selectedProjectId, internalAppRoles, externalAppRoles, userProjectRole, darkModeEnambled, setDarkMode, t } = this.props;
        const styles = getClassNames();
        const iconSettings: IIconProps = { iconName: 'Settings' };
        const iconQuestion: IIconProps = { iconName: 'StatusCircleQuestionMark' };
        const iconRinger: IIconProps = { iconName: 'Ringer' };
        const iconSmile: IIconProps = { iconName: 'Emoji2' };
        const ringerOpened = (panelContent === 'ringer') ? true : false;
        const questionOpened = (panelContent === 'changelog') ? true : false;
        const feedbackOpened = (panelContent === 'feedback') ? true : false;
        loadTheme(getTheme(darkModeEnambled));
        return (
            <div>
                {!!account && (
                    <div id="appBarButtons" className={styles.userContainer}>  
                        { isExternalApp && <LanguageSelector/>}
                        { isProdEnv() && !isExternalApp && <IconButton iconProps={iconRinger} onClick={() => this.openPanel('Notifications', 'ringer')} styles={{root: (!ringerOpened) ? styles.root : styles.root2, icon: (!ringerOpened) ? styles.icon : styles.icon2, rootHovered:  (!ringerOpened) ? styles.rootHovered : {}, rootPressed: styles.rootHovered}} title={"Notifications"} ariaLabel="Ringer" />}
                        {(
                            !isExternalApp &&
                            selectedProjectId && 
                            internalAppRoles && internalAppRoles.length > 0 && 
                            externalAppRoles && externalAppRoles.length > 0 && 
                            roles && roles.length > 0 &&
                            userProjectRole && _.find(userProjectRole.actions, a => a === 'Enable'))
                            && <IconButton iconProps={iconSettings} onClick={() => fetchUsers({openModal: true})} styles={{root: styles.root, icon: styles.icon, rootHovered: styles.rootHovered, rootPressed: styles.rootHovered}} title={t("Settings")} ariaLabel="Settings" />
                        }
                        {roles && roles.length > 0 && <IconButton iconProps={iconQuestion} onClick={() => this.openPanel('Help', 'changelog')} styles={{root: (!questionOpened) ? styles.root : styles.root2, icon: (!questionOpened) ? styles.questionMark : styles.questionMark2, rootHovered:  (!questionOpened) ? styles.rootHovered : {}, rootPressed: styles.rootHovered}} title={"Help"} ariaLabel="Help" />}
                        {/* <IconButton iconProps={iconSmile} onClick={() => this.openPanel('Send feedback', 'feedback')} styles={{root: (!feedbackOpened) ? styles.root : styles.root2, icon: (!feedbackOpened) ? styles.icon : styles.icon2, rootHovered:  (!feedbackOpened) ? styles.rootHovered : {}, rootPressed: styles.rootHovered}} title={"Send feedback"} ariaLabel="Feedback" /> */}
                        <TooltipHost content={username || "..."}>
                            <div ref={this._elementRef}>
                                <Persona imageUrl={(persona && persona.imageUrl) ? persona.imageUrl : undefined} text={username || ''} onClick={() => {setShowTeachingBubble({showTeachingBubble: true})}} className={styles.user} size={PersonaSize.size40} hidePersonaDetails />
                            </div>
                        </TooltipHost>
                        {showTeachingBubble &&                     
                            <TeachingBubble
                                calloutProps={{ directionalHint: DirectionalHint.bottomCenter }}
                              
                                closeButtonAriaLabel="Close"
                                target={this._elementRef}
                                hasCloseIcon={true}
                                onDismiss={() => {setShowTeachingBubble({showTeachingBubble: false})}}
                                headline={username}
                                >
                            
                                    { userProjectRole && userProjectRole.name && userProjectRole.name.includes('_') && <div>{userProjectRole.name.split('_')[1]}</div>}
                                    { userProjectRole && userProjectRole.name && !userProjectRole.name.includes('_') && <div>{userProjectRole.name}</div>}
                                    { isExternalApp && company && <div>{company}</div> }

                                    <DefaultButton
                                        styles={{root: {marginTop: 20}}}
                                        text={t("Logout")}
                                        onClick={ () => doLogout() }
                                    />
                            </TeachingBubble>
                        }
                    </div>
                )}
            </div> 
        );
    }
}

const mapStateToProps = createStructuredSelector({
    account: AuthConfigReducer.getAccount,
    username: AuthConfigReducer.getUsername,
    persona: AuthConfigReducer.getPersona,
    showTeachingBubble: getShowTeachingBubble,
    panelContent: getPanelContent,
    selectedProjectId: getSelectedProjectId,
    internalAppRoles: getInternalAppRoles,
    externalAppRoles: getExternalAppRoles,
    userProjectRole: getSelectedProjectUserRole,
    roles: getRoles,
    company: getCompany,
    darkModeEnambled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({  
        setShowTeachingBubble: UiDocumentActions.setShowTeachingBubble, 
        changeDrawerPage: UiCommandActions.changeDrawerPage, 
        setPanelStatus: UiDocumentActions.setPanelStatus,
        doLogout: AuthConfigActions.CommandActions.doLogout,
        fetchUsers: AdministrationCommandActions.fetchUsers,
        setDarkMode: ProjectDocumentActions.setDarkMode
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Settings)));