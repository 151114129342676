import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../../administration/content/internal.classNames';
import { Checkbox, ComboBox, IComboBoxOption, Label, TextField } from 'office-ui-fabric-react';
import _ from 'lodash';
import { getGenericModal } from '../../../../redux/selectors/ui';
import { StakeHolder } from '../../../../models/stakeHolder';
import { ModalDocumentAction } from '../../../../redux/actions/modal';
import { allCountries } from 'country-region-data';

export interface NewStakeHolderState {
    isPartner: boolean,
    isSupplier: boolean,
    isCustomer: boolean,
    isActive: boolean
}

export interface NewStakeHolderProps extends RouteComponentProps {
    stakeHolderForm: StakeHolder,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty
}

class NewStakeHolder extends React.Component<NewStakeHolderProps, NewStakeHolderState> {
    constructor(props: NewStakeHolderProps) {
        super(props);   
        this.state = {
            isCustomer: false,
            isSupplier: false,
            isPartner: false,
            isActive: false
        }
    }

    componentDidMount = () => {
        const { stakeHolderForm } = this.props;
        this.setState({
            isPartner: stakeHolderForm.isPartner,
            isSupplier: stakeHolderForm.isSupplier,
            isCustomer: stakeHolderForm.isCustomer,
            isActive: stakeHolderForm.isActive
        });
    }

    render(){
        const { stakeHolderForm, setContextItemProperty } = this.props;
        const {isPartner, isSupplier, isCustomer, isActive} = this.state;
        const styles = getClassNames();
        const country_options: IComboBoxOption[] =  _.map(allCountries, (c) => {return {key: c[0], text: c[0]}});
        return (
            <>
                {stakeHolderForm &&    
                    <>
                        <TextField 
                            required
                            label="Company" 
                            autoComplete={'off'} 
                            value={stakeHolderForm.companyName} 
                            onChange={(e, value) => { setContextItemProperty({id: 'newStakeHolder', name: 'companyName', value})}}
                            disabled={!!stakeHolderForm.stakeHolderId}
                            errorMessage={(stakeHolderForm.errors && stakeHolderForm.errors.companyName) ? stakeHolderForm.errors.companyName : undefined}
                        />   
                        <TextField 
                            label="Address" 
                            autoComplete={'off'} 
                            value={stakeHolderForm.address} 
                            disabled={!!stakeHolderForm.stakeHolderId}
                            onChange={(e, value) => { setContextItemProperty({id: 'newStakeHolder', name: 'address', value})}}
                        />   
                        <ComboBox
                            label="Country" 
                            placeholder="Select a country..."
                            selectedKey={stakeHolderForm.country}
                            options={country_options}
                            onChange={(e, value) => setContextItemProperty({id: 'newStakeHolder', name: 'country', value: (value as IComboBoxOption).key as string})}                           
                            useComboBoxAsMenuWidth={true}         
                            calloutProps={{calloutMaxHeight: 320}}
                        />  
                        <TextField 
                            required
                            label="Initials" 
                            autoComplete={'off'} 
                            value={stakeHolderForm.initials} 
                            onChange={(e, value) => { setContextItemProperty({id: 'newStakeHolder', name: 'initials', value})}}
                            errorMessage={(stakeHolderForm.errors && stakeHolderForm.errors.initials) ? stakeHolderForm.errors.initials : undefined}
                        />      
                        {/* <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                            <Checkbox 
                                checked={(stakeHolderForm.isPartner) ? true : false} 
                                onChange={(e, value) => { 
                                    if(!isPartner){
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isPartner', value})
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isCustomer', value: (value) ? false : stakeHolderForm.isCustomer})
                                    }
                                }} 
                                disabled={isPartner || isCustomer}
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is Partner</Label>
                        </div> */}
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                            <Checkbox 
                                checked={(stakeHolderForm.isSupplier) ? true : false} 
                                onChange={(e, value) => { 
                                    if(!isSupplier){
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isSupplier', value})
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isCustomer', value: (value) ? false : stakeHolderForm.isCustomer})
                                    }
                                }} 
                                disabled={isSupplier || isCustomer}
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is Supplier</Label>
                        </div>  
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
                            <Checkbox 
                                checked={(stakeHolderForm.isCustomer) ? true : false} 
                                onChange={(e, value) => { 
                                    if(!isCustomer){
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isCustomer', value})
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isSupplier', value: (value) ? false : stakeHolderForm.isSupplier})
                                        setContextItemProperty({id: 'newStakeHolder', name: 'isPartner', value: (value) ? false : stakeHolderForm.isPartner})
                                    }
                                }} 
                                disabled={isCustomer || (isPartner || isSupplier)}
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Is Customer</Label>
                        </div>  
                        <div style={{display: 'flex', alignItems: 'center', marginTop: 40}}>
                            <Checkbox 
                                checked={(stakeHolderForm.isActive) ? true : false} 
                                onChange={(e, value) => { 
                                    setContextItemProperty({id: 'newStakeHolder', name: 'isActive', value})
                                }} 
                            />  
                            <Label styles={{root: {marginLeft: 5}}}>Enabled</Label>
                        </div>            
                    </>             
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    stakeHolderForm: (state) => (getGenericModal('newStakeHolder')(state)) ? (getGenericModal('newStakeHolder')(state) as any).contextItem : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withRouter(NewStakeHolder));