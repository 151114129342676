import {DocumentActions, SET_VALUE, SET_FILTER_VALUE } from '../actions/filters';
import _ from 'lodash';
import {Filters} from '../../models/filters'
import { FiltersType } from '../../utils/costants';

interface FiltersState  {
    largerPanel: boolean
    showCallout: boolean
    elementRef?: HTMLElement
    selectedFilter?: FiltersType
    filters: Filters
    
}

export const initialFilters: Filters = {
    status: [],
    documentNumber: [],
    tosId: [],
    commented: false,
    isForInternalUse: false,
    showAllVersions: false,
    defaultPurposesList: [],
    defaultStatusList: [],
    isEngineeringOrder: false
}

const initialState: FiltersState = {
    largerPanel: false,
    showCallout: false,
    elementRef: undefined,
    selectedFilter: undefined,
    filters: initialFilters   
}

export const filtersReducer = (filters = initialState, action : DocumentActions): FiltersState => {
    switch (action.type) {
        
        case SET_FILTER_VALUE:
            return {...filters, filters: {...filters.filters, [action.payload.name]: action.payload.value }};

        case SET_VALUE:
            return {...filters, [action.payload.name]: action.payload.value };

        default:
            return filters;
    }
};

//select from state (read)
const selectFiltersState = (state: any) => state.filters as FiltersState;
export const getLargerPanel = (state: any) => selectFiltersState(state).largerPanel;
export const getFilters = (state: any) => selectFiltersState(state).filters;
export const getStatus = (state: any) => selectFiltersState(state).filters.status;
export const getShowCallout = (state: any) => selectFiltersState(state).showCallout;
export const getElementRef = (state: any) => selectFiltersState(state).elementRef;
export const getSelectedFilter = (state: any) => selectFiltersState(state).selectedFilter;
export const getFilterDocumentNumber = (state: any) => selectFiltersState(state).filters.documentNumber;
export const getFilterSheet = (state: any) => selectFiltersState(state).filters.sheet;
export const getFilterDocumentIndex = (state: any) => selectFiltersState(state).filters.documentIndex;
export const getFilterClientNumber = (state: any) => selectFiltersState(state).filters.clientNumber;
export const getFilterTdNumber = (state: any) => selectFiltersState(state).filters.tdNumber;
export const getFilterTransmittalId = (state: any) => selectFiltersState(state).filters.transmittalId;
export const getFilterTransmittalNumber = (state: any) => selectFiltersState(state).filters.transmittalNumber;
export const getFilterPwFilename = (state: any) => selectFiltersState(state).filters.pwFilename;
export const getFilterStatus = (state: any) => selectFiltersState(state).filters.status;
export const getFilterType = (state: any) => selectFiltersState(state).filters.type;
export const getFilterPurpose = (state: any) => selectFiltersState(state).filters.purpose;
export const getFilterEntity = (state: any) => selectFiltersState(state).filters.entity;
export const getFilterEngPhase = (state: any) => selectFiltersState(state).filters.engPhase;
export const getFilterFrom = (state: any) => selectFiltersState(state).filters.from;
export const getFilterTo = (state: any) => selectFiltersState(state).filters.to;
export const getFilterDiscipline = (state: any) => selectFiltersState(state).filters.discipline;
export const getFilterDocType = (state: any) => selectFiltersState(state).filters.docType;
export const getFilterPbs = (state: any) => selectFiltersState(state).filters.pbs;
export const getFilterIsForInternalUse = (state: any) => selectFiltersState(state).filters.isForInternalUse;
export const getFilterShowAllVersions = (state: any) => selectFiltersState(state).filters.showAllVersions;
export const getFilterCommented = (state: any) => selectFiltersState(state).filters.commented;
export const getFilterFolder = (state: any) => selectFiltersState(state).filters.folder;
export const getFilterPartner = (state: any) => selectFiltersState(state).filters.partner;
export const getFilterCustomer = (state: any) => selectFiltersState(state).filters.customer;
export const getFilterSupplier = (state: any) => selectFiltersState(state).filters.supplier;
export const getFilterSapPart = (state: any) => selectFiltersState(state).filters.sapPart;
export const getFilterSapType = (state: any) => selectFiltersState(state).filters.sapType;
export const getFilterLanguage = (state: any) => selectFiltersState(state).filters.language;
export const getFilterFileType = (state: any) => selectFiltersState(state).filters.fileType;
export const getProjectDocumentNumber = (state: any) => selectFiltersState(state).filters.projectDocumentNumber;
export const getFilterSupplierDocIndex = (state: any) => selectFiltersState(state).filters.supplierDocIndex;
export const getFilterSupplierDocNumber = (state: any) => selectFiltersState(state).filters.supplierDocNumber;
export const getFilterTosId = (state: any) => selectFiltersState(state).filters.tosId;
export const getFilterGeneric = (state: any) => selectFiltersState(state).filters._generic;
export const getFilterCustomerDocIndex = (state: any) => selectFiltersState(state).filters.customerDocIndex;
export const getFilterCustomerDocNumber = (state: any) => selectFiltersState(state).filters.customerDocNumber;