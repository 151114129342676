import * as signalR from '@microsoft/signalr';
import _ from 'lodash';
import { configuration } from './configuration';

export class SignalrConfigurator {

    static connection: signalR.HubConnection
    static connectionId: string | undefined

    static init(updateIdsFunction: (ids: number[]) => void) {    
        let url = `${configuration.api_endpoint.replace('/api', '')}/Exod2EventHub`;          
        SignalrConfigurator.connection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets,
            })
            .configureLogging(signalR.LogLevel.Error)
            .withAutomaticReconnect()    
            .build();  

        SignalrConfigurator.start().then(() => {
            SignalrConfigurator.connection.invoke('getConnectionId').then((connectionId) => {
                SignalrConfigurator.connectionId = connectionId;
            }).catch(err => console.error(err.toString()));;
            SignalrConfigurator.connection.on('ReceiveRefresh', (email: string, ids: number[], connectionId: string) => {
                if(ids && ids.length > 0 && SignalrConfigurator.connectionId !== connectionId) //applicationUser !== email && 
                    updateIdsFunction(ids);                           
            });       
        }); 

        SignalrConfigurator.connection.onclose(() => {
            SignalrConfigurator.init(updateIdsFunction);
        })   
    }

    static start = async () => {
        try {         
            if(SignalrConfigurator.connection){
                await SignalrConfigurator.connection.start();
                const {REACT_APP_ENV} = process.env;
                if (REACT_APP_ENV === 'development') 
                    console.log("SignalR Connected.");
            }
        } catch (err) {
            console.log(err);
           // setTimeout(SignalrConfigurator.start, 5000);
        }
    };

    static refreshIds = (email: string, ids: number[]) => {
        SignalrConfigurator.connection
            .invoke('RefreshData', email, ids, SignalrConfigurator.connectionId)
            .catch(err => console.error(err));
    }

   
}

export default SignalrConfigurator;

