import { EventType } from '../../models/eventType';
import { NotifyRule } from '../../models/notifyRule';
import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { AppUser } from '../../models/administration';

export const FETCH_EVENT_TYPES = `[NOTIFY_SETTINGS] FETCH EVENT TYPES`;
export const FETCH_USER_RULES = `[NOTIFY_SETTINGS] FETCH USER NOTIFICATIONS`;
export const FETCH_USERS_RULES = `[NOTIFY_SETTINGS] FETCH USERS NOTIFICATIONS`;
export const SAVE_AND_SET_MAIL = `[NOTIFY_SETTINGS] SAVE AND SET MAIL`;
export const SAVE_AND_SET_TEAMS = `[NOTIFY_SETTINGS] SAVE AND SET TEAMS`;
export const SAVE_RULE = `[NOTIFY_SETTINGS] SAVE RULE`;
export const SET_EVENT_TYPES = `[NOTIFY_SETTINGS] SET EVENT TYPES`;
export const SET_UI_RULES = `[NOTIFY_SETTINGS] SET UI RULES`;
export const SET_EVENT_APPLICATION_ID = `[NOTIFY_SETTINGS] SET EVENT APPLICATION ID`;
export const SET_RULE_MAIL = `[NOTIFY_SETTINGS] SET RULE MAIL`;
export const SET_RULE_TEAMS = `[NOTIFY_SETTINGS] SET RULE TEAMS`;
export const SET_RULE_ID = `[NOTIFY_SETTINGS] SET RULE ID`;
export const SET_RULE = `[NOTIFY_SETTINGS] SET RULE`;
export const SAVE_MULTIPLE_RULES = `[NOTIFY_SETTINGS] SAVE MULTIPLE RULES`;
export const DELETE_MULTIPLE_RULES = `[NOTIFY_SETTINGS] DELETE MULTIPLE RULES`;

export const NotifySettingsCommandActions = {
    fetchEventTypes: () => createAction(FETCH_EVENT_TYPES),
    fetchUserRules: () => createAction(FETCH_USER_RULES),
    fetchUsersRules: ({users, isExternal}: {users: AppUser[], isExternal: boolean}) => createAction(FETCH_USERS_RULES, {users, isExternal}),
    saveAndSetMail: ({index, value}: {index: number, value: boolean}) => createAction(SAVE_AND_SET_MAIL, {index, value}),
    saveAndSetTeams: ({index, value}: {index: number, value: boolean}) => createAction(SAVE_AND_SET_TEAMS, {index, value}),
}

export const NotifySettingsEventActions = {
    saveRule: ({rule}: {rule: NotifyRule}) => createAction(SAVE_RULE, {rule}),
    saveMultipleRules: ({users, isExternal}: {users: AppUser[], isExternal: boolean}) => createAction(SAVE_MULTIPLE_RULES, {users, isExternal}),
    deleteMultipleRules: ({users, all, isExternal}: {users: AppUser[], all: boolean, isExternal: boolean}) => createAction(DELETE_MULTIPLE_RULES, {users, all, isExternal})
}

export const NotifySettingsDocumentActions = {
    setEventTypes: ({eventTypes}: {eventTypes: EventType[]}) => createAction(SET_EVENT_TYPES, {eventTypes}),
    setUiRules: ({uiRules}: {uiRules: NotifyRule[]}) => createAction(SET_UI_RULES, {uiRules}),
    setEventApplicationId: ({id}: {id?: number}) => createAction(SET_EVENT_APPLICATION_ID, {id}),
    setRuleMail: ({index, value}: {index: number, value: boolean}) => createAction(SET_RULE_MAIL, {index, value}),
    setRuleTeams: ({index,value}: {index: number, value: boolean}) => createAction(SET_RULE_TEAMS, {index, value}),
    setRuleId: ({index,value}: {index: number, value?: number}) => createAction(SET_RULE_ID, {index, value}),
    setRule: ({rule}: {rule: NotifyRule}) => createAction(SET_RULE, {rule})
}


export const CommandActions = {...NotifySettingsCommandActions};
export const EventActions = {...NotifySettingsEventActions};
export const DocumentActions = {...NotifySettingsDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>
