import {Actions, CommandActions, EventActions, DocumentActions, FETCH_MAIN_DATA, FETCH_PUBLICATION_CHART, FETCH_CLIENT_CHART, FETCH_PURPOSES_CHART} from "../../actions/main";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions, DocumentActions as LoaderDocumentActions} from "../../actions/loader";
import _ from 'lodash';
import { MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { SortColumn } from "../../../models/sort";
import moment from "moment";
import { ChartData, ClientStatusChart, FinalPurposesChart, PublicationStatusChart } from "../../../models/main";
import { getSelectedProject } from "../../selectors/project";
import { getSelectedProjectId } from "../../reducers/project";
import { getClientChartUrl, getFinalPurposeChartUrl, getPublicationChartUrl } from "../../../utils/mainUrls";
import { getLoader } from "../../selectors/loader";
import { t } from "i18next";


export const mainMiddleware = ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions ) => {
    next(action);
    let orderColumn: SortColumn | undefined;  let plantInfo: ChartData[] = [];

    switch (action.type) {

        case FETCH_MAIN_DATA:
            //dispatch(CommandActions.fetchPublicationChart());
            dispatch(CommandActions.fetchClientChart());
            dispatch(CommandActions.fetchPurposesChart());
            break;

        case FETCH_PUBLICATION_CHART:
            next(LoaderCommandActions.setLoadingPublications({ feature: 'home', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getPublicationChartUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PUBLICATION_CHART}}));    
            break;

        case FETCH_CLIENT_CHART:
            next(LoaderCommandActions.setLoadingClient({ feature: 'home', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getClientChartUrl(getSelectedProjectId(getState()) as number), feature: FETCH_CLIENT_CHART}}));    
            break;

        case FETCH_PURPOSES_CHART:
            next(LoaderCommandActions.setLoadingPurpose({ feature: 'home', loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getFinalPurposeChartUrl(getSelectedProjectId(getState()) as number), feature: FETCH_PURPOSES_CHART}}));    
            break;

         
        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        case FETCH_PUBLICATION_CHART:{
            let chartValues = (action.payload) ? action.payload : undefined;
            let chartValuesMapped = new PublicationStatusChart(chartValues);
            next(CommandActions.setPublicationData({value: chartValuesMapped}));
            next(LoaderCommandActions.setLoadingPublications({ feature: 'home', loading: false}));
            if(getLoader('home')(getState()).loadingClient && getLoader('home')(getState()).loadingPurpose)
                setTimeout(() => { next(LoaderCommandActions.setLoading({ feature: 'home', loading: false})); }, 5000);
                
            break;
        }

        case FETCH_CLIENT_CHART: {
            let chartValues = (action.payload) ? action.payload : undefined;
            let chartValuesMapped = new ClientStatusChart(chartValues);
            next(CommandActions.setClientStatusData({value: chartValuesMapped}));
            next(LoaderCommandActions.setLoadingClient({ feature: 'home', loading: false}));
            if(!getLoader('home')(getState()).loadingPurpose)
                next(LoaderCommandActions.setLoading({ feature: 'home', loading: false}));
            break;
        }

         case FETCH_PURPOSES_CHART: {
            let chartValues = (action.payload) ? action.payload : undefined;
            let chartValuesMapped = new FinalPurposesChart(chartValues);
            next(CommandActions.setFinalPurposesData({value: chartValuesMapped}));
            next(LoaderCommandActions.setLoadingPurpose({ feature: 'home', loading: false}));
            if(!getLoader('home')(getState()).loadingClient)
                next(LoaderCommandActions.setLoading({ feature: 'home', loading: false}));
            break;
        }

        default:
            break;
    }
}

const apiErrorMiddleware = (dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case FETCH_PUBLICATION_CHART:
            next(LoaderCommandActions.setLoadingPublications({ feature: 'home', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'home', show: true, type: MessageBarType.error, message: t('Cannot get publication data') + ' ' + t('Please refresh the page')}));
            break;

        case FETCH_CLIENT_CHART:
            next(LoaderCommandActions.setLoadingClient({ feature: 'home', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'home', show: true, type: MessageBarType.error, message: t('Cannot get client data') + ' ' + t('Please refresh the page')}));
            break;

        case FETCH_PURPOSES_CHART:
            next(LoaderCommandActions.setLoadingPurpose({ feature: 'home', loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'home', show: true, type: MessageBarType.error, message: t('Cannot get final purposes data') + ' ' + t('Please refresh the page')}));
            break;

        default:
            break;
    }
}


const resetVerticalScroll = (): void => {
    var scrollbarDiv = document.getElementById('plantInfo_scrollbar');
    if(scrollbarDiv && scrollbarDiv.children.length > 0)
        scrollbarDiv.children[0].scrollTop = 0; 
}
