import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions} from '../../../redux/actions/ui';
import { CommandActions as DocumentCommandActions} from '../../../redux/actions/document';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import { CommandActions as ModalCommandActions} from '../../../redux/actions/modal';
import _ from 'lodash'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { Document, getChangeStatusEmptyFileForm, getEmptyCommentFileForm, getEmptyFileForm } from '../../../models/document';
import { getTotal } from '../../../redux/reducers/documents';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getContextualDocuments, getSelectedDocument } from '../../../redux/selectors/documents';
import { getSelectedProject, getSelectedProjectCodeword } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { formatDateFile, getHistoryName } from '../../../utils/functions';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface ContactsProps extends RouteComponentProps, WithTranslation  {
    filterPanelOpened: boolean, 
    documents: Document[],
    total: number,
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    statusValues: Status[],
    selectedDocument?: Document,
    selectedProject?: Project,
    codeWord?: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[]
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openGenericModal: typeof ModalCommandActions.openModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
}

class DocumentsCommandBar extends React.Component<ContactsProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { documents, total, openGenericModal, openEditModal, fetchDocuments, loadingContent, loading, loadingScroll, statusValues, userProjectActions, selectedDocument, selectedProject, history, t, codeWord } = this.props;
        let buttons: ICommandBarItemProps[] = [];
        let historyName = getHistoryName(history);
        const selectedDocumentIds = _.map(documents, d => d.id as number);
        let supplierApprovedStatus = _.find(statusValues, a => a.name === 'supplier-approved') as Status;
        let supplierDeclineStatus = _.find(statusValues, a => a.name === 'supplier-rejected') as Status;
        let rejectedStatus = _.find(statusValues, a => a.name === 'rejected') as Status;
        const commentsExist = (documents && documents.length > 0) ? _.some(documents, d => d.comments && d.comments.length > 0) : true;
        buttons = [
            ...((_.find(userProjectActions, a => a === 'PublishSupplierMaterial')) ? [{
                key: 'supplierPublish',
                name: t('Publish to D3'),
                disabled: (selectedDocumentIds.length === 0 || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'changeStatus', contextItem: {newStatus: {...supplierApprovedStatus}, fileForm: getChangeStatusEmptyFileForm()}})},
                iconProps: {
                    iconName: (supplierApprovedStatus) ? supplierApprovedStatus.icon : undefined,
                    style: {color: (supplierApprovedStatus) ? supplierApprovedStatus.color : undefined} 
                }
            }] : []),   
            ...((_.find(userProjectActions, a => a === 'DeclineSupplierMaterial')) ? [{
                key: 'supplierDecline',
                name: t('Reject'),
                disabled: (selectedDocumentIds.length === 0 || loadingContent || loading || loadingScroll),
                onClick: () => {openGenericModal({id: 'changeStatus', contextItem: {newStatus: {...supplierDeclineStatus}, fileForm: getChangeStatusEmptyFileForm(), files: []}})},
                iconProps: {
                    iconName: (supplierDeclineStatus) ? supplierDeclineStatus.icon : undefined,
                    style: {color: (supplierDeclineStatus) ? supplierDeclineStatus.color : undefined} 
                }
            }] : []),     
            ...(_.find(userProjectActions, a => a === 'ReadSupplierMaterial') ? [{
                key: 'downlaod',
                name: t('Download'),
                disabled: (loadingContent || loading || loadingScroll),
                iconProps: {
                    iconName: t('Download'),
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'downlaodFiles',
                            name: t('Files'),
                            disabled: (total === 0 || !_.some(documents, d => d.files.length > 0) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'fileName', contextItem: {fileName: codeWord + '_Documents_' + formatDateFile(new Date()), isParent: false}}) },
                            iconProps:  {
                                iconName: 'DownloadDocument'
                            } 
                        },
                        {
                            key: 'downloadComments',
                            name: t('Comments'),
                            disabled: (total === 0 || !commentsExist || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'comments', optional: codeWord + '_Comments_' + formatDateFile(new Date())}) },
                            iconProps: {
                                iconName: 'Comment',
                            },
                        }
                    ]
                }          
            }] : []),                 
            ...(!isExternalApp ? [{
                key: 'excelReport',
                name: t('Report'),
                disabled: (total === 0 || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'excelReport', optional: codeWord + '_Document report_' + formatDateFile(new Date())}) },
                iconProps: {
                    iconName: 'ExcelDocument',
                    style: {color: "#008000"}
                  },
            }] : []), 
            {
                key: 'refresh',
                name: t('Refresh'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchDocuments({}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }   
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {setFilterPanelOpened, filterPanelOpened, t} = this.props;
      return [
     
        {
          key: 'openPanel',
          name: t('Filters'),
          iconProps: {
            iconName: 'Filter'
          },
          ariaLabel: 'Filters',    
          onClick: () => {setFilterPanelOpened({open: !filterPanelOpened})}      
        },          
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    documents: getContextualDocuments,
    total: getTotal,
    statusValues: getStatusValues,
    selectedDocument: getSelectedDocument,
    selectedProject: getSelectedProject,
    codeWord: getSelectedProjectCodeword
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened,
        setOpenModal: DocumentCommandActions.setOpenModal,
        openGenericModal: ModalCommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsCommandBar)));
