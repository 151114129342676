export class Report {
    id?: number
    name?: string
    description?: string

    constructor(object?: any){
        if(object){
            this.id = object.reportId;
            this.name = object.reportName;
            this.description = object.reportDescription;
        }
    }
}