import React, { CSSProperties } from 'react'

interface Props {
    text?: string | undefined
    selected?: boolean | undefined
    darkModeEnabled?: boolean
    onItemSelected() : void
}

const getStyle = (selected?: boolean, darkModeEnabled?: boolean): CSSProperties => {
    if(!selected && !darkModeEnabled){
        return (
            {
                textAlign: "right",
                cursor: "pointer",
                minWidth: 100,
                fontSize: 17,
                fontWeight: 400,
                color: "#666666",
                
                padding: "15px 25px"
            }
        );
    }
    else if(!selected && darkModeEnabled){
        return (
            {
                textAlign: "right",
                cursor: "pointer",
                minWidth: 100,
                fontSize: 17,
                fontWeight: 400,
                color: "#ffffff",
                
                padding: "15px 25px"
            }
        );
    }
    else if(selected && !darkModeEnabled){
        return (
            {
                textAlign: "right",
                borderRightStyle: "solid",
                borderRightWidth: 4,
                borderRightColor: "#0078d4",
                minWidth: 100,
                fontSize: 17,
                fontWeight: "bold",
                color: "#333333",
                
                padding: "15px 25px"
            }
        );
    }
    else{
        return (
            {
                textAlign: "right",
                borderRightStyle: "solid",
                borderRightWidth: 4,
                borderRightColor: "#0078d4",
                minWidth: 100,
                fontSize: 17,
                fontWeight: "bold",
                color: "#ffffff",
                
                padding: "15px 25px"
            }
        );
    }

} 

export const MenuItem: React.SFC<Props> = (props) => {
    return (
        <div style={getStyle(props.selected, props.darkModeEnabled)} onClick={props.onItemSelected}>
            {props.text}
        </div>
    )
}