class Configuration {
    api_endpoint: string = process.env.REACT_APP_API_ENDPOINT as string
    api_fake_endpoint: string = process.env.REACT_APP_API_FAKE_ENDPOINT as string
    notify: string = process.env.REACT_APP_NOTIFY as string
    user_permissions_endpoint: string = process.env.REACT_APP_USER_PERMISSIONS_API_ENDPOINT as string
    redirect_uri: string = process.env.REACT_APP_REDIRECT_URI as string
    public_url: string = process.env.PUBLIC_URL as string
}

export const configuration: Configuration = new Configuration;
