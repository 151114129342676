import {DocumentActions, SET_VALUE} from '../actions/document';
import _ from 'lodash';
import { Document, OtherRevision } from '../../models/document';

interface DocumentState  {
    openModal: boolean,
    document?: Document,
    otherRevisions: OtherRevision[]
}

const initialState: DocumentState = {
    openModal: false,
    document: undefined,
    otherRevisions: []
} 

export const documentReducer = (document = initialState, action : DocumentActions): DocumentState => {
    switch (action.type) {
        
        case SET_VALUE:
            return {...document, [action.payload.name]: action.payload.value };



        default:
            return document;
    }
};

//select from state (read) 
const selectDocumentState = (state: any) => state.document as DocumentState;
export const getOpenModal = (state: any) => selectDocumentState(state).openModal;
export const getDocument = (state: any) => selectDocumentState(state).document;
export const getOtherRevisions = (state: any) => selectDocumentState(state).otherRevisions;