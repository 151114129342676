import * as React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import _ from 'lodash';
import {CommandActions as FiltersCommandActions} from '../../../redux/actions/filters';
import { getElementRef, getFilters, getSelectedFilter, getShowCallout } from '../../../redux/reducers/filters';
import { Callout, DirectionalHint, getId } from 'office-ui-fabric-react';
import FiltersContent from '../filters/index';
import { FiltersType, getTheme } from '../../../utils/costants';
import { getDarkModeEnabled } from '../../../redux/reducers/project';

export interface IndexProps {
    showCallout: boolean,
    elementRef?: HTMLElement,
    selectedFilter?: FiltersType,
    darkModeEnabled: boolean,
    showOrHideCallout: typeof FiltersCommandActions.showOrHideCallout
}

class CalloutFilter extends React.Component<IndexProps> {
    
    constructor(props: IndexProps) {
        super(props);
    }

    private _labelId: string = getId('callout-label');

    render() {
        const { showCallout, elementRef, selectedFilter, showOrHideCallout } = this.props;
        return (
            <>
                {showCallout && 
                    <Callout
                            directionalHint={DirectionalHint.bottomRightEdge}
                            ariaLabelledBy={this._labelId}
                            gapSpace={0}
                            target={(elementRef) ? elementRef : undefined}
                            onDismiss={() => showOrHideCallout({show: false, filter: undefined, ref: undefined})}
                            setInitialFocus={true}
                        >
                            <FiltersContent isCallout={true} selectedFilter={selectedFilter}/>
                    </Callout>    
                }
            </>
        );
    }
}


const mapStateToProps = createStructuredSelector({
    showCallout: getShowCallout,
    elementRef: getElementRef,
    selectedFilter: getSelectedFilter,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        showOrHideCallout:  FiltersCommandActions.showOrHideCallout
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(CalloutFilter);
