import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Scrollbars from 'react-custom-scrollbars';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { getEventTypes, getUiRules } from '../../redux/reducers/notifySettings';
import { NotifyRule } from '../../models/notifyRule';
import { EventType } from '../../models/eventType';
import {CommandActions} from '../../redux/actions/notifySettings';
import _ from 'lodash';
import { Spinner, SpinnerSize, Toggle } from 'office-ui-fabric-react';
import { ExternalRoleActionsType, InternalRoleActionsType, isExternalApp } from '../../utils/costants';
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { withTranslation, WithTranslation  } from 'react-i18next';
export interface MainProps extends RouteComponentProps, WithTranslation { 
    uiRules: NotifyRule[], 
    eventTypes: EventType[], 
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    saveAndSetMail: typeof CommandActions.saveAndSetMail, 
    saveAndSetTeams: typeof CommandActions.saveAndSetTeams
}
class Ringer extends React.Component<MainProps> {
    constructor(props: MainProps) {
        super(props);   
    }

    saveAndSetValue = (ur: NotifyRule, value: boolean, type: 'mail' | 'teams') => {
        const { saveAndSetMail, saveAndSetTeams, userProjectActions } = this.props;
        const isAdmin = _.find(userProjectActions, a => userProjectActions && userProjectActions.length > 0 && a === 'Enable') ? true : false;
        if(isAdmin && type === 'mail')
            saveAndSetMail({index: ur.index as number, value: value as boolean});
        else if(isAdmin && type === 'teams')
            saveAndSetTeams({index: ur.index as number, value: value as boolean});
        else if(!isAdmin && type === 'mail')
            saveAndSetMail({index: ur.index as number, value: ((!value && ur.msChat) ? false : true) as boolean});
        else if(!isAdmin && type === 'teams')
            saveAndSetTeams({index: ur.index as number, value: ((!value && ur.mail) ? false : true) as boolean});
    }

    render(){
        const { eventTypes, uiRules, userProjectActions, t } = this.props;
        const isAdmin = _.find(userProjectActions, a => userProjectActions && userProjectActions.length > 0 && a === 'Enable') ? true : false;
        const uiRulesFiltered = (isAdmin) ? uiRules : _.filter(uiRules, r => r.eventTypeRuleId) as NotifyRule[];
        let htmlRules = _.map(_.orderBy(uiRulesFiltered, u => u.index), (ur, index) => {
            var e = _.find(eventTypes, e => e.eventTypeId === ur.eventTypeId);
            return(
                <div key={index} style={{marginTop: 20}}>
                    <div style={{marginLeft: 20}}>
                        <Text variant={"mediumPlus"}>{(e) ? e.description : ''}</Text>
                    </div>
                    {!isExternalApp &&
                        <div style={{display: 'flex', justifyContent: (ur.eventTypeId !== 9) ? 'space-around' : 'flex-start', marginTop: 10, marginLeft: (ur.eventTypeId !== 9) ? undefined : 32}}>
                            <Toggle checked={ur.mail} onText="Mail" offText="Mail" onChange={(e, value) => this.saveAndSetValue(ur, value as boolean, 'mail')} />
                            <Toggle checked={ur.msChat} onText="Teams Chat" offText="Teams Chat" onChange={(e, value) => this.saveAndSetValue(ur, value as boolean, 'teams')}/>                                       
                        </div>
                    }
                    {isExternalApp && isAdmin &&
                        <div style={{display: 'flex', justifyContent: (ur.eventTypeId !== 9) ? 'space-around' : 'flex-start', marginTop: 10, marginLeft: (ur.eventTypeId !== 9) ? undefined : 32}}>
                            <Toggle checked={ur.mail} onText="Mail" offText="Mail" onChange={(e, value) => this.saveAndSetValue(ur, value as boolean, 'teams')} />
                        </div>
                    }                 
                </div>                                     
            )             
        });
        return (
            <Scrollbars style={{ height: '89vh' }}>
                {eventTypes && eventTypes.length > 0 && uiRulesFiltered && uiRulesFiltered.length > 0 && 
                    <>
                        <div style={{marginLeft: 20, marginTop: 20}}><Text variant={"medium"} >{t('I would like to receive notifications about')}:</Text></div>    
                        {htmlRules}       
                    </>        
                }
                {(!eventTypes || eventTypes.length === 0 || !uiRulesFiltered || uiRulesFiltered.length === 0) && 
                    <Spinner size={SpinnerSize.large} style={{marginTop: 50}}></Spinner>      
                }
            </Scrollbars>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    uiRules: getUiRules,
    eventTypes: getEventTypes,
    userProjectActions: getSelectedProjectUserActions,
    

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        saveAndSetMail: CommandActions.saveAndSetMail, 
        saveAndSetTeams: CommandActions.saveAndSetTeams 
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(Ringer)));