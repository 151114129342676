import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    font: string,
    font2: string,
    filtersContainer: string,
    notification: string,
    dropDownItem: string,
    clearButton: string,
    inputField: string,
    tagInput: string,
    remove: string,
    tag: string
}
  
export const getClassNames = (isCallout: boolean, theme: any): IComponentClassNames => {
    return mergeStyleSets({
        font: {
            fontWeight: 600,
            marginRight: 20,
            marginTop: 5,
            marginBottom: 5,
            width: 310
        },
        font2: {
            fontWeight: 600,
            marginTop: 5,
            marginBottom: 5,
        },
        filtersContainer: {
            padding: (isCallout) ? 20 : undefined,
            paddingRight: (!isCallout) ? 15 : undefined, 
            paddingLeft: (!isCallout) ? 10 : undefined,
            paddingBottom: (!isCallout) ? 20 : undefined,
        },
        notification: {
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 10
        },
        dropDownItem: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 5  
        },
        clearButton: {
            height: 26,
            marginLeft: 5,
            alignSelf: "center"
        },
        inputField: {
            padding: 6,
            width: '100%',
            selectors:{
                ':focus': {
                    border: '2px solid ' + theme.palette.themePrimary + '!important',
                },
            }
        },
        tagInput: {
            marginTop: 5,
            display: 'flex',
            gap: 10,
            flexWrap: 'wrap'
        },
        tag:{
            border: '1px solid #ddd',
            background: theme.palette.themePrimary,
            color: 'white',
            display: 'inline-block',
            padding: 5,
        },
        remove: {
            border: 'none',
            cursor: 'pointer',
            background: 'none',
            color: 'white'
        },
    });
};