import { CommandActions, DocumentActions, CHANGE_DRAWER_PAGE, SEND_FEEDBACK } from "../../actions/ui";
import {GraphQlConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions} from "../../actions/loader";
import { CommandActions as SharedDataCommandActions} from "../../actions/sharedData";
import { CommandActions as DocumentsCommandActions, DocumentActions as DocumentsDocumentActions, FEATURE} from "../../actions/documents";
import { MessageBarType } from "office-ui-fabric-react";
import {getCloseFiltersPanel, getHistoryName} from '../../../utils/functions';
import {getFeedbackText} from "../../reducers/ui";

export const uiMiddlewareHistory = (history: any) => {
    return uiMiddleware(history);
}

export const uiMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: CommandActions| GraphQlConfigActions.EventActions | GraphQlConfigActions.GraphQlSuccessAction | GraphQlConfigActions.GraphQlErrorAction) => {
    next(action);

    switch (action.type) {

        case SEND_FEEDBACK:
            let feedbackText = getFeedbackText(getState());
            if(feedbackText){
                next(DocumentActions.setLoader({state: true, feature: SEND_FEEDBACK}));
                // DISPATCH FEEDBACK API
                // REMOVE NEXT TWO LINES
                next(DocumentActions.setLoader({state: false, feature: SEND_FEEDBACK}));
                next(NotificationDocumentActions.setNotificationStatus({name: 'rightPanel', show: true, type: MessageBarType.success, message: 'Feedback sent successfully.'}));   
            }
            else
                next(NotificationDocumentActions.setNotificationStatus({name: 'rightPanel', show: true, type: MessageBarType.error, message: 'Please insert a comment below'})); 
            break;

        case CHANGE_DRAWER_PAGE:
            next(DocumentActions.setDrawerKey({key: action.payload.key}));
            let oldHistoryName = getHistoryName(history);  
            next(NotificationDocumentActions.setNotificationStatus({name: oldHistoryName, show: false, type: MessageBarType.info, message: ''}));
            history.push(action.payload.key);       
            let historyName = getHistoryName(history); 
            const closePanel = getCloseFiltersPanel(historyName);
            next(DocumentActions.setFilterPanelOpened({open: closePanel ? false : true}));
            dispatch(LoaderCommandActions.setLoading({ feature: historyName, loading: true}));
            next(DocumentsDocumentActions.setSelectedItemsIds({ids: [], feature: FEATURE}));
            dispatch(SharedDataCommandActions.fetchGridObjects({setDefaultFilters: true}));
            break;

        case GraphQlConfigActions.GRAPHQL_SUCCESS:
            graphQlSuccessMiddleware(dispatch, history, getState, next, action);         
            break;
    
        case GraphQlConfigActions.GRAPHQL_ERROR:
            graphQlErrorMiddleware(dispatch, history, next, action);         
            break;

        default:
            break;
    }
};

const graphQlSuccessMiddleware = (dispatch: any, history: any, getState: any, next: any, action: GraphQlConfigActions.GraphQlSuccessAction) => {
    let notificationName = '';
    switch(action.meta.feature){

        case SEND_FEEDBACK:
            next(DocumentActions.setLoader({state: false, feature: SEND_FEEDBACK}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'rightPanel', show: true, type: MessageBarType.success, message: 'Feedback sent successfully.'}));   
            break;

        default:
            break;
    }
}

const graphQlErrorMiddleware = (dispatch: any, history: any, next: any, action: GraphQlConfigActions.GraphQlErrorAction) => {
    let notificationName = ''
    switch(action.meta.feature){

        case SEND_FEEDBACK:
            next(DocumentActions.setLoader({state: false, feature: SEND_FEEDBACK}));
            next(NotificationDocumentActions.setNotificationStatus({name: 'rightPanel', show: true, type: MessageBarType.error, message: 'Cannot send feedback now. Please retry.'}));   
            break;

        default:
            break;
    }
}


