import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {AuthConfigReducer} from '@pwbapps/reduxcore';
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import {Stack, IStackTokens, Text } from 'office-ui-fabric-react';
import { Card, ICardTokens, ICardSectionStyles, ICardSectionTokens } from '@uifabric/react-cards';

export interface UserProps { token?: string, username?: string, email?: string}

class User extends React.Component<UserProps> {

    constructor(props: UserProps) {
        super(props);   
    }

    checkAdmin = (): any => {
        return (window as any).ability.check(`Authorizations:Write`.toLowerCase());
    }

    getRole = () : string => {

        let str = '';
        const canAuthorize = this.checkAdmin();
        const canRead =  true; //(window as any).ability.check(`BESS:Read`.toLowerCase());
        
        if (canAuthorize && canRead)
            str = ' Administrator ';
        else if (canRead)
            str = ' User ';
        

        return str;
    }

    render(){
        const { token, username, email } = this.props;
        const sectionStackTokens: IStackTokens = { childrenGap: 20, padding: 5 };
        const cardTokens: ICardTokens = { childrenMargin: 12 };
        const role = this.getRole();
        return (
            <div>
                {!!token && (
                    <Stack tokens={sectionStackTokens}>
                        <Card aria-label="Basic horizontal card" horizontal tokens={cardTokens}>
                            <Card.Item>
                                <Persona text={username || ''} size={PersonaSize.size72} hidePersonaDetails />
                            </Card.Item>
                            <Card.Section>
                                <Text variant="mediumPlus" styles={{root: {fontWeight: "bold", marginTop: 5}}}>
                                    {username}
                                </Text>
                                <Text styles={{root: {marginTop: '5px !important'}}}>{email}</Text>
                            </Card.Section>
                        </Card>
                        <Text variant='xLarge' styles={{root: {marginLeft: 10}}}>Authorizations</Text>
                        <Card aria-label="Basic horizontal card" horizontal tokens={cardTokens}>
                            <Card.Item>
                                <Text>{role}</Text>
                            </Card.Item>
                        </Card>                
                    </Stack>
                )}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    token: AuthConfigReducer.getToken,
    username: AuthConfigReducer.getUsername,
    email: AuthConfigReducer.getEmail
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(User);