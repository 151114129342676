import { ModalIds } from '../../models/modal';
import {createAction, ActionsUnion} from '@pwbapps/reduxcore';

export const SET_LOADER = 'SET LOADER';
export const SET_ERROR = 'SET ERROR';
export const SET_DRAWER_KEY = 'SET DRAWER KEY';
export const SET_PANEL_STATUS = 'SET PANEL STATUS';
export const SET_LOADING_SALES_ROLES = 'SET LOADING SALES ROLES';
export const SET_LOADING_PE_ROLES = 'SET LOADING PE ROLES';
export const SET_SIP_FIELDS = 'SET SIP FIELDS';
export const SET_PROJECT_FIELDS = 'SET PROJECT FIELDS';
export const SET_READ_ONLY = 'SET READ ONLY';
export const SET_EVALUATE = 'SET EVALUATE';
export const RESET_AND_CLOSE_BROWSE_MODAL = 'RESET AND CLOSE BROWSE MODAL';
export const CHANGE_DRAWER_PAGE = 'CHANGE DRAWER PAGE';
export const SET_ORDERED_COLUMN = `SET ORDERED COLUMN`;
export const SET_SHOW_TEACHING_BUBBLE = `SET SHOW TEACHING BUBBLE`;
export const SET_SELECTED_VALUES = `SET SELECTED VALUES`;
export const SET_VIEW_CONFIDENTIAL = `SET VIEW CONFIDENTIAL`;
export const SET_TOOLTIP = `SET TOOLTIP`;
export const SET_LOADING_ROLES = `SET LOADING ROLES`;
export const SET_FILTER_PANEL_OPENED = `SET FILTER PANEL OPENED`;
export const SET_DRAWER_OPENED = `SET DRAWER OPENED`;
export const SET_DRAWER_ON_TOP = `SET DRAWER ON TOP`;
export const SET_FEEDBACK_TYPE = `SET FEEDBACK TYPE`;
export const SET_FEEDBACK_TEXT = `SET FEEDBACK TEXT`;
export const SEND_FEEDBACK= `SEND FEEDBACK`;
export const SET_INTERNAL_APP= `SET INTERNAL APP`;

export const UiCommandActions = {
    changeDrawerPage: ({key}: {key?: string}) => createAction(CHANGE_DRAWER_PAGE, {key: key}),
    sendFeedback: () => createAction(SEND_FEEDBACK)
}

export const UiDocumentActions = {
    setFeedbackText: ({text}: {text?: string}) => createAction(SET_FEEDBACK_TEXT, {text}),
    setFeedbackType: ({type}: {type: string}) => createAction(SET_FEEDBACK_TYPE, {type}),
    setDrawerOnTop: ({onTop}: {onTop: boolean}) => createAction(SET_DRAWER_ON_TOP, {onTop}),
    setDrawerOpened: ({open}: {open: boolean}) => createAction(SET_DRAWER_OPENED, {open}),
    setFilterPanelOpened: ({open}: {open: boolean}) => createAction(SET_FILTER_PANEL_OPENED, {open}),
    setTooltip: ({name, show, ref, content, row}: {name: string, show: boolean, ref?: HTMLElement, content?: string, row?: number}) => createAction(SET_TOOLTIP, {name, show, ref, content, row}),
    setSelectedValues: ({values}: {values: any[]}) => createAction(SET_SELECTED_VALUES, {values}),
    setLoader: ({state, feature}: {state: boolean, feature: string}) => createAction(SET_LOADER, {state: state}, {feature: feature}),
    setShowTeachingBubble: ({showTeachingBubble}: {showTeachingBubble: boolean}) => createAction(SET_SHOW_TEACHING_BUBBLE, {showTeachingBubble: showTeachingBubble}),
    setOrderedColumn: ({name, type, modal}: {name: string, type: string, modal: string}) => createAction(SET_ORDERED_COLUMN, {name: name, type: type, modal: modal}),
    setError: ({error, feature}: {error: any, feature: string}) => createAction(SET_ERROR, {error: error}, {feature: feature}),
    setDrawerKey: ({key}: {key?: string}) => createAction(SET_DRAWER_KEY, {key: key}),
    setPanelStatus: ({open, title, content}: {open: boolean, title?: string, content?: string}) => createAction(SET_PANEL_STATUS, {open, title, content}),
    setLoadingRoles: ({loading}: {loading: boolean}) => createAction(SET_LOADING_ROLES, {loading}),
    setInternalApp: ({isInternal}: {isInternal: boolean}) => createAction(SET_INTERNAL_APP, {isInternal}),
}

export const CommandActions = {...UiCommandActions};
export const DocumentActions = {...UiDocumentActions};
export const Actions = {...CommandActions, ...DocumentActions};


export type CommandActions = ActionsUnion<typeof Actions>
export type DocumentActions = ActionsUnion<typeof Actions>
export type Actions = ActionsUnion<typeof Actions>
