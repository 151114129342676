import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { Project, ProjectForm } from '../../models/project';
import { Purpose } from '../../models/purpose';
import { Report } from '../../models/report';
import { StakeHolder } from '../../models/stakeHolder';

export const LOAD_OBJECTS_AND_SET_PROJECT = `[PROJECT] LOAD OBJECTS AND SET PROJECT`;
export const FETCH_PROJECTS = `[PROJECT] FETCH PROJECTS`;
export const SET_SELECTED_PROJECT_ID = `[PROJECT] SET SELECTED PROJECT ID`;
export const SET_PROJECTS = `[PROJECT] SET PROJECTS`;
export const SET_IS_EDIT = `[PROJECT] SET IS EDIT`;
export const SET_ALL_PROJECTS = `[PROJECT] SET ALL PROJECTS`;
export const SET_MODAL_OPENED = `[PROJECT] SET MODAL OPENED`;
export const SET_PROJECT_FORM = `[PROJECT] SET PROJECT FORM`;
export const SET_PROJECT_FORM_VALUE = `[PROJECT] SET PROJECT FORM VALUE`;
export const OPEN_OR_CLOSE_PROJECT_MODAL = `[PROJECT] OPEN OR CLOSE PROJECT MODAL`;
export const SET_IS_WITH_APPROVAL = `[PROJECT] SET IS WITH APPROVAL`;
export const SAVE_PROJECT = `[PROJECT] SAVE PROJECT`;
export const GET_PROJECT_FORM_DATA = `[PROJECT] GET PROJECT FORM DATA`;
export const ADD_STAKEHOLDER = `[PROJECT] ADD STAKEHOLDER`;
export const GET_PROJECT_STAKEHOLDERS= `[PROJECT] GET PROJECT STAKEHOLDERS`;
export const SET_PROJECT_STAKEHOLDERS= `[PROJECT] SET PROJECT STAKEHOLDERS`;
export const SET_STAKEHOLDER_VALUE= `[PROJECT] SET STAKEHOLDER VALUE`;
export const SET_STAKEHOLDERS= `[PROJECT] SET STAKEHOLDERS`;
export const SET_REPORTS = `[PROJECT] SET REPORTS`;
export const SET_DARK_MODE = `[PROJECT] SET DARK MODE`;

export const ProjectsCommandActions = {
    fetchProjects: ({isConfigurationPage}: {isConfigurationPage?: boolean}) => createAction(FETCH_PROJECTS, {isConfigurationPage}),
    loadObjectsAndSetProject: ({projectId}: {projectId: number}) => createAction(LOAD_OBJECTS_AND_SET_PROJECT, {projectId}),
    openOrCloseProjectModal: ({open, projectId}: {open: boolean, projectId?: number}) => createAction(OPEN_OR_CLOSE_PROJECT_MODAL, {open, projectId}),
    setProjectFormId: ({value}: {value?: number}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'projectId', value}),
    setProjectFormName: ({value}: {value?: string}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'projectName', value}),
    setProjectFormProjectCodeword: ({value}: {value?: string}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'projectCodeword', value}),
    setProjectFormIsActivateInAgile: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isActive', value}),
    setProjectFormIsEnabled: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isEnabled', value}),
    setProjectFormIsFolderPageActive: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isFolderPageActive', value}),
    setProjectFormIsClientNumGeneratorPageActive: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isClientNumGeneratorPageActive', value}),
    setProjectFormIsInternalCheckActive: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isInternalCheckActive', value}),
    setProjectFormIsExternalCheckActive: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isExternalCheckActive', value}),
    setProjectFormIsApproveWithCommentsStatusActive: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isApproveWithCommentsStatusActive', value}),
    setProjectFormIsAgileTdCoverPageInternalUse: ({value}: {value: boolean}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'isAgileTdCoverPageInternalUse', value}),
    setProjectFormAutomaticApprovalDays: ({value}: {value?: number}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'automaticApprovalDays', value}),
    setProjectFormMandatoryDrawingColumns: ({value}: {value: string[]}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'mandatoryDrawingColumns', value}),
    setProjectFormMandatoryDocumentColumns: ({value}: {value: string[]}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'mandatoryDocumentColumns', value}),
    setProjectFormTemplate: ({value}: {value?: string}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'template', value}),
    setProjectFormStakeholders: ({value}: {value: StakeHolder[]}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'stakeHolders', value}),
    setProjectFormReports: ({value}: {value: Report[]}) => createAction(SET_PROJECT_FORM_VALUE, {name: 'reports', value}),
    saveProject: () => createAction(SAVE_PROJECT),
    getProjectFormData: ({projectId}: {projectId: number}) => createAction(GET_PROJECT_FORM_DATA, {projectId}),
    addStakeHolder: () => createAction(ADD_STAKEHOLDER),
    getProjectStakeHolders: ({projectId, purposes}: {projectId: number, purposes: Purpose[]}) => createAction(GET_PROJECT_STAKEHOLDERS, {projectId, purposes}),
}

export const ProjectsEventActions = {
    
}

export const ProjectsDocumentActions = {
    setProjectFormPurposeIsWithApproval: ({purposeId, value}: {purposeId: number, value: boolean}) => createAction(SET_IS_WITH_APPROVAL, {purposeId, value}),
    setSelectedProjectId: ({id}: {id?: number}) => createAction(SET_SELECTED_PROJECT_ID, {id}),
    setAllProjects: ({projects}: {projects: Project[]}) => createAction(SET_ALL_PROJECTS, {projects}),
    setProjects: ({projects}: {projects: Project[]}) => createAction(SET_PROJECTS, {projects}),
    setProjectForm: ({projectForm}: {projectForm?: ProjectForm}) => createAction(SET_PROJECT_FORM, {projectForm}),
    setProjectFormValue: ({name, value}: {name: string, value?: any}) => createAction(SET_PROJECT_FORM_VALUE, {name, value}),
    setModalOpened: ({value}: {value: boolean}) => createAction(SET_MODAL_OPENED, {value}),
    setIsEdit: ({value}: {value: boolean}) => createAction(SET_IS_EDIT, {value}),
    setDarkMode: ({value}: {value: boolean}) => createAction(SET_DARK_MODE, {value}),
    setStakeholderValue: ({id, name, value}: {id: number, name: string, value?: any}) => createAction(SET_STAKEHOLDER_VALUE, {id, name, value}),
}

export const CommandActions = {...ProjectsCommandActions};
export const EventActions = {...ProjectsEventActions};
export const DocumentActions = {...ProjectsDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>