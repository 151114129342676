import * as React from 'react';
import { getClassNames } from './app-bar.classNames';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getDarkModeEnabled } from '../../redux/reducers/project';
import { getTheme } from '../../utils/costants';

export interface AppBarProps { 
  leftComponent?: any, 
  centerComponent?: any, 
  rightComponent?: any,
  darkModeEnabled: boolean
}

class AppBar extends React.Component<AppBarProps> {

  render() {
    const {leftComponent, centerComponent, rightComponent, darkModeEnabled} = this.props;
    const styles = getClassNames(getTheme(darkModeEnabled));
    return (
        <div className={styles.container}>
          <div className={styles.left}>
            {leftComponent}
          </div>
          <div className={styles.center}>
            {centerComponent}
          </div>
          <div className={styles.right}>
            {rightComponent}
          </div>
        </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
  return bindActionCreators({

  }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(AppBar);