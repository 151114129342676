import {DocumentActions, SET_LOADER, SET_LOADER_ARRAY, SET_MAIN_LOADERS, SET_SHARED_LOADERS, SET_PROJECT_SHARED_LOADERS} from "../actions/loader";
import _ from 'lodash';

interface LoaderState  {
    loaders: any
}

const initialShared = (loading: boolean) => { return{ loadingStatus: loading, loadingEngPhases: loading, loadingDisciplinesAndDocTypes: loading, loadingAllStakeHolders: loading, loadingAllReports: loading}};
const initialProjectShared = (loading: boolean) => { return{ loadingPurposes: loading, loadingEntities: loading, loadingPbs: loading, loadingPurposesConfig: loading, loadingFolders: loading, loadingStakeHolders: loading, loadingProjectReports: loading }};

const initialState: LoaderState = {
    loaders: {
        global: {loading: false},
        administration: { loading: false, loadingInternal: false, loadingExternal: false, loadingInternalRemove: false, loadingExternalRemove: false, loadingInternalNotification: false, loadingExternalNotification: false, loadingInternalNotificationRemove: false, loadingExternalNotificationRemove: false },
        home: { loading: false, loadingPublications: false, loadingClient: false, loadingPurpose: false },
        project: { loading: false },
        documents: { loading: false, loadingContent: false, loadingScroll: false },
        document: { loading: false, loadingContent: false, loadingRevisions: false, loadingUi: false },
        dashboard: { loading: false, loadingContent: false, },
        pending: { loading: false, loadingContent: false, },
        publishMaterial: { loading: false, loadingContent: false, },
        publish: { loading: false, loadingContent: false, },
        supplierMaterial: { loading: false, loadingContent: false, },
        reservedNumbers: { loading: false, loadingContent: false, },
        qualityCheck: { loading: false, loadingContent: false, },
        createRevision: { loading: false, loadingContent: false, },
        shared: initialShared(false),
        projectShared: initialProjectShared(false)
    }
}

export const loaderReducer = (loader = initialState, action : DocumentActions): LoaderState => {
    switch (action.type) {
        
        case SET_LOADER:
            return {...loader, loaders: {...loader.loaders, [action.payload.feature]: {...loader.loaders[action.payload.feature], [action.payload.name]: action.payload.loading }}};

        case SET_LOADER_ARRAY:
            const loaderArray = [...loader.loaders[action.payload.feature][action.payload.name]];
            return {...loader, loaders: {...loader.loaders, [action.payload.feature]: {...loader.loaders[action.payload.feature], 
                                [action.payload.name]:  (_.filter(loaderArray, (id) => {return id === action.payload.id})).length === 0
                                                            ? [...loaderArray, action.payload.id] 
                                                            : _.filter(loaderArray, (id) => {return id !== action.payload.id})}}};

        case SET_MAIN_LOADERS:
            return {...loader, loaders: {...loader.loaders, [action.payload.feature]: {...loader.loaders[action.payload.feature], loadingContent: action.payload.loading, loading: action.payload.loading, loadingScroll: action.payload.loading }}};

        case SET_SHARED_LOADERS:
            return {...loader, loaders: {...loader.loaders, shared: { ...initialShared(action.payload.loading) }}};

        case SET_PROJECT_SHARED_LOADERS:
            return {...loader, loaders: {...loader.loaders, projectShared: { ...initialProjectShared(action.payload.loading) }}};
                                                    
        default:
            return loader;
    }
};

// select from state (read)
const selectLoaderState = (state: any) => state.loader as LoaderState;
export const getLoaders = (state: any) => selectLoaderState(state).loaders;