import {getDocuments, getSelectedItemsIds} from '../reducers/documents'
import { Document } from '../../models/document';
import _ from 'lodash';
import { getDocument, getOpenModal } from '../reducers/document';

export const getSelectedDocument = (state: any): Document | undefined => {
    const selectedItemIds = getSelectedItemsIds(state);
    if(selectedItemIds && selectedItemIds.length === 1){
        const documents = getDocuments(state);
        return (documents.length > 0) ? _.find(documents, d => d.id && (d.id as number).toString() === selectedItemIds[0]) as Document : undefined;              
    }
}
export const getSelectedDocuments = (state: any): Document[] => {
    const selectedItemIds = getSelectedItemsIds(state);
    if(selectedItemIds && selectedItemIds.length > 0){
        const documents = getDocuments(state);
        return (documents.length > 0) ? _.filter(documents, d => selectedItemIds.includes((d.id as number).toString())) : [];              
    }
    return [];
}
export const getContextualDocument = (state: any): Document | undefined => {
    const selectedItemIds = getSelectedItemsIds(state);
    const openedModal = getOpenModal(state);
    if(openedModal)
        return getDocument(state)
    else if(selectedItemIds && selectedItemIds.length === 1){
        const documents = getDocuments(state);
        return (documents.length > 0) ? _.find(documents, d => d.id && (d.id as number).toString() === selectedItemIds[0]) as Document : undefined;              
    }
}
export const getContextualDocuments = (state: any): Document[] => {
    const openedModal = getOpenModal(state);
    if(openedModal){
        const document = getDocument(state);
        return (document) ? [document] : [];
    }
    else 
        return getSelectedDocuments(state);
}