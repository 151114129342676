import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { Label, TextField } from 'office-ui-fabric-react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import NumberFormat from 'react-number-format';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface NewSheetProps extends RouteComponentProps, WithTranslation {
    sheetNumber?: number,
    setContextItem: typeof ModalDocumentAction.setContextItem
}
class NewSheet extends React.Component<NewSheetProps> {
    constructor(props: NewSheetProps) {
        super(props);   
    }

    render(){
        const { sheetNumber, setContextItem } = this.props;
        
        return (
            <>                                   
                <Label>Number of sheets</Label>
                <NumberFormat
                    customInput={TextField}
                    decimalScale={0}
                    value={(sheetNumber) ? sheetNumber : ''}
                    autoComplete={'off'}
                    min={1}
                    onValueChange={(values) => {
                        setContextItem({id: 'sheet', item: values.floatValue });
                    }}
                    styles={{root: {minWidth: 256}}}
                />                                                  
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    sheetNumber: (state) => (getGenericModal('sheet')(state)) ? (getGenericModal('sheet')(state) as any).contextItem as number : undefined,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(NewSheet)));