import React, { CSSProperties } from 'react'
import { Menu } from './menu';
import _ from 'lodash';
import handleViewport from 'react-in-viewport';
//import LessonTimeline from '../timeline/index'
import { Event } from '../.././models/event';

interface Item {
    text: string,
    body: React.ReactElement
}

interface Props {
    items?: Item[],
    className?: string | undefined,
    statusHistory?: Event[],
    hideNotes?: boolean,
    disableAutoScroll?: boolean,
    darkModeEnabled?: boolean
}

interface State {
    selectedIx: number
}

// const style: CSSProperties = {
//     overflow: "auto",
//     position: "relative",
//     height: 'calc(100%)' 
// }

const bodyContainerStyle: CSSProperties = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    top: 20,
    left: 300,
    width: "calc(100% - 300px)",
}

class ScrollablePane extends React.Component<Props, State> {

    preventSelection:boolean = true;
    resetPreventSelection: () => void;

    components: object = {};

    constructor(props: Props) {
        super(props);

        this.state = { selectedIx: 0 };
        this.resetPreventSelection = _.debounce(() => { this.preventSelection = false; }, 1500);
    }

    storeRef = (ix:number, element: any) => {
        (this.components as any)[ix] = element;
    }

    itemSelected = (ix:number) => {
        this.preventSelection = true;
        this.setState({ selectedIx: ix });
        this.resetPreventSelection();

        (this.components as any)[ix].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
    }

    itemVisible = (ix:number) => {
        // console.log(ix);
        if(this.preventSelection) {
            return;
        }
        this.setState({ selectedIx: ix });
    }


    componentDidMount = () => {
        const _this = this;
        const { disableAutoScroll } = this.props;
        if(!disableAutoScroll && this.components && (this.components as any)[0])
            (this.components as any)[0].scrollIntoView({block: 'start', behavior: 'auto'}) 

        this.resetPreventSelection();
    }

    public render() {
        const { items, className, statusHistory, hideNotes, darkModeEnabled, ...rest } = this.props;
        const { selectedIx } = this.state;

        return (

            <div className={className} {...rest}>
                <Menu statusHistory={statusHistory} hideNotes={hideNotes} darkModeEnabled={darkModeEnabled} items={_.map(items || [], (i, ix) => ({ text: i.text, selected: ix === selectedIx }))} onItemSelected={this.itemSelected} />
                <div style={bodyContainerStyle} >
                    {_.map(items || [], (i, ix) => (
                        <ViewportBlock key={ix} onEnterViewport={() => this.itemVisible(ix)} i={i} ix={ix} storeRef={this.storeRef} darkModeEnabled={darkModeEnabled}  />
                    ))}
                </div>
            </div>
        );
    }
}

const BodyBlock = (props: { inViewport: boolean, forwardedRef: any, ix: any, i: Item, storeRef(ix: number, element: any): void, darkModeEnabled: boolean }) => {
    const { inViewport, forwardedRef, ix, i, storeRef, darkModeEnabled} = props;

    return (
        <div key={i.text} ref={(element) => storeRef(ix, element)}>
            <div ref={forwardedRef}></div>
            {i.body}
        </div>
    );
};

const ViewportBlock = (handleViewport as any)(BodyBlock);

export default ScrollablePane;