import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions} from '../../../redux/actions/ui';
import { CommandActions as DocumentCommandActions} from '../../../redux/actions/document';
import { CommandActions as DocumentsCommandActions} from '../../../redux/actions/documents';
import { CommandActions as ModalCommandActions} from '../../../redux/actions/modal';
import _ from 'lodash'
import { getFilterPanelOpened } from '../../../redux/reducers/ui';
import { Document } from '../../../models/document';
import { getTotal } from '../../../redux/reducers/documents';
import { getStatusValues } from '../../../redux/reducers/sharedData';
import { Status } from '../../../models/status';
import { InternalRoleActionsType, ExternalRoleActionsType, isExternalApp } from '../../../utils/costants';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getContextualDocuments, getSelectedDocument } from '../../../redux/selectors/documents';
import { getSelectedProject, getSelectedProjectCodeword } from '../../../redux/selectors/project';
import { Project } from '../../../models/project';
import { formatDateFile, fromLongToShortLanguage, getHistoryName } from '../../../utils/functions';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface ContactsProps extends RouteComponentProps, WithTranslation  {
    filterPanelOpened: boolean, 
    documents: Document[],
    total: number,
    loading: boolean,
    loadingContent: boolean,
    loadingScroll: boolean,
    statusValues: Status[],
    selectedDocument?: Document,
    selectedProject?: Project,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    codeWord?: string,
    setFilterPanelOpened: typeof UiDocumentActions.setFilterPanelOpened,
    openEditModal: typeof DocumentsCommandActions.openEditModal,
    setOpenModal: typeof DocumentCommandActions.setOpenModal,
    openGenericModal: typeof ModalCommandActions.openModal,
    fetchDocuments: typeof DocumentsCommandActions.fetchDocuments,
    downloadTemplate: typeof DocumentsCommandActions.downloadTemplate,
}

class DocumentsCommandBar extends React.Component<ContactsProps> {
    constructor(props: ContactsProps) {
        super(props);
    }   


    getButtons = () => {
        const { documents, total, openGenericModal, downloadTemplate, fetchDocuments, loadingContent, loading, loadingScroll, statusValues, userProjectActions, selectedDocument, selectedProject, history, t, codeWord } = this.props;
        let buttons: ICommandBarItemProps[] = [];
        let historyName = getHistoryName(history);
        const commentsExist = (documents && documents.length > 0) ? _.some(documents, d => d.comments && d.comments.length > 0) : true;
        const wrongStatusSelected: boolean = _.some(documents, d => d.status && d.status.name !== 'ready-for-supplier' && d.status.name !== 'supplier-rejected');
        const docsLanguageShort: string | undefined = (documents.length > 0 && _.every(documents, (d: Document) => d.languageShort === documents[0].languageShort)) ? documents[0].languageShort : undefined;
        const docsFileType: string | undefined = (documents.length > 0 && _.every(documents, (d: Document) => d.fileType === documents[0].fileType)) ? documents[0].fileType : undefined;
        buttons = [
            ...(!isExternalApp && _.find(userProjectActions, a => a === 'ReadReservedNumbers') ? [{
                key: 'reserveNumber',
                name: t('Reserve'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'reserveNumberSupplier'}) },
                iconProps: {
                    iconName: 'NumberField'
                }
            }] : []),    
            ...(isExternalApp && _.find(userProjectActions, a => a === 'ReadReservedNumbers') ? [{
                key: 'batchUpload',
                name: t('Batch Upload'),
                iconProps: {
                    iconName: 'BulkUpload',
                },
                subMenuProps: {
                    items: [
                        {
                            key: 'import',
                            name: t('Import zip'),
                            disabled: (total === 0 || wrongStatusSelected || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'importZip'}) },
                            iconProps: {
                                iconName: 'ZipFolder',
                                style: {color: "#C2891E"}
                            },
                        },
                        {
                            key: 'downloadTemplate',
                            name: t('Download template'),
                            disabled: (total === 0 || wrongStatusSelected || loadingContent || loading || loadingScroll),
                            onClick: () => { downloadTemplate()},
                            iconProps: {
                                iconName: 'ExcelDocument',
                                style: {color: "#008000"}
                            },
                        }
                    ]
                } 
            }] : []), 
            ...(_.find(userProjectActions, a => a === 'GenerateNumberAndPartReservedNumbers') ? [{
                key: 'generateNumbAndPart',
                name: t('Generate number and part'),
                disabled: (documents.length === 0 || total === 0 || documents.length > 250 || _.some(documents, (d: Document) => d.documentNumber || d.sapPart) || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'generateNumbAndPart', contextItem: undefined}) },
                iconProps: {
                    iconName: 'DocumentManagement',
                },
            }] : []), 
            ...((_.find(userProjectActions, a => a === 'NewSheetReservedNumbers') || _.find(userProjectActions, a => a === 'NewRevisionReservedNumbers')) ? [{
                key: 'batchUpload',
                name: t('New'),
                iconProps: {
                    iconName: 'Add',
                },
                subMenuProps: {
                    items: [
                        ...(_.find(userProjectActions, a => a === 'NewSheetReservedNumbers') ? [{
                            key: 'newSheet',
                            name: t('Sheet'),
                            disabled: (documents.length === 0 || _.some(documents, d => !d.isCurrent) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'sheet'}) },
                            iconProps: {
                                iconName: 'Document'
                            }
                        }] : []),    
                        ...(_.find(userProjectActions, a => a === 'NewRevisionReservedNumbers') ? [{
                            key: 'newRevision',
                            name: t('Revision'),
                            disabled: (documents.length === 0 || _.some(documents, d => !d.isCurrent) || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'revision' }) },
                            iconProps: {
                                iconName: 'OpenInNewTab'
                            }
                        }] : []), 
                        ...(_.find(userProjectActions, a => a === 'ReadReservedNumbers') ? [{
                            key: 'selectLanguage',
                            name: t('Language'),
                            disabled: (documents.length === 0 || total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'selectLanguage', optional: true, contextItem: docsLanguageShort}) },
                            iconProps: {
                                iconName: 'Flag',
                            },
                        }] : []), 
                        ...(_.find(userProjectActions, a => a === 'SelectFileTypeReservedNumbers') ? [{
                            key: 'selectFileType',
                            name: t('File type'),
                            disabled: (documents.length === 0 || total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'selectFileType', optional: true, contextItem: docsFileType}) },
                            iconProps: {
                                iconName: 'PlainText',
                            },
                        }] : []),    
                    ]
                } 
            }] : []), 
            ...((_.find(userProjectActions, a => a === 'ReadReservedNumbers') || _.find(userProjectActions, a => a === 'SelectFileTypeReservedNumbers')) ? [{
                key: 'batchUpload',
                name: t('Edit'),
                iconProps: {
                    iconName: 'Edit',
                },
                subMenuProps: {
                    items: [
                        ...(_.find(userProjectActions, a => a === 'ReadReservedNumbers') ? [{
                            key: 'selectLanguage',
                            name: t('Language'),
                            disabled: (documents.length === 0 || total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'selectLanguage', optional: false, contextItem: docsLanguageShort}) },
                            iconProps: {
                                iconName: 'Flag',
                            },
                        }] : []), 
                        ...(_.find(userProjectActions, a => a === 'SelectFileTypeReservedNumbers') ? [{
                            key: 'selectFileType',
                            name: t('File type'),
                            disabled: (documents.length === 0 || total === 0 || loadingContent || loading || loadingScroll),
                            onClick: () => { openGenericModal({id: 'selectFileType', optional: false, contextItem: docsFileType}) },
                            iconProps: {
                                iconName: 'PlainText',
                            },
                        }] : []), 
                    ]
                } 
            }] : []),                                      
            ...(_.find(userProjectActions, a => a === 'ReadReservedNumbers') ? [{
                key: 'downloadComments',
                name: t('Download comments'),
                disabled: (total === 0 || !commentsExist || loadingContent || loading || loadingScroll),
                onClick: () => { openGenericModal({id: 'comments', optional: codeWord + '_Comments_' + formatDateFile(new Date())}) },
                iconProps: {
                    iconName: 'Comment',
                },
            }] : []),   
            // ...(!isExternalApp ? [{
            //     key: 'excelReport',
            //     name: t('Report'),
            //     disabled: (total === 0 || loadingContent || loading || loadingScroll),
            //     onClick: () => { openGenericModal({id: 'excelReport', optional: codeWord + '_Document report_' + formatDateFile(new Date())}) },
            //     iconProps: {
            //         iconName: 'ExcelDocument',
            //         style: {color: "#008000"}
            //       },
            // }] : []),
            {
                key: 'refresh',
                name: t('Refresh'),
                disabled: (loadingContent || loading || loadingScroll),
                onClick: () => { fetchDocuments({}) },
                iconProps: {
                    iconName: 'Refresh',
                },
            }   
        ]
        return buttons;
    };

    getFarButtons = () => {
      const {setFilterPanelOpened, filterPanelOpened, t} = this.props;
      return [
     
        {
          key: 'openPanel',
          name: t('Filters'),
          iconProps: {
            iconName: 'Filter'
          },
          ariaLabel: 'Filters',    
          onClick: () => {setFilterPanelOpened({open: !filterPanelOpened})}      
        },          
      ]; 
  };

    render() {
        const styles = getClassNames();
        return (
            <>
                <CommandBar
                    styles={{root: styles.root, secondarySet: {borderTop: 1}}}
                    items={this.getButtons()}
                    farItems={this.getFarButtons()}
                />
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    filterPanelOpened: getFilterPanelOpened,
    documents: getContextualDocuments,
    total: getTotal,
    statusValues: getStatusValues,
    selectedDocument: getSelectedDocument,
    selectedProject: getSelectedProject,
    codeWord: getSelectedProjectCodeword
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setFilterPanelOpened: UiDocumentActions.setFilterPanelOpened,
        setOpenModal: DocumentCommandActions.setOpenModal,
        openGenericModal: ModalCommandActions.openModal,
        openEditModal: DocumentsCommandActions.openEditModal,
        fetchDocuments: DocumentsCommandActions.fetchDocuments,
        downloadTemplate: DocumentsCommandActions.downloadTemplate,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(DocumentsCommandBar)));
