import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './internal.classNames';
import { CommandActions, DocumentActions } from '../../../../redux/actions/administrationModal';
import { DetailsList, DetailsListLayoutMode, IColumn, ConstrainMode, IDetailsHeaderProps, SelectionMode } from 'office-ui-fabric-react/lib/DetailsList';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ScrollablePane, ScrollbarVisibility } from 'office-ui-fabric-react/lib/ScrollablePane';
import { Sticky, StickyPositionType } from 'office-ui-fabric-react/lib/Sticky';
import {IRenderFunction, SearchBox} from 'office-ui-fabric-react';
import _ from 'lodash';
import { getExternalAppUsers, getExternalFilter } from '../../../../redux/reducers/administrationModal';
import {AppUser, Role} from '../../../../models/administration';
import {getColumns} from './columnsExternal'
import handleViewport from 'react-in-viewport';
import ErrorBox from '../../../../components/error_box/index';
import debounce from 'debounce';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { getLoader } from '../../../../redux/selectors/loader';
import { getAllAdminNotifications, getSelectedProjectExternalAppRoles } from '../../../../redux/selectors/administration';
import { getStakeHolders } from '../../../../redux/reducers/sharedData';
import { StakeHolder } from '../../../../models/stakeHolder';
import { getEventTypes } from '../../../../redux/reducers/notifySettings';
import { EventType } from '../../../../models/eventType';
import { NotifyRule } from '../../../../models/notifyRule';


export interface IndexProps { 
    externalAppUsers: AppUser[], 
    loading: boolean, 
    loadingExternal: boolean,
    loadingExternalNotification: boolean,
    loadingExternalNotificationRemove: boolean,
    canAuthorize: any,
    filter?: string,
    selectedProjectAppRoles: Role[],
    stakeHolders: StakeHolder[]
    allNotifications: NotifyRule[],
    eventTypes: EventType[],
    setExternalUserFieldValue: typeof DocumentActions.setExternalUserFieldValue,
    setExternalFilter: typeof CommandActions.setExternalFilter, 
    fetchExternalUsers: typeof CommandActions.fetchExternalUsers
}


interface IndexState {
    isDebouncing: boolean
}

class Index extends React.Component<IndexProps, IndexState> {
    debouncedGetItems: Function
    constructor(props: IndexProps) {
        super(props);

        this.state = {
            isDebouncing: false
        }

        this.debouncedGetItems = debounce((val: string) => {
            this.setState({isDebouncing: false});
            this.getUsers();
		}, 500);
    }

    setFilterAndSearch = (newFilter?: string) => {
        const { setExternalFilter } = this.props;
        this.setState({isDebouncing: true});
        setExternalFilter({value: newFilter});
        this.debouncedGetItems(newFilter);
    }

    onRenderDetailsHeader = (props?: IDetailsHeaderProps, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element => {
        return (
          <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
            {(defaultRender && props) ? defaultRender({
              ...props,        
            }) : null}
          </Sticky>
        );
    }
    
    getKey = (item: any, index?: number): string => {
        return item.email;
    }

    getUsers = () => {
		const { fetchExternalUsers} = this.props;
		fetchExternalUsers()   		
	}
    
    render() {
        const { isDebouncing } = this.state;
        const { externalAppUsers, loading, loadingExternal, setExternalUserFieldValue, selectedProjectAppRoles, filter, stakeHolders, allNotifications, eventTypes, loadingExternalNotification, loadingExternalNotificationRemove} = this.props;
        const styles = getClassNames();
        const colums = getColumns(setExternalUserFieldValue, selectedProjectAppRoles, stakeHolders, allNotifications, eventTypes );
        return (
            <div className={styles.tabContainer}>    
                <Text variant='xLarge' nowrap>External users</Text>
                <div style={{paddingBottom: 10, marginTop: 20}}>
                    <SearchBox
                        placeholder="Search"
                        value={filter as string}
                        onChange={(e, value) => this.setFilterAndSearch(value)}
                        autoComplete={'off'}
                    />
                </div> 
                <div className={styles.wrapper}>
                    {(loading || loadingExternal || loadingExternalNotification || loadingExternalNotificationRemove) && <Spinner size={SpinnerSize.large}></Spinner>}
                    {!loading && !loadingExternal && !loadingExternalNotification && !loadingExternalNotificationRemove && !isDebouncing && externalAppUsers.length === 0 && <ErrorBox text={"No results found."} grey={true} marginTop={40} />}	
                    {!loading && !loadingExternal && !loadingExternalNotification && !loadingExternalNotificationRemove && !isDebouncing && externalAppUsers.length > 0 &&	                      
                        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                            <DetailsList                              
                                items={externalAppUsers}
                                columns={colums}
                                getKey={this.getKey}
                                setKey="email"
                                layoutMode={DetailsListLayoutMode.justified}
                                isHeaderVisible={true}
                                constrainMode={ConstrainMode.unconstrained}
                                onRenderDetailsHeader={this.onRenderDetailsHeader}
                                selectionPreservedOnEmptyClick={true}
                                selectionMode={SelectionMode.none}                             
                            />
                        </ScrollablePane>                 
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    externalAppUsers: getExternalAppUsers,
    loadingExternal: (state) => getLoader('administration')(state).loadingExternal,
    loading: (state) => getLoader('administration')(state).loading,
    loadingExternalNotification: (state) => getLoader('administration')(state).loadingExternalNotification,
    loadingExternalNotificationRemove: (state) => getLoader('administration')(state).loadingExternalNotificationRemove,
    filter: getExternalFilter,
    selectedProjectAppRoles: getSelectedProjectExternalAppRoles,
    stakeHolders: getStakeHolders,
    allNotifications: getAllAdminNotifications,
    eventTypes: getEventTypes,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setExternalUserFieldValue: DocumentActions.setExternalUserFieldValue, 
        setExternalFilter: CommandActions.setExternalFilter, 
        saveUsers: CommandActions.saveUsers, 
        fetchExternalUsers: CommandActions.fetchExternalUsers
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(Index);
