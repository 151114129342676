import {Actions, CommandActions, EventActions, DocumentActions, SHOW_OR_HIDE_CALLOUT, SET_DEFAULT_FILTERS, RESET_FILTERS} from "../../actions/filters";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import _ from 'lodash';
import { getHistoryName } from "../../../utils/functions";
import { Status } from "../../../models/status";
import { getAllPurposes, getPurposes, getStatusValues } from "../../reducers/sharedData";
import { isExternalApp } from "../../../utils/costants";
import { initialFilters } from "../../reducers/filters";
import { getSelectedProject, getSelectedProjectSuppliers } from "../../selectors/project";
import { getSelectedProjectUserActions } from "../../selectors/auth";
import { Project } from "../../../models/project";

export const filtersMiddlewareHistory = (history: any) => {
    return filtersMiddleware(history);
}


export const filtersMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions | ApiConfigActions.ApiSuccessAction | ApiConfigActions.ApiErrorAction) => {
    next(action);
    let token = null; let historyName = null;

    switch (action.type) {

        case SET_DEFAULT_FILTERS: {
            let historyName = getHistoryName(history);
            if(historyName === 'dashboard'){
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: 'Customer'}));
                next(CommandActions.setDefaultPurposesList({value: []}));
                next(CommandActions.setDefaultStatusList({value: []}));
                next(CommandActions.setIsEngineeringOrder({value: false}));
                next(CommandActions.setIsForInternalUse({value: false}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setSupplierDocIndex({value: undefined}));
                next(CommandActions.setSupplierDocNumber({value: undefined}));
            }
            else if(historyName === 'qualityCheck'){
                const statusValues = getStatusValues(getState());
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: undefined}));
                next(CommandActions.setDefaultPurposesList({value: []}));
                next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'uploaded')]}));
                next(CommandActions.setIsEngineeringOrder({value: false}));
                next(CommandActions.setIsForInternalUse({value: false}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setCustomerDocIndex({value: undefined}));
                next(CommandActions.setCustomerDocNumber({value: undefined}));
            }
            // else if(historyName === 'publish'){
            //     const statusValues = getStatusValues(getState());
            //     next(CommandActions.setStatus({value: []}));
            //     next(CommandActions.setStatusCategory({value: undefined}));
            //     next(CommandActions.setDefaultPurposesList({value: []}));
            //     next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'ready-for-publishing')]}));
            //     next(CommandActions.setIsEngineeringOrder({value: false}));
            //     next(CommandActions.setIsForInternalUse({value: false}));
            //     next(CommandActions.setFolder({value: undefined}));
            // }
            else if(historyName === 'pending'){
                const statusValues = getStatusValues(getState());
                const purposes = getPurposes(getState());
                const selectedProject = getSelectedProject(getState()) as Project;
                const userProjectActions = getSelectedProjectUserActions(getState());
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: undefined}));
                next(CommandActions.setDefaultPurposesList({value: _.filter(purposes, p => p.isWithApproval === true)}));
                next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'published')]}));
                next(CommandActions.setIsEngineeringOrder({value: false}));
                next(CommandActions.setIsForInternalUse({value: false}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setSupplierDocIndex({value: undefined}));
                next(CommandActions.setSupplierDocNumber({value: undefined}));
            }
            else if(historyName === 'supplierMaterial'){
                const statusValues = getStatusValues(getState());
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: undefined}));
                next(CommandActions.setDefaultPurposesList({value: []}));
                next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'supplier-published')]}));
                next(CommandActions.setIsEngineeringOrder({value: true}));
                next(CommandActions.setIsForInternalUse({value: true}));
                next(CommandActions.setTdNumber({value: undefined}));
                next(CommandActions.setPublicationNumber({value: undefined}));
                next(CommandActions.setPwFilename({value: undefined}));
                next(CommandActions.setShowAllVersions({value: false}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setCustomerDocIndex({value: undefined}));
                next(CommandActions.setCustomerDocNumber({value: undefined}));
            }
            else if(historyName === 'createRevision'){
                const statusValues = getStatusValues(getState());
                const allPurposes = getAllPurposes(getState());
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: undefined}));
                next(CommandActions.setDefaultPurposesList({value: _.filter(allPurposes, p => p.name === 'FI')}));
                next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'approved' || sv.name === 'approved-comments' || sv.name === 'declined-comments' || sv.name === 'published')]}));
                next(CommandActions.setIsEngineeringOrder({value: false}));
                next(CommandActions.setIsForInternalUse({value: false}));
                next(CommandActions.setTdNumber({value: undefined}));
                next(CommandActions.setPublicationNumber({value: undefined}));
                next(CommandActions.setPwFilename({value: undefined}));
                next(CommandActions.setShowAllVersions({value: false}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setCustomerDocIndex({value: undefined}));
                next(CommandActions.setCustomerDocNumber({value: undefined}));
            }
            else if(historyName === 'reservedNumbers'){
                const statusValues = getStatusValues(getState());
                next(CommandActions.setStatusCategory({value: 'Supplier'}));
                next(CommandActions.setDefaultPurposesList({value: []}));
                next(CommandActions.setDefaultStatusList({value: (isExternalApp) ? [..._.filter(statusValues, sv => sv.name === 'ready-for-supplier' || sv.name === 'supplier-rejected')] : []}));
                next(CommandActions.setIsEngineeringOrder({value: true}));
                next(CommandActions.setIsForInternalUse({value: true}));
                next(CommandActions.setTdNumber({value: undefined}));
                next(CommandActions.setPublicationNumber({value: undefined}));
                next(CommandActions.setPwFilename({value: undefined}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setCustomerDocIndex({value: undefined}));
                next(CommandActions.setCustomerDocNumber({value: undefined}));
            }
            else if(historyName === 'publishMaterial'){
                const statusValues = getStatusValues(getState());
                next(CommandActions.setStatus({value: []}));
                next(CommandActions.setStatusCategory({value: undefined}));
                next(CommandActions.setDefaultPurposesList({value: []}));
                next(CommandActions.setDefaultStatusList({value: [..._.filter(statusValues, sv => sv.name === 'supplier-uploaded')]}));
                next(CommandActions.setIsEngineeringOrder({value: true}));
                next(CommandActions.setIsForInternalUse({value: false}));
                next(CommandActions.setFolder({value: undefined}));
                next(CommandActions.setFileType({value: undefined}));
                next(CommandActions.setCustomerDocIndex({value: undefined}));
                next(CommandActions.setCustomerDocNumber({value: undefined}));
            }
            if(isExternalApp || (historyName !== 'reservedNumbers' && historyName !== 'supplierMaterial') || getSelectedProjectSuppliers(getState()).length === 0){
                next(CommandActions.setSupplier({value: undefined}));
            }
            break;
        }

        case RESET_FILTERS:
            next(CommandActions.setFilters({value: initialFilters}));
            dispatch(CommandActions.setDefaultFilters());
            break;

        case SHOW_OR_HIDE_CALLOUT:
            next(CommandActions.setShowCallout({value: action.payload.show}));
            next(CommandActions.setElementRef({value: action.payload.ref}));
            next(CommandActions.setSelectedFilter({value: action.payload.filter}));
            break;

        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, next, action);         
            break;
       
        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){
    
        default:
            break;
    }
}