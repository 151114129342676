import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import {Timeline, TimelineEvent} from 'react-event-timeline'
import {Event} from '../../models/event';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { DefaultButton, FontSizes, IconButton } from 'office-ui-fabric-react';
import { DocumentActions } from '../../redux/actions/ui';
import { filterEventsByInternalStatus } from '../../utils/statusFunctions';
import { getHistoryName } from '../../utils/functions';
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { ExternalRoleActionsType, InternalRoleActionsType } from '../../utils/costants';

export interface LessonTimelineProps extends RouteComponentProps { 
    statusHistory?: Event[],
    hideNotes?: boolean,
    selectedProjectCode?: string,
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    darkModeEnabled?: boolean
}
                                                               
class LessonTimeline extends React.Component<LessonTimelineProps> {
    constructor(props: LessonTimelineProps) {
        super(props);
    }

    commonStyle = {
        fontSize: '12px',
        fontWeight: 'normal',
    }

    getTimelineEvents = (): JSX.Element[] => {
        const { statusHistory, hideNotes, history, userProjectActions, darkModeEnabled } = this.props;
        if(statusHistory && statusHistory.length > 0){
            const statusHistoryOrdered = _.orderBy(filterEventsByInternalStatus(getHistoryName(history), statusHistory), sh => sh.date, 'desc');
            let isPartner = _.some(userProjectActions, a => a === 'FilterDocumentsByPartnerId');
            let events: JSX.Element[] = [];
            _.each(statusHistoryOrdered, (s, index) => {
                events = [...events,
                    <div className={(darkModeEnabled) ? 'timelineDiv' : undefined }>
                        <TimelineEvent
                            key={index}
                            title={
                                <div>
                                    <div style={{fontWeight: 600}}>
                                        {(s.status) ? (s.status.description + ((s.status.agileCode && !isPartner) ? (' (' + s.status.agileCode + ')') : ''))  : ''}
                                    </div>
                                    <div>
                                        <div style={{fontSize: 11, minWidth: 185}}>{(s.user && s.user.includes('@')) ? s.user.split('@')[0] : s.user}</div>
                                    </div>
                                </div>
                            }
                            createdAt={s.dateString}
                            icon={<FontIcon iconName={(s.status) ? s.status.icon  : ''} style={{fontSize: FontSizes.large, color:  (s.status) ? s.status.color : undefined}} />}
                            iconColor={(s.status) ? s.status.color  : ''}
                            contentStyle={{width: 'auto', marginRight: 20, ...this.commonStyle }}
                            style={{...this.commonStyle}}                       
                        >
                            {/* {(!hideNotes) ? status.description : ''} */}
                        </TimelineEvent>               
                    </div> 
                ]    
            });
            return events;
        }
        return [];
    }

    render() {
        const events = this.getTimelineEvents();
        const { } = this.props;
        return ( 
            <>
                {events.length > 0 &&
                    <div style={{overflowY: 'auto', overflowX: 'hidden', flexGrow: 1}}>
                        <div style={{display: 'flex'}}>
                            <div style={{minWidth: 200}}>
                                <Timeline>
                                    {events}
                                </Timeline>                
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
} 

const mapStateToProps = createStructuredSelector({
    userProjectActions: getSelectedProjectUserActions,
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(LessonTimeline));