import {Actions} from "../../actions/data";
import {NormalizedItem, ItemToNormalize} from '../models/normalizedItem';

export const normalizeMiddleware = ({dispatch}: {dispatch: any}) => (next: any) => (action: any) => {

    // filter both by action type and metadata content
    if (action.type.includes('SET') && action.meta && action.meta.normalize ) {

        // notify about the transformation
        dispatch(Actions.dataNormalized(action.meta.feature));

        // transform the data structure
        const objects = (action.payload) ? action.payload.reduce((acc: NormalizedItem<ItemToNormalize>, item: ItemToNormalize) => {
            acc[item.publicId] = item;
            return acc;
        }, {}) : {};

        // fire the objects document action
        next({...action, payload: objects, normalize: false });

    } else {
        next(action);
    }

};