import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import _ from 'lodash';
import { getGenericModal } from '../../../redux/selectors/ui';
import { TextField, Text, Label } from 'office-ui-fabric-react';
import { ModalDocumentAction } from '../../../redux/actions/modal';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getContextualDocuments } from '../../../redux/selectors/documents';
import { t } from 'i18next';
import NumberFormat from 'react-number-format';

export interface GenerateNumbAndPartTypeProps extends RouteComponentProps, WithTranslation {
    documentNumber?: number,
    setContextItem: typeof ModalDocumentAction.setContextItem
}
class GenerateNumbAndPartType extends React.Component<GenerateNumbAndPartTypeProps> {
    constructor(props: GenerateNumbAndPartTypeProps) {
        super(props);   
    }

    render(){
        const { documentNumber, setContextItem } = this.props;
        return (
            <>       
                <Text variant='mediumPlus' nowrap>{t('If "SMS Doc.Number" is blank, it will be filled automatically')}</Text>   

                <Label styles={{root: {marginTop: 20}}}>SMS Doc.Number</Label>
                <NumberFormat
                    customInput={TextField}
                    decimalScale={0}
                    value={(documentNumber) ? documentNumber : ''}
                    autoComplete={'off'}
                    min={1}
                    onValueChange={(values) => {
                        setContextItem({id: 'generateNumbAndPart', item: values.floatValue });
                    }}
                    styles={{root: {minWidth: 256}}}
                />                                                 
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    documentNumber: (state) => (getGenericModal('generateNumbAndPart')(state)) ? (getGenericModal('generateNumbAndPart')(state) as any).contextItem as number : undefined,
    selectedDocuments: getContextualDocuments
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItem: ModalDocumentAction.setContextItem
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(GenerateNumbAndPartType)));