import {Actions, CommandActions, EventActions, DocumentActions, FETCH_PROJECTS, LOAD_OBJECTS_AND_SET_PROJECT, OPEN_OR_CLOSE_PROJECT_MODAL, SAVE_PROJECT, GET_PROJECT_FORM_DATA, ADD_STAKEHOLDER, GET_PROJECT_STAKEHOLDERS} from "../../actions/project";
import {ApiConfigActions} from "@pwbapps/reduxcore";
import { DocumentActions as NotificationDocumentActions} from "../../actions/notification";
import { CommandActions as LoaderCommandActions} from "../../actions/loader";
import { CommandActions as SharedCommandActions} from "../../actions/sharedData";
import { CommandActions as UiCommandActions} from "../../actions/ui";
import { DocumentActions as ModalDocumentActions, CommandActions as ModalCommandActions} from "../../actions/modal";
import _ from 'lodash';
import { MessageBarType } from "office-ui-fabric-react/lib/MessageBar";
import { getProjectsUrl, saveProjectUrl } from "../../../utils/projectUrls";
import { Project, ProjectForm } from "../../../models/project";
import { getHistoryName } from "../../../utils/functions";
import { getUserProjects } from "../../selectors/project";
import { getAllProjects, getIsEdit, getProjectForm, getProjectFormStakeholders, getSelectedProjectId } from "../../reducers/project";
import { getAllPurposes, getAllStakeHolders } from "../../reducers/sharedData";
import { getProjectFormDataUrl, getPurposeConfigUrl } from "../../../utils/sharedUrls";
import { Purpose, PurposeConfig } from "../../../models/purpose";
import { CommandActions as AdministrationCommandActions } from "../../actions/administrationModal";
import { getGenericModal } from "../../selectors/ui";
import { Modal } from "../../../models/modal";
import { StakeHolder } from "../../../models/stakeHolder";
import { CommandActions as FiltersCommandActions } from "../../actions/filters";
import { t } from "i18next";
import { Report } from "../../../models/report";
import { CommandActions as NotifySettingsCommandActions } from "../../actions/notifySettings";

export const projectMiddlewareHistory = (history: any) => {
    return projectMiddleware(history);
}

export const projectMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions | ApiConfigActions.EventActions ) => {
    next(action);

    switch (action.type) {

        case OPEN_OR_CLOSE_PROJECT_MODAL:
            if(action.payload.open){
                if(action.payload.projectId){
                    next(DocumentActions.setIsEdit({value: true}));
                    const project = _.find(getAllProjects(getState()), p => p.projectId === action.payload.projectId);
                    if(project){
                        dispatch(CommandActions.getProjectFormData({projectId: action.payload.projectId}));
                    }
                }
                else{
                    next(DocumentActions.setIsEdit({value: false}));
                    next(DocumentActions.setProjectForm({projectForm: new ProjectForm(getAllPurposes(getState()))}));   
                    next(DocumentActions.setModalOpened({value: true})); 
                }
            }
            else {
                next(DocumentActions.setIsEdit({value: false}));
                next(DocumentActions.setProjectForm({projectForm: undefined}));
                next(DocumentActions.setModalOpened({value: false}));
            }
               
            break; 

        case GET_PROJECT_FORM_DATA:
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getProjectFormDataUrl(action.payload.projectId), feature: GET_PROJECT_FORM_DATA, returnObject: {projectId: action.payload.projectId}}}));    
            break; 

        case FETCH_PROJECTS:
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: true}));
            dispatch(ApiConfigActions.CommandActions.apiTokenRequest({request: { method: 'GET', url: getProjectsUrl(action.payload.isConfigurationPage), feature: FETCH_PROJECTS, returnObject: {isConfigurationPage: action.payload.isConfigurationPage}}}));    
            break; 

        case LOAD_OBJECTS_AND_SET_PROJECT:
            let historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoading({ feature: historyName, loading: true}));
            next(DocumentActions.setSelectedProjectId({ id: action.payload.projectId }));
            dispatch(FiltersCommandActions.resetFilters());
            if(historyName === 'landing' || historyName === 'home'){
                dispatch(SharedCommandActions.getProjectSharedDataAndLoadObjects({fetchObjects: false}));    
                dispatch(UiCommandActions.changeDrawerPage({key: '/home'}))
            }
            else 
                dispatch(SharedCommandActions.getProjectSharedDataAndLoadObjects({fetchObjects: true}));  
            break; 

        case SAVE_PROJECT:
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: true}));
            let projectForm = getProjectForm(getState()) as ProjectForm;
            const isEdit = getIsEdit(getState());
            if(!isEdit){
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    
                    request: { 
                        method: 'POST',
                        body: {
                            projectId: projectForm.projectId,
                            projectNumber: projectForm.projectName,
                            projectCodeword: projectForm.projectCodeword,
                            isActivatedInAgile: false,
                            isEnabled: projectForm.isEnabled,
                            mandatoryDrawingColumns: (projectForm.mandatoryDrawingColumns.length > 0) ? _.join(projectForm.mandatoryDrawingColumns, ';') : '',
                            mandatoryDocumentColumns: (projectForm.mandatoryDocumentColumns.length > 0) ? _.join(projectForm.mandatoryDocumentColumns, ';') : '',
                            template: projectForm.template,
                            isFolderPageActive: false,
                            isClientNumGeneratorPageActive: false,
                            isInternalCheckActive: false,
                            isExternalCheckActive: false,
                            isApproveWithCommentsStatusActive: false,
                            isAgileTdCoverPageInternalUse: false,
                            automaticApprovalDays: projectForm.automaticApprovalDays,
                            listPurpose: _.map(projectForm.purposeList, p => {return {purposeId: p.purposeId, isWithApproval: p.isWithApproval}}),
                            listStakeHolder: _.map(projectForm.stakeHolders, s => { return {
                                companyName: s.companyName,
                                address: s.address,
                                country: s.country,
                                initials: s.initials,
                                isPartner: s.isPartner,
                                isSupplier: s.isSupplier,
                                isCustomer: s.isCustomer,
                                isActive: s.isActive
                            }}),
                            listReport: _.map(projectForm.reports, r => r.id as number)                 
                        },
                        url: saveProjectUrl(), 
                        feature: SAVE_PROJECT,
                        returnObject: {projectCodeword: projectForm.projectCodeword}
                    }
                }));    
            }
            else{
                dispatch(ApiConfigActions.CommandActions.apiTokenRequest({
                    request: { 
                        method: 'PUT',
                        body: {
                            projectId: projectForm.projectId,
                            projectNumber: projectForm.projectName,
                            projectCodeword: projectForm.projectCodeword,
                            isActivatedInAgile: false,
                            isEnabled: projectForm.isEnabled,
                            mandatoryDrawingColumns: (projectForm.mandatoryDrawingColumns.length > 0) ? _.join(projectForm.mandatoryDrawingColumns, ';') : '',
                            mandatoryDocumentColumns: (projectForm.mandatoryDocumentColumns.length > 0) ? _.join(projectForm.mandatoryDocumentColumns, ';') : '',
                            template: projectForm.template,
                            isFolderPageActive: false,
                            isClientNumGeneratorPageActive: false,
                            isInternalCheckActive: false,
                            isExternalCheckActive: false,
                            isApproveWithCommentsStatusActive: false,
                            isAgileTdCoverPageInternalUse: false,
                            automaticApprovalDays: projectForm.automaticApprovalDays,
                            listPurpose: _.map(projectForm.purposeList, p => {return {purposeId: p.purposeId, isWithApproval: p.isWithApproval}}),
                            projectConfigurationId: projectForm.projectConfigurationId,
                            eeoConfigurationId: projectForm.eeoConfigurationId,
                            listStakeHolder: _.map(projectForm.stakeHolders, s => { return {
                                companyName: s.companyName,
                                address: s.address,
                                country: s.country,
                                initials: s.initials,
                                isPartner: s.isPartner,
                                isSupplier: s.isSupplier,
                                isCustomer: s.isCustomer,
                                isActive: s.isActive
                            }}),
                            listReport: _.map(projectForm.reports, r => r.id as number)   
                        },
                        url: saveProjectUrl(), 
                        feature: SAVE_PROJECT
                    }
                }));
            }
            break; 

            case ADD_STAKEHOLDER: {
                const stakeHolderForm = (getGenericModal('newStakeHolder')(getState())) ? (getGenericModal('newStakeHolder')(getState()) as Modal).contextItem as StakeHolder : undefined;
                if(stakeHolderForm){
                    let projectForm = getProjectForm(getState()) as ProjectForm;
                    let companyAlreadyIn = false;
                    let error = 'This company name is already in Exod';
                    const allStakeHolders = getAllStakeHolders(getState());
                    if(stakeHolderForm.companyName && allStakeHolders && allStakeHolders.length > 0 && 
                       _.some(allStakeHolders, s => (!stakeHolderForm.stakeHolderId && s.companyName === stakeHolderForm.companyName) || 
                                                             (stakeHolderForm.stakeHolderId && stakeHolderForm.stakeHolderId !== s.stakeHolderId && s.companyName === stakeHolderForm.companyName)))
                    {
                        next(ModalDocumentActions.setContextItemProperty({id: 'newStakeHolder', name: 'errors', value: {...((getGenericModal('newStakeHolder')(getState()) as Modal).contextItem as StakeHolder).errors, companyName: error}}));
                        companyAlreadyIn = true;
                    }
                    if(stakeHolderForm.companyName && stakeHolderForm.initials && !companyAlreadyIn){
                        next(ModalDocumentActions.setContextItemProperty({id: 'newStakeHolder', name: 'errors', value: { companyName: undefined, initials: undefined}}));
                        const stakeholders = getProjectFormStakeholders(getState());
                        const newStakeholders = _.orderBy((!stakeHolderForm.stakeHolderId) ? [...stakeholders, stakeHolderForm] : [..._.filter(stakeholders, s => s.stakeHolderId !== stakeHolderForm.stakeHolderId), stakeHolderForm], s => s.companyName);
                        next(CommandActions.setProjectFormStakeholders({value: _.orderBy(newStakeholders, s => (s.companyName as string).toLocaleLowerCase())}));
                        dispatch(ModalCommandActions.closeModal({id: 'newStakeHolder'}));
                    } 
                    else{
                        next(ModalDocumentActions.setContextItemProperty({id: 'newStakeHolder', name: 'errors', value: {...((getGenericModal('newStakeHolder')(getState()) as Modal).contextItem as StakeHolder).errors, companyName: (!stakeHolderForm.companyName && !companyAlreadyIn) ? t('This field is mandatory') : (companyAlreadyIn ? error : undefined)}}));
                        next(ModalDocumentActions.setContextItemProperty({id: 'newStakeHolder', name: 'errors', value: {...((getGenericModal('newStakeHolder')(getState()) as Modal).contextItem as StakeHolder).errors, initials: (!stakeHolderForm.initials) ? t('This field is mandatory') : undefined}}));
                    }
                }          
                break;
            }

        case ApiConfigActions.API_SUCCESS:
            apiSuccessMiddleware(history, dispatch, getState, next, action);         
            break;
    
        case ApiConfigActions.API_ERROR:
            apiErrorMiddleware(history, dispatch, getState, next, action);         
            break;

        default:
            break;
    }
};

const apiSuccessMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiSuccessAction) => {
    switch(action.meta.feature){

        case FETCH_PROJECTS:
            let isConfigurationPage: boolean | undefined =  (action.meta.returnObject) ? action.meta.returnObject.isConfigurationPage : undefined;
            let projects = (action.payload && action.payload.value) ? action.payload.value : undefined;
            let projectsMapped = _.map(projects, p => {return new Project(p)});
            if(!isConfigurationPage){
                next(DocumentActions.setProjects({projects: projectsMapped}));
                const userProjects = getUserProjects(getState())
                if(userProjects.length === 1 && !getSelectedProjectId(getState())){
                    next(DocumentActions.setSelectedProjectId({id: userProjects[0].projectId as number}));
                    let historyName = getHistoryName(history);
                    if(historyName === 'landing' || historyName === 'home')
                        dispatch(UiCommandActions.changeDrawerPage({key: '/home'}))                
                }
            }
            else
                next(DocumentActions.setAllProjects({projects: projectsMapped}));
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: false}));
            break; 

        case GET_PROJECT_FORM_DATA:
            const project = _.find(getAllProjects(getState()), p => p.projectId === action.meta.returnObject.projectId);
            let allPurposes = getAllPurposes(getState());
            let purposeConfigPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].projectPurposes: [];
            let purposeConfigMapped= _.map(purposeConfigPayload, t => new PurposeConfig(t));
            let purposes: Purpose[] = []
            _.forEach(allPurposes, p => {
                let purposeConfig = _.find(purposeConfigMapped, pc => pc.purposeId === p.id);
                purposes = (purposeConfig) ? [...purposes, {...p, isWithApproval: purposeConfig.isWithApproval} ] : purposes;
            });
            let stakeholdersPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].projectStakeHolders: []; 
            let stakeholdersMapped= _.orderBy(_.map(stakeholdersPayload, t => new StakeHolder(t)), s => (s.companyName as string).toLocaleLowerCase());
            next(DocumentActions.setProjectForm({projectForm: new ProjectForm(purposes, stakeholdersMapped, project)}));  
            let reportsPayload = (action.payload && action.payload.value && action.payload.value.length > 0) ? action.payload.value[0].reports: []; 
            let reportsMapped= _.map(reportsPayload, t => new Report(t));
            next(CommandActions.setProjectFormReports({value: reportsMapped})); 
            next(DocumentActions.setModalOpened({value: true})); 
            break; 

        case SAVE_PROJECT:
            let projectCodeword = (action.meta.returnObject && action.meta.returnObject.projectCodeword) ? action.meta.returnObject.projectCodeword : undefined;
            if(projectCodeword)
                dispatch(AdministrationCommandActions.createProjectRoles({projectId: projectCodeword}));
            else{
                dispatch(CommandActions.fetchProjects({isConfigurationPage: true}));
                dispatch(CommandActions.fetchProjects({}));
                dispatch(SharedCommandActions.fetchAllStakeHolders());
                dispatch(SharedCommandActions.fetchStakeHolders());
                next(DocumentActions.setModalOpened({value: false}));
                next(NotificationDocumentActions.setNotificationStatus({name: 'project', show: true, type: MessageBarType.success, message: 'Project saved successfully'})); 
                setTimeout(() => { next(NotificationDocumentActions.setNotificationStatus({name: 'project', show: false, type: MessageBarType.info, message: ''})); }, 5000);
            }
            break; 

        default:
            break;
    }
}

const apiErrorMiddleware = (history: any, dispatch: any, getState: any, next: any, action: ApiConfigActions.ApiErrorAction) => {
    switch(action.meta.feature){

        case FETCH_PROJECTS:
            let historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get projects list') + ' ' + t('Please refresh the page')}));
            break; 

        case GET_PROJECT_FORM_DATA: {
            let historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot get project purposes') + ' ' + t('Please refresh the page')}));
            next(DocumentActions.setModalOpened({value: false}));
            break; 
        }
    

        case SAVE_PROJECT: {
            let historyName = getHistoryName(history);
            dispatch(LoaderCommandActions.setLoadingContent({ feature: "project", loading: false}));
            next(NotificationDocumentActions.setNotificationStatus({name: historyName, show: true, type: MessageBarType.error, message: t('Cannot save this project') + ' ' + t('Please refresh the page')}));
            next(DocumentActions.setModalOpened({value: false}));
            break; 
        }

        default:
            break;
    }
}