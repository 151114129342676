import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { ModalIds } from '../../models/modal';

export const OPEN_MODAL = `[MODAL] OPEN MODAL`;
export const CLOSE_MODAL = `[MODAL] CLOSE MODAL`;
export const SET_NEW_MODAL = `[MODAL] SET NEW MODAL`;
export const SET_VISIBILITY = `[MODAL] SET VISIBILITY`;
export const SET_CONTEXT_ITEM = `[MODAL] SET CONTEXT ITEM`;
export const RESET_CONTEXT_ITEM_PROPERTIES= `[MODAL] RESET CONTEXT ITEM PROPERTIES`;
export const SET_CONTEXT_ITEM_PROPERTY = `[MODAL] SET CONTEXT ITEM PROPERTY`;
export const SET_NOTES = `[MODAL] SET NOTES`;
export const SET_OPTIONAL = `[MODAL] SET OPTIONAL`;
export const SET_LOADING = `[MODAL] SET LOADING`;

export const ModalCommandActions = {
    openModal: ({id, contextItem, optional}: {id: ModalIds, contextItem?: any, optional?: any}) => createAction(OPEN_MODAL, {id, contextItem, optional}),
    closeModal: ({id, contextItem, optional}: {id: ModalIds, contextItem?: any, optional?: any}) => createAction(CLOSE_MODAL, {id, contextItem, optional}),
    resetContextItemProperties: ({id, name}: {id: ModalIds, name: string}) => createAction(RESET_CONTEXT_ITEM_PROPERTIES, {id, name}),
}

export const ModalDocumentAction = {
    setNewModal: ({id}: {id: ModalIds}) => createAction(SET_NEW_MODAL, {id}),
    setVisibility: ({id, visible}: {id: ModalIds, visible: boolean}) => createAction(SET_VISIBILITY, {id, visible}),
    setContextItem: ({id, item}: {id: ModalIds, item?: any}) => createAction(SET_CONTEXT_ITEM, {id, item}),
    setContextItemProperty: ({id, name, value}: {id: ModalIds, name: string, value?: any}) => createAction(SET_CONTEXT_ITEM_PROPERTY, {id, name, value}),
    setLoading: ({id, loading}: {id: ModalIds, loading: boolean}) => createAction(SET_LOADING, {loading, id}),
    setNotes: ({id, notes}: {id: ModalIds, notes?: string}) => createAction(SET_NOTES, {notes, id}),
    setOptional: ({id, optional}: {id: ModalIds, optional?: any}) => createAction(SET_OPTIONAL, {optional, id})
}


// single actions
export type ModalCommandActions = ActionsUnion<typeof ModalCommandActions>
export type ModalDocumentAction = ActionsUnion<typeof ModalDocumentAction>

export const CommandActions = {...ModalCommandActions};
export const DocumentActions = {...ModalDocumentAction};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>
