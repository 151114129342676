import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { Discipline, ReserveNumberDisciplineDocType } from '../../models/discipline';
import { Type } from '../../models/type';
import { Status } from '../../models/status';
import { EngPhase } from '../../models/engPhase';
import { Entity } from '../../models/entity';
import { Pbs } from '../../models/pbs';
import { Purpose } from '../../models/purpose';
import { Folder } from '../../models/folder';
import { ApplicationStakeHolder, StakeHolder } from '../../models/stakeHolder';
import { Report } from '../../models/report';

export const GET_SHARED_DATA_AND_LOAD_PROJECTS = `[SHARED_DATA] GET SHARED DATA AND LOAD PROJECTS`;
export const GET_PROJECT_SHARED_DATA_AND_LOAD_OBJECTS = `[SHARED_DATA] GET PROJECT SHARED DATA AND LOAD OBJECTS`;
export const FETCH_GRID_OBJECTS = `[SHARED_DATA] FETCH GRID OBJECTS`;
export const FETCH_STATUS_AND_LOAD_OBJECTS = `[SHARED_DATA] FETCH STATUS AND LOAD OBJECTS`;
export const FETCH_TYPES = `[SHARED_DATA] FETCH DOCUMENT TYPES`;
export const FETCH_PURPOSES = `[SHARED_DATA] FETCH PURPOSES`;
export const FETCH_PURPOSES_CONFIG = `[SHARED_DATA] FETCH PURPOSES CONFIG`;
export const FETCH_ENTITIES = `[SHARED_DATA] FETCH ENTITIES`;
export const FETCH_ENG_PHASES = `[SHARED_DATA] FETCH ENG PHASES`;
export const FETCH_DISCIPINES_AND_DOCTYPES = `[SHARED_DATA] FETCH DISCIPLINE AND DOC TYPES`;
export const FETCH_PBS = `[SHARED_DATA] FETCH PBS`;
export const FETCH_FOLDERS = `[SHARED_DATA] FETCH FOLDERS`;
export const FETCH_ALL_STAKEHOLDERS = `[SHARED_DATA] FETCH ALL STAKEHOLDERS`;
export const FETCH_STAKEHOLDERS = `[SHARED_DATA] FETCH STAKEHOLDERS`;
export const FETCH_RESERVE_NUMBERS_VALUES = `[SHARED_DATA] FETCH RESERVE NUMBERS VALUES`;
export const SET_SHARED_VALUE = `[SHARED_DATA] SET SHARED VALUE`;
export const FETCH_REPORTS = `[SHARED_DATA] FETCH REPORTS`;
export const FETCH_PROJECT_REPORTS = `[SHARED_DATA] FETCH PROJECT REPORTS`;
export const FETCH_PROJECT_DATA = `[SHARED_DATA] FETCH PROJECT DATA`;

export const SharedDataCommandActions = {
    getSharedDataAndLoadProjects: () => createAction(GET_SHARED_DATA_AND_LOAD_PROJECTS),
    getProjectSharedDataAndLoadObjects: ({fetchObjects}: {fetchObjects?: boolean}) => createAction(GET_PROJECT_SHARED_DATA_AND_LOAD_OBJECTS, {fetchObjects}),
    fetchGridObjects: ({setDefaultFilters}: {setDefaultFilters?: boolean}) => createAction(FETCH_GRID_OBJECTS, {setDefaultFilters}),
    fetchStatusAndLoadObjects: ({fetchObjects}: {fetchObjects?: boolean}) => createAction(FETCH_STATUS_AND_LOAD_OBJECTS, {fetchObjects}),
    fetchTypes: () => createAction(FETCH_TYPES),
    fetchPurposes: ({fetchObjects}: {fetchObjects?: boolean}) => createAction(FETCH_PURPOSES, {fetchObjects}),
    fetchEntities: () => createAction(FETCH_ENTITIES),
    fetchPurposeConfig: ({allPurposes}: {allPurposes: Purpose[]}) => createAction(FETCH_PURPOSES_CONFIG, {allPurposes}),
    fetchEngPhases: () => createAction(FETCH_ENG_PHASES),
    fetchPbs: () => createAction(FETCH_PBS),
    fetchDisciplineAndDocTypes: () => createAction(FETCH_DISCIPINES_AND_DOCTYPES),
    fetchFolders: () => createAction(FETCH_FOLDERS),
    fetchStakeHolders: () => createAction(FETCH_STAKEHOLDERS),
    fetchAllStakeHolders: () => createAction(FETCH_ALL_STAKEHOLDERS),
    fetchReserveNumbersValues: () => createAction(FETCH_RESERVE_NUMBERS_VALUES),
    fetchReports: () => createAction(FETCH_REPORTS),
    fetchProjectReports: () => createAction(FETCH_PROJECT_REPORTS),
    fetchProjectData: ({fetchObjects}: {fetchObjects?: boolean}) => createAction(FETCH_PROJECT_DATA, {fetchObjects}),
    

    setStatusValues: ({value}: {value: Status[]}) => createAction(SET_SHARED_VALUE, {name: 'statusValues', value}),
    setTypes: ({value}: {value: Type[]}) => createAction(SET_SHARED_VALUE, {name: 'types', value}),
    setAllPurposes: ({value}: {value: Purpose[]}) => createAction(SET_SHARED_VALUE, {name: 'allPurposes', value}),
    setPurposes: ({value}: {value: Purpose[]}) => createAction(SET_SHARED_VALUE, {name: 'purposes', value}),
    setEntities: ({value}: {value: Entity[]}) => createAction(SET_SHARED_VALUE, {name: 'entities', value}),
    setEngPhases: ({value}: {value: EngPhase[]}) => createAction(SET_SHARED_VALUE, {name: 'engPhases', value}),
    setDisciplinesAndDocTypes: ({value}: {value: Discipline[]}) => createAction(SET_SHARED_VALUE, {name: 'disciplines', value}),
    setPbs: ({value}: {value: Pbs[]}) => createAction(SET_SHARED_VALUE, {name: 'pbs', value}),
    setFolders: ({value}: {value: Folder[]}) => createAction(SET_SHARED_VALUE, {name: 'folders', value}),
    setAllStakeHolders: ({value}: {value: ApplicationStakeHolder[]}) => createAction(SET_SHARED_VALUE, {name: 'allStakeHolders', value}),
    setStakeHolders: ({value}: {value: StakeHolder[]}) => createAction(SET_SHARED_VALUE, {name: 'stakeHolders', value}),
    setReserveNumbersPbs: ({value}: {value: Pbs[]}) => createAction(SET_SHARED_VALUE, {name: 'reserveNumbersPbs', value}),
    setReserveNumbersDisciplines: ({value}: {value: ReserveNumberDisciplineDocType[]}) => createAction(SET_SHARED_VALUE, {name: 'reserveNumbersDisciplines', value}),
    setAllReports: ({value}: {value: Report[]}) => createAction(SET_SHARED_VALUE, {name: 'allReports', value}),
    setReports: ({value}: {value: Report[]}) => createAction(SET_SHARED_VALUE, {name: 'reports', value}),
}

export const SharedDataDocumentAction = {
    setSharedValue: ({name, value}: {name: string, value: any}) => createAction(SET_SHARED_VALUE, {name, value}),
}


// single actions
export type SharedDataCommandActions = ActionsUnion<typeof SharedDataCommandActions>
export type SharedDataDocumentAction = ActionsUnion<typeof SharedDataDocumentAction>

export const CommandActions = {...SharedDataCommandActions};
export const DocumentActions = {...SharedDataDocumentAction};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>
