import React, { Component } from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { CommandActions as UiCommandActions, DocumentActions as UiDocumentActions } from '../../redux/actions/ui';
import { getDrawerSelectedKey, getDrawerOpened, getDrawerOnTop } from '../../redux/reducers/ui';
import { Nav, INavLink, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';
import { getClassNames } from './index.classNames';
import { FontIcon, IIconProps, IRenderFunction } from 'office-ui-fabric-react';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import memoizeOne from 'memoize-one';
import { getDarkModeEnabled, getProjects, getSelectedProjectId } from '../../redux/reducers/project';
import { Project } from '../../models/project';
import { getSelectedProjectUserActions } from '../../redux/selectors/auth';
import { ExternalRoleActionsType, InternalRoleActionsType, isExternalApp } from '../../utils/costants';
import _ from 'lodash';
import { getRoles } from '@pwbapps/reduxcore/dist/redux/reducers/auth';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getInternalAppRoles, getExternalAppRoles } from '../../redux/reducers/administrationModal';
import { Role } from '../../models/administration';
import { RoleModels } from '@pwbapps/reduxcore';
import { getSelectedProject } from '../../redux/selectors/project';
import { getStakeHolderId } from '../../redux/reducers/initialization';
import { getLoader } from '../../redux/selectors/loader';

export interface NavBarProps extends RouteComponentProps, WithTranslation  { 
    drawerSelectedKey?: string, 
    drawerOpened: boolean, 
    drawerOnTop: boolean,
    selectedProject?: Project,
    stakeHolderId?: number,
    projects: Project[], 
    userProjectActions: InternalRoleActionsType[] | ExternalRoleActionsType[],
    roles: RoleModels.Role[],
    internalAppRoles: Role[],
    externalAppRoles: Role[],
    loading: boolean,
    darkModeEnabled: boolean,
    changeDrawerPage: typeof UiCommandActions.changeDrawerPage 
}
                                                               
export interface NavBarState { }

class NavBar extends React.Component<NavBarProps, NavBarState> {
    constructor(props: NavBarProps) {
        super(props);

        this.state = {

        };

    }

    changePage = (url: string) => {
        const { changeDrawerPage, history } = this.props;
        changeDrawerPage({key: url});
        history.push(url); 
    }

    onRenderLink = (props?: INavLink, defaultRender?: IRenderFunction<INavLink>): JSX.Element => {
        return (
            <>
                {props && 
                    <div style={{display: 'flex'}}>
                        {props.iconMenu && <FontIcon iconName={props.iconMenu} style={{marginRight: 5, fontSize: 18}}/>  }
                        {props.name}
                    </div>
                }
            </>
        );
    }

    getMemoizeLinks = memoizeOne((language, selectedProject, roles, internalAppRoles, externalAppRoles) => this.getLinks())

    getLinks = (): INavLink[] => {
        const {userProjectActions, roles, selectedProject, projects, t, i18n, internalAppRoles, externalAppRoles, stakeHolderId} = this.props;
        const isSuperAdmin = (roles && roles.length > 0 && _.some(roles, r => r.name === 'SUPERADMIN'));
        let links: INavLink[] = [];
        const loc = window.location.href;
        let env = 'DEV';
        if(loc.includes('qas'))
            env = 'QAS';
        else if(loc.includes('pre'))
            env = 'PRE';
        else if(loc.includes('prd'))
            env = 'PRD';

        links = [...links,             
            ...(projects && projects.length > 0 && selectedProject && _.find(userProjectActions, a => a === 'ShowHome') ? [{
                name: t('Home'),
                url: '',
                key: '/home',
                onClick: () => this.changePage('/home'),
                title: 'Home',
                iconMenu: 'Home',
            }] : []),      
            // ...(selectedProject.isFolderPageActive && _.find(userProjectActions, a => a === 'ShowFolders') ? [{
            //     name: 'Folders',
            //     url: '',
            //     key: '/folders',
            //     onClick: () => this.changePage('/folders'),
            //     title: 'Folders',
            //     iconMenu: 'FabricFolder'
                       
            // }] : []),      
            ...(projects && projects.length > 0 && selectedProject && _.find(userProjectActions, a => a === 'ShowDashboard') ? [{
                name: t('Dashboard'),
                url: '',
                key: '/dashboard',
                onClick: () => this.changePage('/dashboard'),
                title: 'Dashboard',
                iconMenu: 'ViewDashboard'                                                 
            }] : []),   
            ...(projects && projects.length > 0 && selectedProject && !isExternalApp && _.find(userProjectActions, a => a === 'ShowPending') ? [{
                name: t('Pending'),
                url: '',
                key: '/administration/pending',
                onClick: () => this.changePage('/administration/pending'),
                title: 'Pending',
                iconMenu: 'Clock'                       
            }] : []),        
            ...(projects && projects.length > 0 && selectedProject && selectedProject.eeoConfigurationId && (_.find(userProjectActions, a => a === 'ShowReservedNumbers' || a === 'ShowSupplierMaterial' || a === 'ShowCreateRevision')) ? [{
                name: t('Engineering Orders'),
                url: '',
                expandAriaLabel: 'Expand Home section',
                collapseAriaLabel: 'Collapse Home section',
                title: 'Engineering Orders',
                iconMenu: 'ReservationOrders',
                links: [
                    ...(_.find(userProjectActions, a => a === 'ShowReservedNumbers') ? [{
                        name: t(!(isExternalApp) ? 'Reserved Numbers' : 'Dashboard'),
                        url: '',
                        key: '/eeo/reservedNumbers',
                        onClick: () => this.changePage('/eeo/reservedNumbers'),
                        title: !(isExternalApp) ? 'Reserved Numbers' : 'Dashboard'                                                
                    }] : []), 
                    ...(_.find(userProjectActions, a => a === 'ShowSupplierMaterial') ? [{
                        name: t('Supplier Documents'),
                        url: '',
                        key: '/eeo/supplierMaterial',
                        onClick: () => this.changePage('/eeo/supplierMaterial'),
                        title: 'Supplier Documents'                                                 
                    }] : []), 
                    //  
                ],
                isExpanded: true
            }] : []),
            ...((projects && projects.length > 0 && selectedProject && _.find(userProjectActions, a => a === 'ShowQualityCheck' || a === 'ShowPublish' || a === 'ShowPending' || a === 'ShowPublishMaterial') ) ? [{
                name: t('Administration'),
                url: '',
                expandAriaLabel: 'Expand Home section',
                collapseAriaLabel: 'Collapse Home section',
                iconMenu: 'Phone',
                links: [
                    // ...(_.find(userProjectActions, a => a === 'ShowPublish') ? [{
                    //     name: t('Publish'),
                    //     url: '',
                    //     key: '/administration/publish',
                    //     onClick: () => this.changePage('/administration/publish'),
                    //     title: 'Publish'                
                    // }] : []),
                    ...(_.find(userProjectActions, a => a === 'ShowPublishMaterial') ? [{
                        name: t('Publish Documents'),
                        url: '',
                        key: '/administration/publishMaterial',
                        onClick: () => this.changePage('/administration/publishMaterial'),
                        title: 'Publish'                
                    }] : []),
                    ...(isExternalApp && _.find(userProjectActions, a => a === 'ShowPending') ? [{
                        name: t('Pending'),
                        url: '',
                        key: '/administration/pending',
                        onClick: () => this.changePage('/administration/pending'),
                        title: 'Pending'                
                    }] : []) 
                ],
                isExpanded: true,
            }] : []), 
            // ...(selectedProject && selectedProject.isClientNumGeneratorPageActive && _.find(userProjectActions, a => a === 'ShowClientNumber') ? [{
            //     name: t('Generators'),
            //     url: '',
            //     expandAriaLabel: 'Expand Home section',
            //     collapseAriaLabel: 'Collapse Home section',
            //     title: 'Generators',
            //     iconMenu: 'NumberField',
            //     links: [
            //         ...(_.find(userProjectActions, a => a === 'ShowClientNumber') ? [{
            //             name: t('Client Number'),
            //             url: '',
            //             key: '/generators/clientNumber',
            //             onClick: () => this.changePage('/generators/clientNumber'),
            //             title: 'Client Number'                                                 
            //         }] : []),
            //     ],
            //     isExpanded: true
            // }] : []),
            ...(isSuperAdmin && !isExternalApp && roles && roles.length > 0 && internalAppRoles && internalAppRoles.length > 0 && externalAppRoles && externalAppRoles.length > 0 ? [{
                name: t('Projects configuration'),
                url: '',
                key: '/projectsConfiguration',
                onClick: () => this.changePage('/projectsConfiguration'),
                expandAriaLabel: 'Expand Home section',
                collapseAriaLabel: 'Collapse Home section',
                title: 'Projects configuration',
                iconMenu: 'DataManagementSettings',
            }] : []),
            ...(projects && projects.length > 0 && selectedProject && !isExternalApp ? [{
                name: 'Reporting',
                url: '',
                key: '/reporting',
                title: 'Reporting',
                iconMenu: 'ReportDocument',
                links: [
                    {
                        name: 'Status Report',
                        url: '',
                        key: '',
                        onClick: () => {window.open('http://svhilsql51/ReportServer/Pages/ReportViewer.aspx?%2fEXODSMS_' + env + '%2fEXOD-Reports%2fStatusReportForUI&project=' + selectedProject.projectId, '_blank');},
                        title: 'Status Report'       
                                              
                    }
                ],
                isExpanded: true,
            }] : []),
        ]
       
  
        
        return links;
    }

    render() {
        const { drawerSelectedKey, drawerOpened, drawerOnTop, selectedProject, projects, i18n, roles, internalAppRoles, externalAppRoles, loading, darkModeEnabled } = this.props;
        const styles = getClassNames(darkModeEnabled);
        const globalNavButtonIcon: IIconProps = { iconName: 'GlobalNavButton' };
        return ( 
            <>
                { drawerOpened && !loading && 
                    <Nav                      
                        isOnTop={drawerOnTop}
                        selectedAriaLabel="Selected"
                        ariaLabel="Nav basic example"
                        selectedKey={drawerSelectedKey}
                        styles={{root: styles.navbar}}
                        onRenderLink={this.onRenderLink}
                        groups={[
                            {
                                links: this.getMemoizeLinks(i18n.resolvedLanguage, selectedProject, roles, internalAppRoles, externalAppRoles )                                                                                                                
                            }
                        ]}
                    />
                }
            </>
        );
    }
} 

const mapStateToProps = createStructuredSelector({
    drawerSelectedKey: getDrawerSelectedKey,
    drawerOpened: getDrawerOpened,
    drawerOnTop: getDrawerOnTop,
    selectedProject: getSelectedProject,
    projects: getProjects,
    userProjectActions: getSelectedProjectUserActions,
    roles: getRoles,
    internalAppRoles: getInternalAppRoles,
    externalAppRoles: getExternalAppRoles,
    stakeHolderId: getStakeHolderId,
    loading: (state) => getLoader('shared')(state).loadingStatus,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ changeDrawerPage: UiCommandActions.changeDrawerPage }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(NavBar)));