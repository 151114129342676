export class ChartData {
    id: string
    value: number
    constructor(object: any){
        this.id = object.id;
        this.value = object.value;
    }
}

export class PublicationStatusChart {
    totalDocumentLatestRevision: ChartData
    totalDocumentUploaded: ChartData
    totalDocumentReadyForPublishing: ChartData
    totalDocumentPublishedOrChecked: ChartData
    totalDocumentPublishedNoApproval: ChartData

    constructor(object: any) {
        this.totalDocumentLatestRevision = {id: 'Latest Revision', value: object.totalDocumentLatestRevision};
        this.totalDocumentUploaded = {id: 'Uploaded', value: object.totalDocumentUploaded};
        this.totalDocumentReadyForPublishing = {id: 'Ready for Publishing', value: object.totalDocumentReadyForPublishing};
        this.totalDocumentPublishedOrChecked = {id: 'Published or Checked', value: object.totalDocumentPublishedOrChecked};
        this.totalDocumentPublishedNoApproval = {id: 'Published no Approval', value: object.totalDocumentPublishedNoApproval};
    }
}

export class ClientStatusChart {
    totalPublishedWithApproval: ChartData
    totalPublishedWithoutApproval: ChartData
    totalApproved: ChartData
    totalApprovedWithComments: ChartData
    totalRejected : ChartData

    constructor(object: any) {
        this.totalPublishedWithApproval  = {id: 'Published with Approval', value: object.totalPublishedWithApproval};
        this.totalPublishedWithoutApproval = {id: 'Published without Approval', value: object.totalPublishedWithoutApproval};
        this.totalApproved = {id: 'Approved', value: object.totalApproved};
        this.totalApprovedWithComments = {id: 'Approved with Comments', value: object.totalApprovedWithComments};
        this.totalRejected  = {id: 'Rejected', value: object.totalRejected };
    }
}

export class FinalPurposesChart {
    totalDocumentsFi: ChartData
    totalDocumentsAb: ChartData
    totalDocumentsFc: ChartData
    totalDocumentsFa: ChartData
    totalDocumentsFm: ChartData
    totalDocumentsFr: ChartData
    totalDocumentsAm: ChartData
    totalDocumentsFe: ChartData
    totalDocumentsFd: ChartData
    totalDocumentsFmp: ChartData

    constructor(object: any) {
        this.totalDocumentsFi = {id: 'For information', value: object.totalDocumentsFi};
        this.totalDocumentsAb = {id: 'As built', value: object.totalDocumentsAb};
        this.totalDocumentsFc = {id: 'For construction', value: object.totalDocumentsFc};
        this.totalDocumentsFa = {id: 'For approval', value: object.totalDocumentsFa};
        this.totalDocumentsFm = {id: 'For manufactioring', value: object.totalDocumentsFm};
        this.totalDocumentsFr = {id: 'For review', value: object.totalDocumentsFr};
        this.totalDocumentsAm = {id: 'As manufactered', value: object.totalDocumentsAm};
        this.totalDocumentsFe = {id: 'For enquery', value: object.totalDocumentsFe};
        this.totalDocumentsFd = {id: 'For design', value: object.totalDocumentsFd};
        this.totalDocumentsFmp = {id: 'For material purchasing', value: object.totalDocumentsFmp};
    
    }
}