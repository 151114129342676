import {DocumentActions, SET_VALUE} from '../actions/main';
import _ from 'lodash';
import { ClientStatusChart, FinalPurposesChart, PublicationStatusChart } from '../../models/main';

interface MainState  {
    publicationStatusData?: PublicationStatusChart,
    clientStatusdata?: ClientStatusChart
    finalPurposes?: FinalPurposesChart
}

const initialState: MainState = {
    publicationStatusData: undefined,
    clientStatusdata: undefined,
    finalPurposes: undefined
} 

export const mainReducer = (main = initialState, action : DocumentActions): MainState => {
    switch (action.type) {
        
        case SET_VALUE:
            return {...main, [action.payload.name]: action.payload.value };


        default:
            return main;
    }
};

//select from state (read) 
const selectMainState = (state: any) => state.main as MainState;
export const getPublicationStatusData = (state: any) => selectMainState(state).publicationStatusData;
export const getClientStatusData = (state: any) => selectMainState(state).clientStatusdata;
export const getFinalPurposesData = (state: any) => selectMainState(state).finalPurposes;