export class PreviewObject {
    id: string
    url: string
    ext: string
    file?: any
    fileName?: string
    constructor(object: any){
        this.id = object.id;
        this.url = object.url;
        this.ext = object.ext;
        this.file = object.file;
        this.fileName = object.fileName;
    }
}