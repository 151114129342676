import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    container: string;
    left: string,
    center: string,
    right: string
}
  
export const getClassNames = (theme: any): IComponentClassNames => {
    return mergeStyleSets({
        container:{
            display: 'flex',
            position: 'fixed',
            top: 0,
            overflow: 'hidden',
            backgroundColor: theme.palette.themePrimary,
            zIndex: 10000,
            width: '100%',
            height: 48,   
        },
        left: {
            flex: '0 0 228px',
            alignSelf: 'center',
            paddingLeft: 10

        },
        center: {
            display: 'flex',
            justifyContent: 'center',
            flex: '1 1 200px',
            alignSelf: 'center',          
        },
        right: {
            flex: '0 0 228px',
            alignSelf: 'center',
        },
    });
};