import _ from "lodash";
import {PbsLevelsType} from '../utils/costants'

export class Pbs {
    id: string
    name: string
    description: string
    parentId?: string
    parentName?: string
    type: PbsLevelsType
    
    constructor(object?: Plant | PlantUnit | PlantSection | Equipment, reserveNumberObject?: {object: any, type: PbsLevelsType, payload: any}){
        if(object){
            this.id = ((object.type !== 'Plant')) ? (object as (PlantUnit | PlantSection | Equipment)).fullCode : object.code;
            this.name = ((object.type !== 'Plant')) ? (object as (PlantUnit | PlantSection | Equipment)).fullCode : object.code;
            this.description = object.name;
            this.type = object.type;
            if(this.type !== 'Plant')
                this.parentId = (object as (PlantUnit | PlantSection | Equipment)).parentFullCode; 
        }
        else if (reserveNumberObject){
            this.type = reserveNumberObject.type;
            if(reserveNumberObject.type === 'Plant'){
                this.id = reserveNumberObject.object.plantCode;
                this.name = reserveNumberObject.object.plantCode;
                this.description = reserveNumberObject.object.plantName;
                this.parentId = undefined;
                this.parentName = undefined;
            }
            else if(reserveNumberObject.type === 'Plant Unit'){
                this.id = reserveNumberObject.object.plantUnitId;
                this.description = reserveNumberObject.object.plantUnitName;
                this.parentId = reserveNumberObject.object.plantCode;
                this.parentName = reserveNumberObject.object.plantCode;
                this.name = this.parentName + '_' + reserveNumberObject.object.plantUnitCode;
            }
            else if(reserveNumberObject.type === 'Plant Section'){
                this.id = reserveNumberObject.object.plantSectionId;
                this.description = reserveNumberObject.object.plantSectionName;
                this.parentId = reserveNumberObject.object.plantUnitId;

                let pu = (_.find(reserveNumberObject.payload.plantUnits, p => p.plantUnitId === this.parentId)) as any;
                let pl = (_.find(reserveNumberObject.payload.plants, p => p.plantCode === pu.plantCode)) as any;

                this.parentName = pl.plantCode + '_' + pu.plantUnitCode;
                this.name = this.parentName + '_' + reserveNumberObject.object.plantSectionCode;
            }
            else{
                this.id = reserveNumberObject.object.equipmentId;
                this.description = reserveNumberObject.object.equipmentName;
                this.parentId = reserveNumberObject.object.plantSectionId;
                

                let ps = (_.find(reserveNumberObject.payload.plantSections, p => p.plantSectionId === this.parentId)) as any;
                let pu = (_.find(reserveNumberObject.payload.plantUnits, p => p.plantUnitId === ps.plantUnitId)) as any;
                let pl = (_.find(reserveNumberObject.payload.plants, p => p.plantCode === pu.plantCode)) as any;

                this.parentName = pl.plantCode + '_' + pu.plantUnitCode + '_' + ps.plantSectionCode;
                this.name = this.parentName + '_' + reserveNumberObject.object.equipmentCode;
            }
        }
        else{
            this.id = '';
            this.name = '';
            this.description = '';
            this.type = 'Plant'
        }
    }
}

export class Plant {
    id: number
    code: string
    name: string
    plantUnits: PlantUnit[]
    type: PbsLevelsType
    fullCode: string

    constructor(object: any) {
       this.id = object.plantId;
       this.code = object.plantCode;
       this.name = object.plantName;
       this.plantUnits = (object.plantUnits && object.plantUnits.length > 0) ? _.map(object.plantUnits, p => new PlantUnit(p, this.code, this.code)) : [];
       this.fullCode = this.code;
       this.type = 'Plant';
    }
}

export class PlantUnit {
    id: number
    code: string
    name: string
    parentCode: string
    parentFullCode: string
    fullCode: string
    plantSections: PlantSection[]
    type: PbsLevelsType

    constructor(object: any, parentCode: string, parentFullCode: string) {
       this.id = object.plantUnitId;
       this.name = object.plantUnitName;
       this.code = object.plantUnitCode;
       this.parentCode = parentCode;
       this.parentFullCode = parentFullCode;
       this.fullCode = this.parentFullCode + '_' + this.code;
       this.plantSections = (object.plantSections && object.plantSections.length > 0) ? _.map(object.plantSections, p => new PlantSection(p, this.code, this.fullCode)) : [];
       this.type = 'Plant Unit';
    }
}

export class PlantSection {
    id: number
    code: string
    name: string
    parentCode: string
    parentFullCode: string
    fullCode: string
    equipment: Equipment[]
    type: PbsLevelsType

    constructor(object: any, parentCode: string, parentFullCode: string) {
       this.id = object.plantSectionId;
       this.code = object.plantSectionCode;
       this.name = object.plantSectionName;
       this.parentCode = parentCode;
       this.parentFullCode = parentFullCode;
       this.fullCode = this.parentFullCode + '_' + this.code;
       this.equipment = (object.equipment && object.equipment.length > 0) ? _.map(object.equipment, e => new Equipment(e, this.code, this.fullCode)) : [];
       this.type = 'Plant Section';
    }
}

export class Equipment {
     id: number
     code: string
     name: string
     parentCode: string
     parentFullCode: string
     fullCode: string
     type: PbsLevelsType

     constructor(object: any, parentCode: string, parentFullCode: string) {
        this.id = object.equipmentId;
        this.code = object.equipmentCode;
        this.name = object.equipmentName;
        this.parentCode = parentCode;
        this.parentFullCode = parentFullCode;
        this.fullCode = this.parentFullCode + '_' + this.code;
        this.type = 'Equipment';
     }
}

export class PlantConfig {
    plantId?: number
    constructor(object: any){
        this.plantId = object.plantId;
    }
}

export class PbsElement {
    code: string
    description: string
    constructor(object: any) {
       this.code = object.code;
       this.description = object.description;
    }
}
