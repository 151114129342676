import { mergeStyleSets } from '@uifabric/merge-styles';
import { CommonStyle } from '../../../components/styles/commandBar';

export interface IComponentClassNames {
    root: string,
    dropDownItem: string
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        root: CommonStyle,
        dropDownItem: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'  
         },
    });
};