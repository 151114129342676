import React, { CSSProperties } from 'react'
import _ from 'lodash';
import {MenuItem} from './menuItem';

export interface Item {
    text: string,
    selected: boolean
}

interface Props {
    items?: Item[],
    className?: string | undefined
    onItemSelected(ix:number) : void,
    hideNotes?: boolean,
    darkModeEnabled?: boolean
}

const style: CSSProperties = {
    position: "sticky",
    top: 20,
    left: 20,
    width: 250,
    display: "flex",
    flexDirection: "column",
    height: "calc(100% - 20px)",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#E4E4E4",
}

export const Menu: React.SFC<Props> = (props) => {
    const { items, onItemSelected, hideNotes, darkModeEnabled, ...rest } = props;

    return (
        <div style={style} {...rest}>
            {_.map(items || [], (i, ix) => (
                <MenuItem key={i.text} text={i.text} selected={i.selected}  darkModeEnabled={darkModeEnabled} onItemSelected={() => onItemSelected(ix)} />
            ))}
        </div>
    )
}