import {getProjectFormStakeholders, getProjects} from '../reducers/project'
import { Project } from '../../models/project';
import _ from 'lodash';
import {getSelectedProjectId} from '../reducers/project'
import {getInternalAppRoles, getExternalAppRoles} from '../reducers/administrationModal'
import {Role} from '../../models/administration'
import { AuthConfigReducer } from '@pwbapps/reduxcore';
import { ProjectStakeHolderValues, StakeHolder } from '../../models/stakeHolder';
import { getAllStakeHolders, getStakeHolders } from '../reducers/sharedData';
import { Report } from '../../models/report';

export const getUserProjects = (state: any): Project[] => {
    let projectCodes: string[] = [];
    let enabledProjects: Project[] = [];
    const projects = getProjects(state);
    const roles = AuthConfigReducer.getRoles(state);
    if(roles && roles.length > 0){
        if(_.find(roles, r => r.name === 'SUPERADMIN'))
            return projects;
        projectCodes = _.map(roles, r => r.name.split('_')[0]);
        if((projectCodes.length > 0 && projects.length > 0)){
            _.forEach(projects, p => {enabledProjects = (p.projectName && projectCodes.includes(_.replace(p.projectName, /\s/g, ''))) ? [...enabledProjects, p] : enabledProjects });
            return enabledProjects;  
        }    
    }
    return [];
}

export const getSelectedProject = (state: any): Project | undefined => {
    const projects = getUserProjects(state);
    const selectedProjectId = getSelectedProjectId(state);
    return (selectedProjectId && projects && projects.length > 0) ? _.find(projects, p => p.projectId == selectedProjectId) : undefined;
}

export const getSelectedProjectName = (state: any): string | undefined => {
    const project = getSelectedProject(state);
    return (project) ? project.projectName : undefined;
}

export const getSelectedProjectDwgColumns = (state: any): string[] => {
    const project = getSelectedProject(state);
    return (project) ? project.mandatoryDrawingColumns : [];
}

export const getSelectedProjectDocColumns = (state: any): string[] => {
    const project = getSelectedProject(state);
    return (project) ? project.mandatoryDocumentColumns : [];
}

export const getSelectedProjectTemplateTitles = (state: any): {name: string, value: string}[] => {
    const project = getSelectedProject(state);
    return (project) ? project.templateTitles : [];
}

export const getSelectedProjectTemplate = (state: any): string | undefined => {
    const project = getSelectedProject(state);
    return (project) ? project.template : undefined;
}

export const getSelectedProjectPartners = (state: any): StakeHolder[] => {
    const projectStakeHolders = getStakeHolders(state);
    return _.filter(projectStakeHolders, ps => ps.isPartner);
}

export const getSelectedProjectCustomers = (state: any): StakeHolder[] => {
    const projectStakeHolders = getStakeHolders(state);
    return _.filter(projectStakeHolders, ps => ps.isCustomer);
}

export const getSelectedProjectSuppliers = (state: any): StakeHolder[] => {
    const projectStakeHolders = getStakeHolders(state);
    return _.filter(projectStakeHolders, ps => ps.isSupplier);
}

export const getSelectedProjectCodeword= (state: any): string | undefined => {
    const project = getSelectedProject(state);
    return (project) ? project.projectCodeword : undefined;
}


export const getSelectedProjectSelectableStakeHolders = (state: any): StakeHolder[] => {
    const project = getSelectedProject(state);
    const allStakeHolders = getAllStakeHolders(state);
    const projectStakeHolders = getProjectFormStakeholders(state);
    let selectableStakeHolders: StakeHolder[] = [];
    if(project){
        _.forEach(allStakeHolders, as => {
            const alreadyIn = _.some(projectStakeHolders, ps => ps.stakeHolderId === as.stakeHolderId);
            const projectFlags = _.find(as.projectStakeholders , ps => ps.projectId === project.projectId) as ProjectStakeHolderValues;
            if(!alreadyIn && project && as.projectStakeholders && projectFlags){
                selectableStakeHolders = [...selectableStakeHolders, {
                    stakeHolderId: as.stakeHolderId,
                    companyName: as.companyName,
                    country: as.country,
                    address: as.address,
                    initials: as.initials,
                    projectId: project.projectId,
                    isCustomer: false,
                    isPartner: false,
                    isSupplier: false,
                    isActive: projectFlags.isActive
                }];
            }
        });
    }
    return selectableStakeHolders;
}