import {DocumentActions, SET_INTERNAL_USER_FIELD_VALUE, SET_EXTERNAL_USER_FIELD_VALUE, SET_USERS_ARRAY, SET_VALUE, } from '../actions/administrationModal';
import _ from 'lodash';
import {AppUser, Role} from '../../models/administration';

interface AdministrationState  {
    openModal: boolean,
    internalFilter?: string
    externalFilter?: string
    internalAppRoles: Role[],
    externalAppRoles: Role[]
    internalAppUsers: AppUser[],
    internalEditedUsers: AppUser[],
    externalAppUsers: AppUser[],
    externalEditedUsers: AppUser[],
}

const initialState: AdministrationState = {
    openModal: false,
    internalFilter: undefined,
    externalFilter: undefined,
    internalAppRoles: [],
    externalAppRoles: [],
    internalAppUsers: [],
    externalAppUsers: [],
    internalEditedUsers: [],
    externalEditedUsers: []
}


export const administrationModalReducer = (administrationModal = initialState, action : DocumentActions): AdministrationState => {
    switch (action.type) {
        
        case SET_INTERNAL_USER_FIELD_VALUE:
            return {...administrationModal, internalAppUsers: _.map(administrationModal.internalAppUsers, (u) => {return (u.email === action.payload.email) ? {...u, [action.payload.name]: action.payload.value} : u}), 
                                            internalEditedUsers: (_.filter(administrationModal.internalEditedUsers, (u) => {return u.email === action.payload.email}).length > 0) ? 
                                                                    _.map(administrationModal.internalEditedUsers, (u) => {
                                                                        return (u.email === action.payload.email) ? {...u, [action.payload.name]: action.payload.value} : u
                                                                    }) 
                                                                    : [...administrationModal.internalEditedUsers, {..._.find(administrationModal.internalAppUsers, (u) => { return (u.email === action.payload.email) }), [action.payload.name]: action.payload.value}]
            }   
            
        case SET_EXTERNAL_USER_FIELD_VALUE:
            return {...administrationModal, externalAppUsers: _.map(administrationModal.externalAppUsers, (u) => {return (u.email === action.payload.email) ? {...u, [action.payload.name]: action.payload.value} : u}), 
                                            externalEditedUsers: (_.filter(administrationModal.externalEditedUsers, (u) => {return u.email === action.payload.email}).length > 0) ? 
                                                                    _.map(administrationModal.externalEditedUsers, (u) => {
                                                                        return (u.email === action.payload.email) ? {...u, [action.payload.name]: action.payload.value} : u
                                                                    }) 
                                                                    : [...administrationModal.externalEditedUsers, {..._.find(administrationModal.externalAppUsers, (u) => { return (u.email === action.payload.email) }), [action.payload.name]: action.payload.value}]
                }   
            
        case SET_USERS_ARRAY:
            return {...administrationModal, [action.payload.arrayName]: (!action.payload.adding) ? action.payload.users : [...(administrationModal as any)[action.payload.arrayName], ...action.payload.users]        
            } 

        case SET_VALUE:
            return {...administrationModal, [action.payload.name]: action.payload.value };


        default:
            return administrationModal;
    }
};

// select from state (read)
const selectAdministrationState = (state: any) => state.administrationModal as AdministrationState;
export const getInternalAppUsers = (state: any) => selectAdministrationState(state).internalAppUsers;
export const getExternalAppUsers = (state: any) => selectAdministrationState(state).externalAppUsers;
export const getInternalEditUsers = (state: any) => selectAdministrationState(state).internalEditedUsers;
export const getExternalEditUsers = (state: any) => selectAdministrationState(state).externalEditedUsers;
export const getOpenModal = (state: any) => selectAdministrationState(state).openModal;
export const getInternalAppRoles = (state: any) => selectAdministrationState(state).internalAppRoles;
export const getExternalAppRoles = (state: any) => selectAdministrationState(state).externalAppRoles;
export const getInternalFilter = (state: any) => selectAdministrationState(state).internalFilter;
export const getExternalFilter = (state: any) => selectAdministrationState(state).externalFilter;

