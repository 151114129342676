import _ from 'lodash';
import moment from 'moment';
import { Status, StatusHistory } from './status';

export class Event {
    date?: Date
    dateString?: string
    user?: string
    status?: Status

    constructor(object: StatusHistory, status: Status[]){
        if(object){
            this.date = object.date;
            this.dateString = (object.date) ? moment(object.date).format("DD/MM/YYYY HH:mm") : undefined;
            this.user = object.statusUser;
            this.status = (object.statusId) ? _.find(status, s => s.id === object.statusId) : undefined;
        }
    }
}