import {DocumentActions, SET_EVENT_APPLICATION_ID, SET_EVENT_TYPES, SET_UI_RULES, SET_RULE_MAIL, SET_RULE_TEAMS, SET_RULE_ID, SET_RULE} from "../actions/notifySettings";
import { EventType } from "../../models/eventType";
import { NotifyRule } from "../../models/notifyRule";
import _ from "lodash";

interface NotifySettingsState  {
    eventApplicationId?: number
    eventTypes: EventType[]
    uiRules: NotifyRule[]
}

const initialState: NotifySettingsState = {
    eventApplicationId: undefined,
    eventTypes: [],
    uiRules: []
}

export const notifySettingsReducer = (notifySettings = initialState, action : DocumentActions): NotifySettingsState => {
    switch (action.type) {
        
        case SET_EVENT_APPLICATION_ID:
            return {...notifySettings, eventApplicationId: action.payload.id};

        case SET_EVENT_TYPES:
            return {...notifySettings, eventTypes: action.payload.eventTypes};

        case SET_UI_RULES:
            return {...notifySettings, uiRules: action.payload.uiRules};

        case SET_RULE_MAIL:
            return {...notifySettings, uiRules: [..._.filter(notifySettings.uiRules, ur => ur.index !== action.payload.index), {..._.find(notifySettings.uiRules, ur => ur.index === action.payload.index) as NotifyRule, mail : action.payload.value}]};

        case SET_RULE_TEAMS:
            return {...notifySettings, uiRules: [..._.filter(notifySettings.uiRules, ur => ur.index !== action.payload.index), {..._.find(notifySettings.uiRules, ur => ur.index === action.payload.index) as NotifyRule, msChat : action.payload.value}]};

        case SET_RULE_ID:
            return {...notifySettings, uiRules: [..._.filter(notifySettings.uiRules, ur => ur.index !== action.payload.index), {..._.find(notifySettings.uiRules, ur => ur.index === action.payload.index) as NotifyRule, eventTypeRuleId : action.payload.value}]};

        case SET_RULE:
            return {...notifySettings, uiRules: [..._.map(notifySettings.uiRules, ur => {return (ur.index === action.payload.rule.index) ? action.payload.rule : ur})]};

        default:
            return notifySettings;
    }
};

// select from state (read)
const selectNotifySettingsState = (state: any) => state.notifySettings as NotifySettingsState;
export const getEventTypes = (state: any) => selectNotifySettingsState(state).eventTypes;
export const getEventApplicationId = (state: any) => selectNotifySettingsState(state).eventApplicationId;
export const getUiRules = (state: any) => selectNotifySettingsState(state).uiRules;

