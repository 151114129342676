import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { getClassNames } from '../index.classNames';
import { Text } from 'office-ui-fabric-react';
import _ from 'lodash';
import { PublishedTo } from '../../../../models/document';
import { getAllStakeHolders } from '../../../../redux/reducers/sharedData';
import { ApplicationStakeHolder } from '../../../../models/stakeHolder';
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface OtherRevisionsProps extends RouteComponentProps, WithTranslation {
    publishedTo: PublishedTo[],
    allStakeHolders: ApplicationStakeHolder[]
}

class OtherRevisions extends React.Component<OtherRevisionsProps> {
    constructor(props: OtherRevisionsProps) {
        super(props);   
    }

    render(){
        const { publishedTo, allStakeHolders, t } = this.props;
        const styles = getClassNames();
        const customerNames = _.map(publishedTo, p => {return (_.find(allStakeHolders, s => s.stakeHolderId === p.stakeHolderId) as ApplicationStakeHolder).companyName as string});
        return (
            <>
                <div className={styles.tabContainer}>           
                    <div style={{marginBottom: 30}}><Text variant='xLarge' nowrap>{t('Published to')}</Text></div>    
                    <div style={{display: 'flex', flexDirection: 'column', gap: 15, marginLeft: 15}}>
                        {_.map(customerNames, (r, index) => {
                            return(
                                <Text>- {r}</Text>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    allStakeHolders: getAllStakeHolders
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(OtherRevisions)));