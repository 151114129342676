import _ from "lodash"

export class StakeHolder {   
    stakeHolderId?: number
    projectId?: number
    companyName?: string 
    address?: string 
    country?: string 
    initials?: string
    isPartner: boolean 
    isSupplier: boolean 
    isCustomer: boolean 
    isActive: boolean
    errors?: {
        companyName?: string
        initials?: string
    }
    constructor(object?: any, isActive?: boolean) {
        if(object) {
           this.stakeHolderId = object.stakeHolderId;
           this.projectId = object.projectId;
           this.companyName = (object.stakeHolder) ? object.stakeHolder.companyName : (object.companyName ? object.companyName : undefined);
           this.address = (object.stakeHolder) ? object.stakeHolder.address : (object.address ? object.address : undefined);
           this.country = (object.stakeHolder) ? object.stakeHolder.country : (object.country ? object.country : undefined);
           this.initials = (object.stakeHolder) ? object.stakeHolder.initials : (object.initials ? object.initials : undefined);
           this.isPartner = object.isPartner;
           this.isSupplier = object.isSupplier;
           this.isCustomer = object.isCustomer;
           this.isActive = object.isActive;
        }
        else{
            this.isPartner = false;
            this.isSupplier = false;
            this.isCustomer = false;
            this.isActive = false;
        }
        this.errors = ( object && object.errors) ? object.errors : { companyName: undefined, initials: undefined};
    }   
}

export class ApplicationStakeHolder {   
    stakeHolderId?: number
    companyName?: string 
    address?: string 
    country?: string 
    initials?: string
    projectStakeholders: ProjectStakeHolderValues[]
    constructor(object?: any) {
       this.projectStakeholders = [];
       if(object) {
           this.stakeHolderId = object.stakeHolderId;
           this.companyName = object.companyName;
           this.address = object.address;
           this.country = object.country;
           this.initials = object.initials;
           this.projectStakeholders = _.map(object.projectStakeHolders, ps => new ProjectStakeHolderValues(ps));        
       }
    }   
}

export class ProjectStakeHolderValues {   
    projectId?: number
    isPartner: boolean 
    isSupplier: boolean 
    isCustomer: boolean 
    isActive: boolean
   
    constructor(object?: any) {
       if(object) {
           this.projectId = object.projectId;
           this.isPartner = object.isPartner;
           this.isSupplier = object.isSupplier;
           this.isCustomer = object.isCustomer;
           this.isActive = object.isActive;
       }
       else{
        this.isPartner = false;
        this.isSupplier = false;
        this.isCustomer = false;
        this.isActive = false;
       }
    }   
}

