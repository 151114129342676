import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import _ from 'lodash';
import { ChoiceGroup, DatePicker, Dropdown, FontIcon, FontSizes, IChoiceGroupOption, IDropdownOption, ProgressIndicator, TextField } from 'office-ui-fabric-react';
import { DocumentActions as ModalDocumentAction } from '../../../../redux/actions/modal';
import { CommentForm } from '../../../../models/comment';
import { getGenericModal } from "../../../../redux/selectors/ui";
import { withTranslation, WithTranslation  } from 'react-i18next';

export interface NewCommentProps extends RouteComponentProps, WithTranslation {
    commentForm: CommentForm,
    setContextItemProperty: typeof ModalDocumentAction.setContextItemProperty
}

class NewComment extends React.Component<NewCommentProps> {
    constructor(props: NewCommentProps) {
        super(props);   
    }

    render(){
        const { commentForm, t, setContextItemProperty } = this.props;
        return (
            <>
                { commentForm && 
                    <>
                        <TextField 
                            key={'commentText'}
                            label={t("Text")}
                            autoComplete={'off'} 
                            value={commentForm.commentText || ''} 
                            onChange={(e, value) => { 
                                setContextItemProperty({id: 'newComment', name: 'commentText', value})
                            }}
                            multiline
                            rows={5}
                        />   
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
        setContextItemProperty: ModalDocumentAction.setContextItemProperty
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter(NewComment)));