import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    container: string;
    child: string;
}
  
export const getClassNames = (): IComponentClassNames => {
    return mergeStyleSets({
        container: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: 20,
            paddingRight: 20
        },
        child: {
            flex: "1 1 100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
        }
    });
};