import React, { Component } from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router-dom'
import AppBar from '../header/app-bar';
import { getClassNames } from './index.classNames';
import AdministrationModal from '../modals/administration/index';
import RightPanel from '../rightPanel/index';
import { getDrawerOnTop } from '../../redux/reducers/ui';
import FiltersPanel from '../filtersPanel/index';
import Preview from '../documents/functionalities/preview';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import { CommandActions as DocumentsCommandActions } from '../../redux/actions/documents';
import { getGenericModal } from '../../redux/selectors/ui';
import { PreviewObject } from '../../models/files';
import { withTranslation, WithTranslation  } from 'react-i18next';
import { getDarkModeEnabled } from '../../redux/reducers/project';
let GenericModal = GenericModalGenerator();

export interface MasterPageProps extends RouteComponentProps, WithTranslation { 
    navigation?: any, 
    leftComponent?: any, 
    centerComponent?: any, 
    rightComponent?: any, 
    bodyComponent?: any,
    previewObject?: PreviewObject,
    darkModeEnabled: boolean,
    downloadFile: typeof DocumentsCommandActions.downloadFile
}
                                                               

class MasterPage extends React.Component<MasterPageProps> {
    constructor(props: MasterPageProps) {
        super(props);
    }

    render() {
        const {navigation, leftComponent, centerComponent, rightComponent, bodyComponent, previewObject, darkModeEnabled, t, downloadFile} = this.props;
        const styles = getClassNames(darkModeEnabled);
        return (         
            <>
                <AppBar leftComponent={leftComponent} centerComponent={centerComponent} rightComponent={rightComponent}></AppBar>
                <div className={styles.mainContainer}>
                        {navigation}                                  
                        <div className={styles.bodyContainer}>
                            {bodyComponent}
                        </div>                                  
                </div>                     
                <AdministrationModal/>
                <FiltersPanel/>
                <RightPanel/>
                {previewObject && 
                    <GenericModal 
                        id={'preview'}
                        title={t('Download File')}        
                        onClose={() => {}}
                        btnCloseText={t('Cancel')} 
                        onConfirm={() => {downloadFile({id: previewObject.id, fileName: previewObject.fileName as string})}}
                        btnConfirmText={t('Download')}
                        customComponent={<Preview/>}   
                        width={'calc(100vw - 300px)'}           
                    />            
                }
            </>                            
        );
    }
}

const mapStateToProps = createStructuredSelector({
    drawerOnTop: getDrawerOnTop,
    previewObject: (state) => (getGenericModal('preview')(state)) ? (getGenericModal('preview')(state) as any).contextItem as PreviewObject : undefined,
    darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ 
        downloadFile: DocumentsCommandActions.downloadFile
    }, dispatch);
}

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(withRouter((MasterPage))));