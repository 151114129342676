import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { DocumentActions as UiDocumentActions } from '../../../redux/actions/ui';
import { DocumentActions as NotificationDocumentActions } from '../../../redux/actions/notification';
import { CommandActions} from '../../../redux/actions/administrationModal';
import { DefaultButton} from 'office-ui-fabric-react/lib/Button';
import { Text } from 'office-ui-fabric-react/lib/Text';
import InternalUsers from './content/internal';
import ExternalUsers from './content/external';
import { MessageBarType } from 'office-ui-fabric-react';
import { Panel, PanelType, IPanelProps } from 'office-ui-fabric-react/lib/Panel';
import { IRenderFunction } from 'office-ui-fabric-react/lib/Utilities';
import ScrollablePane from '../../../components/scrollablePane/index';
import Notification from '../../../containers/notification/index';
import { getNotification } from '../../../redux/selectors/notification';
import { getInternalEditUsers, getOpenModal } from '../../../redux/reducers/administrationModal';
import {AppUser, ExternalUserForm} from '../../../models/administration';
import { MainCompGenerator as GenericModalGenerator, Actions as GenericModalActions } from '@pwbapps/genericdialog'
import NewExternalUser from './content/functionalities/newExternalUser';
import { ModalCommandActions } from '../../../redux/actions/modal';
import { getDarkModeEnabled, getSelectedProjectId } from '../../../redux/reducers/project';
import { getLoader } from '../../../redux/selectors/loader';
import { getStakeHolders } from '../../../redux/reducers/sharedData';
import { StakeHolder } from '../../../models/stakeHolder';
let GenericModal = GenericModalGenerator();

export interface ModalProps { 
  openModal: boolean, 
  selectedProjectId?: number,
  showNotification: boolean, 
  type: MessageBarType, 
  message: string, 
  permanent: boolean, 
  loading: boolean,     
  loadingExternal: boolean,     
  stakeHolders: StakeHolder[],    
  darkModeEnabled: boolean,
  resetAndCloseModal: typeof CommandActions.resetAndCloseModal, 
  setNotificationStatus: typeof NotificationDocumentActions.setNotificationStatus, 
  saveUsers: typeof CommandActions.saveUsers,
  openGenericModal: typeof ModalCommandActions.openModal,
  saveNewExternalUser: typeof CommandActions.saveNewExternalUser,
  downloadUsers: typeof CommandActions.downloadUsers,
  closeGenericModal: typeof ModalCommandActions.closeModal,
}

class ModalAdministration extends React.Component<ModalProps> {
    constructor(props: ModalProps) {
        super(props);
    }

    closeModal = () => {
      const { resetAndCloseModal } = this.props;
      resetAndCloseModal();
    }

    onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => {
      const { openGenericModal, selectedProjectId, stakeHolders, downloadUsers } = this.props;
      const newExternalUserForm: ExternalUserForm = { email: undefined, company: undefined, country: undefined, notifications: [], errors: undefined };
      const styles = getClassNames();
      return (      
      <>    
        <div style={{display: 'flex', justifyContent: 'space-between', width:'100%', marginLeft: 30, marginRight: 120}}>
          <Text variant='xLarge' nowrap>Administration panel - {selectedProjectId}</Text>
          <div style={{display: 'flex', gap: 20}}>
            <DefaultButton onClick={() => {downloadUsers()}} text="Export users" className={styles.button} />
            <DefaultButton onClick={() => {openGenericModal({id: 'newExternalUser', contextItem: newExternalUserForm})}} text="New external user" className={styles.button} disabled={!stakeHolders || stakeHolders.length === 0}  title={(!stakeHolders || stakeHolders.length === 0) ? 'There are no stakeholders in this project. Please contact your IT support.' : ''}/>
          </div>
        </div> 
        {/* {// This custom navigation still renders the close button (defaultRender).
        // If you don't use defaultRender, be sure to provide some other way to close the panel.
        defaultRender!(props)} */}
      </>
      )
    });

    saveUsers = () => {
      const {saveUsers} = this.props;
      saveUsers();
    };

    onRenderFooterContent = () => {
      const {loading} = this.props;
      const styles = getClassNames();
      return (
        <div>
            <DefaultButton
                primary
                split 
                text="Save" 
                className={styles.button}
                onClick={this.saveUsers}
                disabled={loading}
            />
          <DefaultButton onClick={this.closeModal} text="Cancel" className={styles.button} disabled={loading} />
        </div>
      );
    };

    checkAdmin = () => {
      return true; // DELETE THIS ROW
      //return (window as any).ability.check(`Authorizations:Write`.toLowerCase());
    }
    
    render() {
        const styles = getClassNames();
        const {openModal, showNotification, type, message, permanent, loadingExternal, stakeHolders, darkModeEnabled, saveNewExternalUser, closeGenericModal} = this.props; 
        const canAuthorize = this.checkAdmin();
        return (
          openModal &&  
            <>
              <Panel
                isOpen={openModal}
                hasCloseButton={false}
                type={PanelType.large}
                closeButtonAriaLabel="Close"
                styles={{navigation: styles.header, content: styles.content, scrollableContent: styles.scrollableContent, footer: styles.footer, contentInner: styles.contentInner }}
                onRenderNavigationContent={this.onRenderNavigationContent}
                onRenderFooterContent={this.onRenderFooterContent}
                
              >
                <div className={styles.notify}>
                  <Notification name={'administration'} show={showNotification} type={type} message={message} permanent={permanent}/>
                </div>        

                <ScrollablePane className={styles.notifyHeader} darkModeEnabled={darkModeEnabled} items={[
                    { text: "Internal users", body: <div className={styles.tabContainer}> <InternalUsers canAuthorize={canAuthorize} /></div> },
                    { text: "External users", body: <div className={styles.tabContainer}> <ExternalUsers canAuthorize={canAuthorize} /></div> },
                  ]} 
                /> 
              
              </Panel>
              <GenericModal 
                    id={'newExternalUser'}
                    title={'New external user'}
                    message={''}        
                    onClose={() => {closeGenericModal({id: 'newExternalUser'})}}
                    onConfirm={() => {saveNewExternalUser()}}
                    btnCloseText={'Cancel'}   
                    btnConfirmText={'Save'}   
                    disableConfirm={loadingExternal}
                    disableClose={loadingExternal}
                    customComponent={<NewExternalUser/>}        
                    disableAutoModalClose
                    showLoaderWhileDisabled
                    width={640}
                />
            </>     
        );
    }
}

const mapStateToProps = createStructuredSelector({
  openModal: getOpenModal,
  showNotification: (state) => getNotification('administration')(state).show,
  type: (state) => getNotification('administration')(state).type,
  message: (state) => getNotification('administration')(state).message,
  permanent: (state) => getNotification('administration')(state).permanent,
  selectedProjectId: getSelectedProjectId,
  loading: (state) => getLoader('administration')(state).loading,
  loadingExternal: (state) => getLoader('administration')(state).loadingExternal,
  stakeHolders: getStakeHolders,
  darkModeEnabled: getDarkModeEnabled
});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({
      resetAndCloseModal: CommandActions.resetAndCloseModal,
      setNotificationStatus: NotificationDocumentActions.setNotificationStatus, 
      saveUsers: CommandActions.saveUsers,
      openGenericModal: ModalCommandActions.openModal,
      closeGenericModal: ModalCommandActions.closeModal,
      saveNewExternalUser: CommandActions.saveNewExternalUser,
      downloadUsers: CommandActions.downloadUsers,
    }, dispatch);
}


export default connect(mapStateToProps, matchDispatchToProps)((ModalAdministration));
