import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { ClientStatusChart, FinalPurposesChart, PublicationStatusChart } from '../../models/main';

export const SET_VALUE = `[MAIN] SET VALUE`;
export const FETCH_MAIN_DATA = `[MAIN] FETCH MAIN DATA`;
export const FETCH_PUBLICATION_CHART = `[MAIN] FETCH PUBLICATION CHART`;
export const FETCH_CLIENT_CHART = `[MAIN] FETCH CLIENT CHART`;
export const FETCH_PURPOSES_CHART = `[MAIN] FETCH PURPOSES CHART`;
export const SET_BARS_VALUES_AND_STOP_LOADER = `[MAIN] SET BARS VALUES AND STOP LOADER`;
export const SET_PIE_VALUES_AND_STOP_LOADER = `[MAIN] SET PIE VALUES AND STOP LOADER`;
export const SET_BARS_VALUES = `[MAIN] SET BARS VALUES`;
export const SET_PIE_VALUES = `[MAIN] SET PIE VALUES`;


export const MainCommandActions = {
    fetchMainData: () => createAction(FETCH_MAIN_DATA),
    fetchPublicationChart: () => createAction(FETCH_PUBLICATION_CHART),
    fetchClientChart: () => createAction(FETCH_CLIENT_CHART),
    fetchPurposesChart: () => createAction(FETCH_PURPOSES_CHART),
    setPublicationData: ({value}: {value: PublicationStatusChart}) => createAction(SET_VALUE, {name: 'publicationStatusData', value}),
    setClientStatusData: ({value}: {value: ClientStatusChart}) => createAction(SET_VALUE, {name: 'clientStatusdata', value}),
    setFinalPurposesData: ({value}: {value: FinalPurposesChart}) => createAction(SET_VALUE, {name: 'finalPurposes', value}),
}

export const MainEventActions = {

}
 
export const MainDocumentActions = {
    setValue: ({name, value}: {name: string, value?: any}) => createAction(SET_VALUE, {name, value}),
}

export const CommandActions = {...MainCommandActions};
export const EventActions = {...MainEventActions};
export const DocumentActions = {...MainDocumentActions};
export const Actions = {...CommandActions, ...EventActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type EventActions = ActionsUnion<typeof EventActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>