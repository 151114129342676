import moment from "moment";
import { Document, FileForm } from "./document";

export const StatusValues = ['approved', 'supplier-approved', 'approved-comments', 'canceled', 'reserved',
                             'declined-comments', 'published', 'supplier-published', 
                             'saved', 'uploaded', 'supplier-uploaded', 'waiting', 'rejected', 'sending', 'supplier-rejected', 'ready-for-supplier', 'no-approval-required'
] as const;
export type StatusEnum = (typeof StatusValues)[number];


export class Status {
    category: 'Customer' | 'Supplier'
    id?: number
    name?: StatusEnum
    description?: string
    agileCode?: number
    icon?: string
    color?: string
    comment?: string
    internalStatus: boolean
    date?: Date

    constructor(object?: any){
        if(object){
            this.id = object.statusId;
            this.description = object.statusName;           
            this.category = object.category;
            this.internalStatus = object.internalStatus;
            this.agileCode = object.agileStatus;
            switch(this.description){
                case 'Approved': {
                    this.name = 'approved';
                    this.icon = 'CircleFill';
                    this.color = '#04970D';
                    break;
                }
                case 'Approved with comments': {
                    this.name = 'approved-comments';
                    this.icon = 'CommentAdd';
                    this.color = '#04970D';
                    break;
                }
                case 'Rejected': {
                    this.name = 'rejected';
                    this.description = 'Rejected';
                    this.icon = 'ErrorBadge';
                    this.color = '#bc0c19';
                    break;
                }
                case 'Published to EP': {
                    this.name = 'published';
                    this.icon = 'Completed';
                    this.color = '#0522A3';
                    break;
                }
                case 'Sending to EP': {
                    this.name = 'sending';
                    this.icon = 'Send';
                    this.color = '#24e1dc';
                    break;
                }
                case 'Reserved': {
                    this.name = 'reserved';
                    this.icon = 'Permissions';
                    this.color = '#B38200';
                    break;
                }
                case 'Supplier uploaded': {
                    this.name = 'supplier-uploaded';
                    this.icon = 'CloudUpload';
                    this.color = '#0522A3';
                    break;
                }
                case 'Supplier published': {
                    this.name = 'supplier-published';
                    this.icon = 'Completed';
                    this.color = '#0522A3';
                    break;
                }
                case 'Supplier rejected': {
                    this.name = 'supplier-rejected';
                    this.icon = 'ErrorBadge';
                    this.color = '#bc0c19';
                    break;
                }
                case 'Supplier approved': {
                    this.name = 'supplier-approved';
                    this.icon = 'CircleFill';
                    this.color = '#04970D';
                    break;
                }     
                case 'Ready for supplier': {
                    this.name = 'ready-for-supplier';
                    this.icon = 'UserWarning';
                    this.color = '#44C2EA';
                    break;
                }  
                case 'No approval required': {
                    this.name = 'no-approval-required';
                    this.icon = 'CircleRing';
                    this.color = '#04970D';
                    break;
                }                        
                default: {
                    this.description = '';
                    this.icon = '';
                    this.color = '';
                }
            }

        }
        else{
            this.category = 'Customer';
            this.internalStatus = false;
        }
        this.comment = '';
    }
}

export class StatusHistory {
    id: number
    statusId: number
    date?: Date
    statusUser: string

    constructor(object: any) {
        this.id = object.statusHistoryId;
        this.statusId = object.statusId;
        this.date = (object.date) ? moment((object.date && object.date.includes('.')) ? object.date.split('.')[0] + 'Z' : object.date).toDate() : undefined;
        this.statusUser = object.statusUser;
    }
}

export class UploadFilesForm {
    files: any[]
    total: number
    fileForm: FileForm
    documents: Document[]

    constructor(object: any) {
        this.files = object.files;
        this.total = object.total;
        this.fileForm = object.fileForm;
        this.documents = object.documents;
    }
}