import {createAction, ActionsUnion} from '@pwbapps/reduxcore';
import { SortColumn } from '../../models/sort';

export const SET_ORDER_COLUMN= `[LOADING] SET ORDER COLUMN`;

export const SortCommandActions = {
 
}

export const SortDocumentActions = {
    setOrderColumn: ({feature, orderColumn}: {feature: string, orderColumn: SortColumn }) => createAction(SET_ORDER_COLUMN, {feature, orderColumn}),
}


export const CommandActions = {...SortCommandActions};
export const DocumentActions = {...SortDocumentActions};
export const Actions = {...CommandActions, ...DocumentActions};

// group actions
export type CommandActions = ActionsUnion<typeof CommandActions>
export type DocumentActions = ActionsUnion<typeof DocumentActions>
export type Actions = ActionsUnion<typeof Actions>