import React from 'react'
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getClassNames } from './index.classNames';
import { MessageBarButton, Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { DocumentActions as NotificationDocumentActions } from '../../redux/actions/notification';
import { CommandActions as UiCommandActions } from '../../redux/actions/ui';
import { withRouter, RouteComponentProps } from 'react-router-dom'


export interface NotificationProps extends RouteComponentProps { 
    name: string, 
    type?: MessageBarType, 
    message?: string, 
    show: boolean,
    permanent?: boolean,
    onRetry?: any,
    linkText?: string,
     url?: string,                 
    setNotificationStatus: typeof NotificationDocumentActions.setNotificationStatus, 
    changeDrawerPage: typeof UiCommandActions.changeDrawerPage 
}
                                                               

class Notification extends React.Component<NotificationProps> {
    constructor(props: NotificationProps) {
        super(props);     
    }

    getActions = (): JSX.Element | undefined => {
        const { onRetry } = this.props;
        if(onRetry){
            return (
                <div>
                    <MessageBarButton onClick={onRetry}>Retry</MessageBarButton>
                </div>
            )       
        }
        return undefined;
    }

    gotoLink = (url: string) => {
        const { changeDrawerPage, history } = this.props;
        changeDrawerPage({key: url});
        history.push(url); 
    }
 
    render() {
        const styles = getClassNames();
        const { name, type, message, show, setNotificationStatus, permanent, linkText, url } = this.props;
        return (
            <>
                {
                    show && !permanent &&   <MessageBar actions={this.getActions()} messageBarType={(type) ? type : MessageBarType.info} isMultiline={false} dismissButtonAriaLabel="Close" onDismiss={() => { setNotificationStatus({name: name, show: false, type: MessageBarType.info, message: ''}); }} >{message} 
                                                {linkText && url && 
                                                    <Link href="" onClick={() => this.gotoLink(url)}>{linkText}</Link>                                             
                                                }                        
                                            </MessageBar>
                }    
                {
                    show &&  permanent && <MessageBar actions={this.getActions()} messageBarType={(type) ? type : MessageBarType.info} isMultiline={false} dismissButtonAriaLabel="Close" >{message}</MessageBar>
                }                    
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({

});

function matchDispatchToProps(dispatch: any){
    return bindActionCreators({ setNotificationStatus: NotificationDocumentActions.setNotificationStatus, changeDrawerPage: UiCommandActions.changeDrawerPage }, dispatch); 
}

export default connect(mapStateToProps, matchDispatchToProps)(withRouter(Notification));