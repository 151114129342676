import {getDisciplinesOdataQuery, getPbsOdataQuery, getEngPhasesOdataQuery, getEntitiesOdataQuery, getPurposesOdataQuery, getPurposesConfigOdataQuery, getStakeHoldersOdataQuery, getStakeHolderIdOdataQuery, getAllStakeHoldersOdataQuery, getProjectReportsOdataQuery, getProjectDataOdataQuery, getProjectFormOdataQuery } from './odataDocumentQueries'
import {configuration} from '../configuration'
import { getAllStatusOdataQuery } from './odataDocumentQueries';

// GET
export const getStatusValuesUrl = (): string => {
    const query = getAllStatusOdataQuery() as string;
    return `${configuration.api_endpoint}/Status${(query) ? query : ''}`;
};
export const getTypeValuesUrl = (): string => {
    return `${configuration.api_endpoint}/Types`;
}; 
export const getEngPhaseValuesUrl = (): string => {
    const query = getEngPhasesOdataQuery() as string;
    return `${configuration.api_endpoint}/EngineeringPhases${(query) ? query : ''}`;
}; 
export const getPurposeValuesUrl = (): string => {
    const query = getPurposesOdataQuery() as string;
    return `${configuration.api_endpoint}/Purposes${(query) ? query : ''}`;
}; 
export const getEntityValuesUrl = (projectId: number): string => {
    const query = getEntitiesOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
};
export const getDisciplineAndDocTypesValuesUrl = (): string => {
    const query = getDisciplinesOdataQuery() as string;
    return `${configuration.api_endpoint}/Disciplines${(query) ? query : ''}`;
}; 
export const getProjectDataUrl = (projectId: number): string => {
    const query = getProjectDataOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
}; 
export const getPbsValuesUrl = (projectId: number): string => {
    const query = getPbsOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
}; 
export const getPurposeConfigUrl = (projectId: number): string => {
    const query = getPurposesConfigOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
}; 
export const getProjectFormDataUrl = (projectId: number): string => {
    const query = getProjectFormOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
}; 
export const getFoldersUrl = (projectId: number): string => {
    return `${configuration.api_endpoint}/Folders?projectId=${projectId}`;
};
export const getStakeHoldersUrl = (projectId: number): string => {
    const query = getStakeHoldersOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
};
export const getAllStakeHoldersUrl = (): string => {
    const query = getAllStakeHoldersOdataQuery() as string;
    return `${configuration.api_endpoint}/StakeHolders${(query) ? query : ''}`;
};
export const getStakeHolderIdUrl = (projectId: number, company: string): string => {
    const query = getStakeHolderIdOdataQuery(projectId, company) as string;
    return `${configuration.api_endpoint}/StakeHolders${(query) ? query : ''}`;
};
export const getReserveNumberPbsUrl = (): string => {
    return `${configuration.api_endpoint}/GetPBS`;
};
export const getReportsUrl = (): string => {
    return `${configuration.api_endpoint}/Reports`;
};
export const getProjectReportsUrl = (projectId: number): string => {
    const query = getProjectReportsOdataQuery(projectId) as string;
    return `${configuration.api_endpoint}/Projects${(query) ? query : ''}`;
};