import {Actions, DocumentActions, OPEN_MODAL, CLOSE_MODAL  } from "../../actions/modal";
import * as _ from 'lodash';
import { getGenericModal } from "../../selectors/ui";
import { isPrimitive } from "../../../utils/functions";

export const modalMiddlewareHistory = (history: any) => {
    return modalMiddleware(history);
}

export const modalMiddleware = (history: any) => ({dispatch, getState}: {dispatch: any, getState: any}) => (next: any) => (action: Actions) => {
    next(action);
    let historyName = null;

    switch (action.type) {

        case OPEN_MODAL:
            next(DocumentActions.setNewModal({id: action.payload.id}));
            next(DocumentActions.setContextItem({id: action.payload.id, item: action.payload.contextItem}));
            next(DocumentActions.setOptional({id: action.payload.id, optional: action.payload.optional}));
            next(DocumentActions.setVisibility({id: action.payload.id, visible: true}));
            break;

        case CLOSE_MODAL:
            next(DocumentActions.setVisibility({id: action.payload.id, visible: false}));
            setTimeout(() => { 
                const modal = getGenericModal(action.payload.id)(getState());
                if(modal){
                    if(action.payload.contextItem)
                        next(DocumentActions.setContextItem({id: action.payload.id, item: action.payload.contextItem}));
                    else{
                        let isObject = isPrimitive(modal);
                        let contextItem = (isObject) ? {...modal.contextItem} : modal.contextItem;
                        if(isObject)
                            _.forOwn(contextItem, (value, key) => {contextItem[key] = (Array.isArray(contextItem[key])) ? [] : undefined;});
                        else
                            contextItem = undefined;
                        next(DocumentActions.setContextItem({id: action.payload.id, item: contextItem}));
                    }
                }
                next(DocumentActions.setOptional({id: action.payload.id, optional: (action.payload.optional)}));
            }, 300);
            break;

        default:
            break;
    }
};