import React from 'react';
import { IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import {AppUser, Role} from '../../../../models/administration';
import { ComboBox, IComboBoxOption } from 'office-ui-fabric-react/lib/ComboBox';
import { Checkbox, ICheckboxProps } from 'office-ui-fabric-react/lib/Checkbox';
import { getClassNames } from './internal.classNames';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { DocumentActions } from '../../../../redux/actions/administrationModal';
import { getAppRolesDropdownOptions, getNotificationsDropdownOptions, isProdEnv } from '../../../../utils/functions';
import _ from 'lodash';
import { NotifyRule } from '../../../../models/notifyRule';
import { EventType } from '../../../../models/eventType';

const _hostId: string = getId('tooltipHost');

export const getColumns = (setInternalUserFieldValue: typeof DocumentActions.setInternalUserFieldValue, selectedProjectRoles: Role[], allNotifications: NotifyRule[], eventTypes: EventType[]) => {
      const styles = getClassNames();
    
      const columns: IColumn[] = [
        {
          key: 'column0',
          name: 'Email',
          fieldName: 'email',
          minWidth: 280,
          maxWidth: 280,
          isResizable: true,
          data: 'string',
         //  onColumnClick: (handleClick) ? handleClick : () => {},
          isPadded: true,        
          // isSorted: (orderedColumn && orderedColumn.name === 'name') ? true : false,
          // isSortedDescending: (orderedColumn && orderedColumn.name === 'name' && orderedColumn.type === 'desc') ? true : false,
        },
        {
          key: 'column1',
          name: 'Role',
          fieldName: 'role',
          minWidth: 120,
          maxWidth: 180,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: AppUser) => {
            let role_options: IComboBoxOption[] = getAppRolesDropdownOptions(selectedProjectRoles);
            role_options = (item.role && item.role.name !== 'NONE') ? role_options : _.filter(role_options, ro => ro.key !== 'NONE');
            return(
              <ComboBox
                  className={styles.input}
                  options={role_options}
                  placeholder={'Role...'}
                  onChange={(e, value) => 
                    {
                      let role = _.find(selectedProjectRoles, r => r.azureName === (value as any).azureName )
                      setInternalUserFieldValue({
                        email: item.email as string,
                        name: 'role', 
                        value: {...role, userRoleId: (item.role) ? item.role.userRoleId : undefined}
                      })
                      if(!item.role || (item.role && item.role.name === 'NONE') || (item.role && (!role || role.name === 'NONE'))){
                        setInternalUserFieldValue({
                          email: item.email as string,
                          name: 'notifications', 
                          value: (allNotifications && allNotifications.length > 0 && (!item.role || (item.role && item.role.name === 'NONE'))) ? [...allNotifications] : []
                        })
                      }
                    }
                  }
                  selectedKey={(item.role) ? item.role.id : null}  
                  useComboBoxAsMenuWidth={true}         
                  calloutProps={{calloutMaxHeight: 220}}
                  //text={rootCauses.toString()}          
              />
            );
          }
        },
        ...(isProdEnv() ? [{
          key: 'column2',
          name: 'Notifications',
          fieldName: 'Notifications',
          minWidth: 120,
          maxWidth: 180,
          isResizable: true,
        //  onColumnClick: (handleClick) ? handleClick : () => {},
          data: 'string',
          isPadded: true,
          onRender: (item: AppUser) => {
            let notifications_options = getNotificationsDropdownOptions(allNotifications, eventTypes, false);
            let selectedOptionNames = _.filter(notifications_options, o => _.map(item.notifications, n => n.eventTypeId as number).includes(o.key));
            return(
              <ComboBox
                  multiSelect
                  className={styles.input}
                  options={notifications_options}
                  placeholder={'Notifications...'}
                  title={(selectedOptionNames.length > 0) ? _.join(_.map(selectedOptionNames, o => o.text), ', ') : undefined}
                  onChange={(e, value) => 
                    {
                      let userNotification = (item.notifications) ? _.find(item.notifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;
                      let newNotification = (!userNotification) ?  _.find(allNotifications, r => value && r.eventTypeId === value.key ) as NotifyRule : undefined;

                      setInternalUserFieldValue({
                        email: item.email as string,
                        name: 'notifications', 
                        value: (!userNotification && newNotification) ? [...item.notifications as NotifyRule[], newNotification] : [... _.filter(item.notifications, n => userNotification && n.eventTypeId !== userNotification.eventTypeId) as NotifyRule[]]
                      })

                      setInternalUserFieldValue({
                        email: item.email as string,
                        name: 'notificationsToBeRemoved', 
                        value: (userNotification && !newNotification && userNotification.eventTypeRuleId) ? [...item.notificationsToBeRemoved as NotifyRule[], userNotification] : [... _.filter(item.notificationsToBeRemoved, n => newNotification && n.eventTypeId !== newNotification.eventTypeId) as NotifyRule[]]
                      })
                      
                    }
                  }
                  selectedKey={(item.notifications) ? _.map(item.notifications, n => n.eventTypeId as number) : []}       
                  useComboBoxAsMenuWidth={true}         
                  calloutProps={{calloutMaxHeight: 220}}       
              />
            );
          }
        }] : []),  
    ];

    return columns;
}