import { IPersonaProps } from "office-ui-fabric-react";
import _ from "lodash";
import moment from "moment";
import { ExternalRolesType, FileTypesValues, HistoryPathType, previewsAllowed } from "./costants";
import { Role } from "../models/administration";
import { Purpose } from "../models/purpose";
import { Entity } from "../models/entity";
import { EngPhase } from "../models/engPhase";
import { Discipline, ReserveNumberDisciplineDocType } from "../models/discipline";
import { Type } from "../models/type";
import { FilterComponent } from "../models/filters";
import { StakeHolder } from "../models/stakeHolder";
import { Report } from "../models/report";
import { NotifyRule } from "../models/notifyRule";
import { EventType } from "../models/eventType";
import { configuration } from "../configuration";

export const getHistoryName = (history: any): HistoryPathType => {
    if(history.location.pathname.includes('home'))
        return 'home';
    else if(history.location.pathname.includes('folders'))
        return 'folders';
    else if(history.location.pathname.includes('dashboard'))
        return 'dashboard';
    else if(history.location.pathname.includes('administration/qualityCheck'))
        return 'qualityCheck';
    else if(history.location.pathname.includes('administration/publishMaterial'))
        return 'publishMaterial';
    else if(history.location.pathname.includes('administration/publish'))
        return 'publish';
    else if(history.location.pathname.includes('administration/pending'))
        return 'pending';
    else if(history.location.pathname.includes('eeo/reservedNumbers'))
        return 'reservedNumbers';
    else if(history.location.pathname.includes('eeo/supplierMaterial'))
        return 'supplierMaterial';
    else if(history.location.pathname.includes('eeo/createRevision'))
        return 'createRevision';
    else if(history.location.pathname.includes('generators/clientNumber'))
        return 'clientNumber';
    else if(history.location.pathname.includes('projectsConfiguration'))
        return 'projectsConfiguration';
    else
        return "home";  
}

export const getPossibleExternalRolesByCompany = (company: string, stakeHolders: StakeHolder[], currentRole?: string): ExternalRolesType[] => {
    const stakeHolder = _.find(stakeHolders, s => s.companyName === company) as StakeHolder;
    let roles: ExternalRolesType[] = [];
    if(stakeHolder){
        if(stakeHolder.isPartner)
            roles = [...roles, 'PARTNER'];
        if(stakeHolder.isSupplier)
            roles = [...roles, 'SUPPLIER'];
        if(stakeHolder.isCustomer)
            roles = [...roles, 'APPROVER', 'READER'];
    }
    return roles;
}

export const getContextualFilters = (filters: FilterComponent[], historyName: HistoryPathType): FilterComponent[] => {
    if(historyName !== 'dashboard' && historyName !== 'reservedNumbers')
        filters = _.filter(filters, f => f.id !== 'status');

    if(historyName === 'reservedNumbers')
        filters = _.filter(filters, f => f.id !== 'pwFilename' && f.id !== 'publication' && f.id !== 'tdNumber' && f.id !== 'isForInternalUse');
    else if(historyName === 'supplierMaterial')
        filters = _.filter(filters, f => f.id !== 'pwFilename' && f.id !== 'publication' && f.id !== 'tdNumber' && f.id !== 'isForInternalUse' && f.id !== 'showAllVersions');
    else if(historyName === 'createRevision')
        filters = _.filter(filters, f => f.id !== 'showAllVersions' && f.id !== 'isForInternalUse');
    return filters;
}

export const getEngPhaseDropdownOptions = (engPhases: EngPhase[], isReservingNumbers?: boolean): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(engPhases, (c) => {
        options = [...options, ...(!isReservingNumbers || (isReservingNumbers && (c.code === 'BE' || c.code === 'DE'))) ? [{ key: c.id as number, text: ((c.code as string) + ' - ' + c.name) }] : []]
    });
    return options;
}

export const getTypesDropdownOptions = (values: Type[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.id as number, text: v.name as string}]
    });
    return options;
}

export const getDisciplineDropdownOptions = (disciplines: Discipline[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(disciplines, (c) => {
        options = [...options, { key: c.id as number, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getReservedNumbersDisciplineDropdownOptions = (disciplines: ReserveNumberDisciplineDocType[]): {key: string, text: string}[] => {
    let options: {key: string, text: string}[] = [];
    _.forEach(disciplines, (c) => {
        options = [...options, { key: c.code as string, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getDocTypesDropdownOptions = (discipline: Discipline): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(discipline.docTypes, (c) => {
        options = [...options, { key: c.id as number, text: c.code + ' - ' + c.name as string }]
    });
    return options;
}

export const getReservedNumbersDocTypesDropdownOptions = (disciplines: ReserveNumberDisciplineDocType[], discipline: ReserveNumberDisciplineDocType): {key: string, text: string}[] => {
    let options: {key: string, text: string}[] = [];
    let disciplineDocTypes = _.filter(disciplines, d => d.code === discipline.code && d.name === discipline.name);
    _.forEach(disciplineDocTypes, (c) => {
        options = [...options, { key: c.docTypeCode as string, text: c.docTypeCode + ' - ' + c.docTypeName as string }]
    });
    return options;
}


export const getPurposeDropdownOptions = (values: Purpose[], historyName: HistoryPathType): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        //options = (historyName !== 'pending' || ((historyName ==='pending' && v.isWithApproval))) ? [...options, { key: v.id as number, text: v.name + ' - ' + v.description as string}] : options;
        options = [...options, { key: v.id as number, text: v.description as string}];
    });
    return options;
}

export const getEntityDropdownOptions = (values: Entity[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.id as number, text: v.name as string}]
    });
    return options;
}

export const getAppRolesDropdownOptions = (roles: Role[]): {key: string, text: string, azureName: string}[] => {
    let options: {key: string, text: string, azureName: string}[] = [];
    _.forEach(roles, (r) => {
        options = [...options, { key: r.id as string, text: r.name as string, azureName: r.azureName as string }]
    });
    return [...options];
}

export const getStakeHoldersDropdownOptions = (values: StakeHolder[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(values, (v) => {
        options = [...options, { key: v.stakeHolderId as number, text: v.companyName as string}]
    });
    return options;
}

export const getReportsDropdownOptions = (reports: Report[]): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(reports, (c) => {
        options = [...options, { key: c.id as number, text: c.name as string }]
    });
    return options;
}

export const getNotificationsDropdownOptions = (notifications: NotifyRule[], eventTypes: EventType[], isExternal: boolean): {key: number, text: string}[] => {
    let options: {key: number, text: string}[] = [];
    _.forEach(notifications, (n) => {
        var e = _.find(eventTypes, e => e.eventTypeId === n.eventTypeId) as EventType;
        options = (!isExternal || (isExternal && e.name !== "TdCreated" && e.name !== "SupplierExodToAgile")) ? [...options, { key: n.eventTypeId as number, text: e.name as string }] : options;
    });
    return options;
}

export const getFileTypesDropdownOptions = (): {key: string, text: string}[] => {
    let options: {key: string, text: string}[] = [];
    _.forEach(FileTypesValues, (v) => {
        options = [...options, { key: v as string, text: v as string}]
    });
    return options;
}


export const isMainPage = (historyName: HistoryPathType): boolean => {
    return true;
}

export const getPersonaFromMicrosoftUserObject = (object: any): IPersonaProps => {
    let personaProps: IPersonaProps & { key: string | number }  = {
        text: object.displayName,
        secondaryText: object.mail, // email
        tertiaryText: object.onPremisesSamAccountName || undefined, // windows login
        presence: undefined,
        imageInitials: (!!object.givenName && !!object.surname) ? object.surname.substring(0,1) + object.givenName.substring(0,1) : object.displayName.substring(0,1),
        id: object.id,
        imageShouldFadeIn: true,
        showInitialsUntilImageLoads: true,
        key: object.id
    }
    return personaProps
}

export const launchChromeIfIE = () => {
    try{
        var isChrome = ((navigator.userAgent.indexOf("Chrome") != -1) && (navigator.userAgent.indexOf("Edg") == -1));
        var isEdgeChromium = (navigator.userAgent.indexOf("Edg") != -1);
  
        var url = window.location.href;
  
        if (!(isChrome || isEdgeChromium))
        {	
            //alert('chrome');
            var ws1 = new ActiveXObject("WScript.Shell"); //Available only with Internet Explorer.
            try
            {
                ws1.Exec('"C:\\Program Files\\Google\\Chrome\\Application\\chrome.exe"' + ' -url ' + url);
                //alert('chrome 64');
            }
            catch(err)
            {
                ws1.Exec('"C:\\Program Files (x86)\\Google\\Chrome\\Application\\chrome.exe"' + ' -url ' + url);
                //alert('chrome 86');
            }
            closeWindow();
  
        }
        return false;
     }
     catch(err){
         return true;
     }
}

export const formatDate = (date?: Date): string => {
    if(date)
        return moment(date).format('DD/MM/YYYY');
    else
        return '';
}
export const formatDateFile = (date?: Date): string => {
    if(date)
        return moment(date).format('YYYYMMDD');
    else
        return '';
}


export const closeWindow = () =>{
    window.open('','_parent','');
    window.close();
}

export const isValidUrl = (str: string) => {
    try { new URL(str); } catch (_) { return false; }  
    return true;
} 

export const areStringArraysEqual = (a: string[], b: string[]) =>{
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    a = [..._.sortBy(a)];
    b = [..._.sortBy(b)];
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
}

export const isPrimitive = (element: any)  => {
    return element !== Object(element);
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const getFileExtension = (filename: string): string | undefined => {
    var re = /(?:\.([^.]+))?$/;
    if(filename) {
        let r = re.exec(filename);
        return (r) ? r[1] : undefined;
    }
    return undefined;
}

export const replaceFileExtension = (sourceFilename: string, destinationFilename: string): string | undefined => {
    if(sourceFilename && destinationFilename) {
        let sourceExt = getFileExtension(sourceFilename);
        let destinationExt = getFileExtension(destinationFilename);
        if(sourceExt && destinationExt){
            return sourceFilename.replace('.' + sourceExt, '.' + destinationExt);
        }
    }
    return undefined;
}

export const getCloseFiltersPanel = (historyName: HistoryPathType): boolean => {  
    return true;
}

export const getPurposeLabel = (historyName: HistoryPathType): string => {
    return (historyName === 'dashboard' || historyName === 'pending') ? 'Purpose' : 'Shipping info';
}

export const showStatusFilter = (historyName: HistoryPathType): boolean => {
    return historyName === 'dashboard' || historyName === 'reservedNumbers';
}

export const showParentFileChangeIcon = (historyName: HistoryPathType): boolean => {
    return historyName === 'dashboard' || historyName === 'publish' || historyName === 'publishMaterial' || historyName === 'qualityCheck';
}

export const capitalizeFirstLetter = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getBlob = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = () => {
            var fileBuffer = Buffer.from(reader.result as any);
            //let attachment: Attachment = {name: file.name, fileBuffer: fileBuffer};
            resolve(new Blob([fileBuffer]));
        };

        reader.onabort = () => {
     
        };
        reader.onerror = () => {
        
        };

        reader.readAsArrayBuffer(file);
    });
}

export const canBePreviewed = (ext: string): boolean => {
    return (previewsAllowed.includes(ext)) ? true : false;
}

export const isInternalProdEnv = (): boolean => {
    return configuration.public_url === 'https://exodsms.sms-group.com';
}
export const isExternalProdEnv = (): boolean => {
    return configuration.public_url === 'https://exod.sms-group.com';
}
export const isProdEnv = (): boolean => {
    return isInternalProdEnv() || isExternalProdEnv();
}

export const fromShortToLongLanguage = (languageCode: string): string => {
    const languageMap: { [code: string]: string } = {
        "af": "AF - Africans",
        "ar": "AR - Arabic",
        "bg": "BG - Bulgarian",
        "ca": "CA - Catalan",
        "cs": "CS - Czech",
        "da": "DA - Danish",
        "de": "DE - German",
        "el": "EL - Greek",
        "en": "EN - English",
        "es": "ES - Spanish",
        "et": "ET - Estonian",
        "fi": "FI - Finnish",
        "fr": "FR - French",
        "he": "HE - Hebrew",
        "hi": "HI - Hindi",
        "hr": "HR - Croatian",
        "hu": "HU - Hungarian",
        "id": "ID - Indonesian",
        "is": "IS - Icelandic",
        "it": "IT - Italian",
        "ja": "JA - Japanese",
        "kk": "KK - Kazakh",
        "ko": "KO - Korean",
        "lt": "LT - Lithuanian",
        "lv": "LV - Latvian",
        "ms": "MS - Malay",
        "nl": "NL - Dutch",
        "no": "NO - Norwegian",
        "pl": "PL - Polish",
        "pt": "PT - Portuguese",
        "ro": "RO - Romanian",
        "ru": "RU - Russian",
        "sh": "SH - Serbo-croatian",
        "sk": "SK - Slovakian",
        "sl": "SL - Slovenian",
        "sr": "SR - Serbian",
        "sv": "SV - Swedish",
        "th": "TH - Thai",
        "tr": "TR - Turkish",
        "uk": "UK - Ukrainian",
        "vi": "VI - Vietnamese",
        "zh": "ZH - Chinese"
    };

    return languageMap[languageCode] || "EN - English";
};
export const fromLongToShortLanguage = (languageDescription: string): string => {
    const languageCodeMap: { [description: string]: string } = {
        "AF - Africans": "af",
        "AR - Arabic": "ar",
        "BG - Bulgarian": "bg",
        "CA - Catalan": "ca",
        "CS - Czech": "cs",
        "DA - Danish": "da",
        "DE - German": "de",
        "EL - Greek": "el",
        "EN - English": "en",
        "ES - Spanish": "es",
        "ET - Estonian": "et",
        "FI - Finnish": "fi",
        "FR - French": "fr",
        "HE - Hebrew": "he",
        "HI - Hindi": "hi",
        "HR - Croatian": "hr",
        "HU - Hungarian": "hu",
        "ID - Indonesian": "id",
        "IS - Icelandic": "is",
        "IT - Italian": "it",
        "JA - Japanese": "ja",
        "KK - Kazakh": "kk",
        "KO - Korean": "ko",
        "LT - Lithuanian": "lt",
        "LV - Latvian": "lv",
        "MS - Malay": "ms",
        "NL - Dutch": "nl",
        "NO - Norwegian": "no",
        "PL - Polish": "pl",
        "PT - Portuguese": "pt",
        "RO - Romanian": "ro",
        "RU - Russian": "ru",
        "SH - Serbo-croatian": "sh",
        "SK - Slovakian": "sk",
        "SL - Slovenian": "sl",
        "SR - Serbian": "sr",
        "SV - Swedish": "sv",
        "TH - Thai": "th",
        "TR - Turkish": "tr",
        "UK - Ukrainian": "uk",
        "VI - Vietnamese": "vi",
        "ZH - Chinese": "zh"
    };

    return languageCodeMap[languageDescription] || 'en';
};
