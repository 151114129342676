import {DocumentActions, SET_NOTIFICATION_STATUS} from "../actions/notification";
import { MessageBarType } from 'office-ui-fabric-react';

interface NotificationState  {
    notifications: any
}

const initialState: NotificationState = {

    notifications:{
        landing: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        home: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        rightPanel: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        administration: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined  },
        project: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        documents: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        document: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        dashboard: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        pending: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        publishMaterial: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        supplierMaterial: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        publish: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        reservedNumbers: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        qualityCheck: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined },
        createRevision: { show: false, message: '', type: MessageBarType.info, permanent: false, onRetry: undefined, linkText: undefined, url: undefined }
    }
}

export const notificationReducer = (notification = initialState, action : DocumentActions): NotificationState => {
    switch (action.type) {
        
        case SET_NOTIFICATION_STATUS:
            return {...notification, notifications: {...notification.notifications, [action.payload.name]: {...notification.notifications[action.payload.name], 
                                                                                                                show: action.payload.show, type: action.payload.type, 
                                                                                                                message: action.payload.message,
                                                                                                                permanent: (action.payload.permanent) ? true : false,
                                                                                                                onRetry: (action.payload.onRetry) ? action.payload.onRetry : undefined,
                                                                                                                linkText: (action.payload.linkText) ? action.payload.linkText : undefined,
                                                                                                                url: (action.payload.url) ? action.payload.url : undefined
                                                                                                            }}};

        default:
            return notification;
    }
};

// select from state (read)
const selectNotificationState = (state: any) => state.notification as NotificationState;
export const getNotifications = (state: any) => selectNotificationState(state).notifications;