import * as React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';

export const getField = (selector: (state: any) => any) => {

    interface IndexProps { value?: any | null, children: (value: any) => void}
    class Index extends React.Component<IndexProps> {
        render() {
            const { value, children } = this.props;
            return (
                <>
                    {children(value)}
                </>
            );
        }                 
    }

    const mapStateToProps = createStructuredSelector({
        value: selector,
    });
    
    function matchDispatchToProps(dispatch: any){
        return bindActionCreators({}, dispatch);
    }
    
    return connect(mapStateToProps, matchDispatchToProps)(Index);
}