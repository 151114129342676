import {DocumentActions, SET_LOADER, SET_DRAWER_KEY, SET_PANEL_STATUS, SET_TOOLTIP, SET_DRAWER_ON_TOP, SET_INTERNAL_APP,
    SET_SHOW_TEACHING_BUBBLE, SET_FILTER_PANEL_OPENED, SET_DRAWER_OPENED, SET_FEEDBACK_TYPE, SET_FEEDBACK_TEXT} from "../actions/ui";
import _ from 'lodash';

interface uiState  {
    loading: boolean,
    drawerSelectedKey?: string,
    filterPanelOpened: boolean,
    drawerOnTop: boolean,
    drawerOpened: boolean,
    panelOpened: boolean,
    panelContent?: string,
    panelTitle?: string,
    showTeachingBubble: boolean,
    tooltips: any,
    feedbackType: string,
    feedbackText?: string,
    isInternalApp?: boolean
}

const initState: uiState = {
    loading: false,
    drawerSelectedKey: undefined,
    filterPanelOpened: false,
    drawerOnTop: false,
    drawerOpened: true,
    panelOpened: false,
    panelContent: undefined,
    panelTitle: undefined,
    showTeachingBubble: false,
    tooltips: {
        default: {show: false, ref: undefined, content: undefined, row: undefined},
    },
    feedbackType: 'like',
    feedbackText: undefined,
    isInternalApp: undefined
};

export const uiReducer = (ui = initState, action: DocumentActions) => {
    switch (action.type) {
        case SET_FEEDBACK_TEXT:
            return {...ui, feedbackText: action.payload.text};

        case SET_FEEDBACK_TYPE:
            return {...ui, feedbackType: action.payload.type};

        case SET_DRAWER_ON_TOP:
            return {...ui, drawerOnTop: action.payload.onTop};

        case SET_DRAWER_OPENED:
            return {...ui, drawerOpened: action.payload.open};

        case SET_FILTER_PANEL_OPENED:
            return {...ui, filterPanelOpened: action.payload.open, panelOpened: false, panelTitle: undefined, panelContent: undefined };

        case SET_LOADER:
            return {...ui, loading: action.payload.state};

        case SET_SHOW_TEACHING_BUBBLE:
            return {...ui, showTeachingBubble: action.payload.showTeachingBubble};

        case SET_DRAWER_KEY:
            return {...ui, drawerSelectedKey: action.payload.key};

        case SET_PANEL_STATUS:
            return {...ui, panelOpened: action.payload.open, panelTitle: action.payload.title, panelContent: action.payload.content, filterPanelOpened: false, feedbackType: 'like', feedbackText: undefined };
          
        case SET_TOOLTIP:
            return {...ui, tooltips: {...ui.tooltips, [action.payload.name]: {...ui.tooltips[action.payload.name], show: action.payload.show, ref: action.payload.ref, content: action.payload.content, row: action.payload.row}}};   

        case SET_INTERNAL_APP:
            return {...ui, isInternalApp: action.payload.isInternal};

        default:
            return ui;
    }
};

const selectUiState = (state: any) => state.ui as uiState;
export const getDrawerSelectedKey = (state: any) => selectUiState(state).drawerSelectedKey;
export const getLoading = (state: any) => selectUiState(state).loading;
export const getFilterPanelOpened = (state: any) => selectUiState(state).filterPanelOpened;
export const getPanelOpened = (state: any) => selectUiState(state).panelOpened;
export const getPanelContent = (state: any) => selectUiState(state).panelContent;
export const getPanelTitle= (state: any) => selectUiState(state).panelTitle;
export const getShowTeachingBubble = (state: any) => selectUiState(state).showTeachingBubble;
export const getTooltips = (state: any) => selectUiState(state).tooltips;
export const getDrawerOpened = (state: any) => selectUiState(state).drawerOpened;
export const getDrawerOnTop = (state: any) => selectUiState(state).drawerOnTop;
export const getFeedbackType = (state: any) => selectUiState(state).feedbackType;
export const getFeedbackText = (state: any) => selectUiState(state).feedbackText;
export const getIsInternalApp = (state: any) => selectUiState(state).isInternalApp;