import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    resultContainer: string,
    wrapper: string,
    pieContainer: string,
    attachment: string
}
  
export const getClassNames = (largerPadding: boolean): IComponentClassNames => {
    return mergeStyleSets({
        resultContainer: {
            paddingRight: (largerPadding) ? 360 : 15,
            
            // selectors:{
            //     '@media only screen and (max-width: 1279px)': {
            //         paddingRight: (largerPadding) ? 100 : 10,
            //     }
            // }
        },
        attachment: {
            selectors:{
                ':hover': {
                    backgroundColor: '#edebe9'
                }
            }
        },
        wrapper: {
            height: '85vh',
            position: 'relative',
        },
        pieContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 800,
            height: 400,
            position: 'relative',
            selectors:{
                '@media only screen and (max-height: 1350px)': {
                    height: 450,
                },
                '@media only screen and (max-height: 1150px)': {
                    height: 400,
                },
                '@media only screen and (max-height: 950px)': {
                    height: 350,
                },
                '@media only screen and (max-height: 750px)': {
                    height: 300,
                },

                '@media only screen and (max-width: 1850px)': {
                    width: 750,
                },
                '@media only screen and (max-width: 1825px)': {
                    width: 725,
                },
                '@media only screen and (max-width: 1800px)': {
                    width: 700,
                },
                '@media only screen and (max-width: 1775px)': {
                    width: 675,
                },
                '@media only screen and (max-width: 1750px)': {
                    width: 650,
                },
                '@media only screen and (max-width: 1700px)': {
                    width: 635,
                },
                // '@media only screen and (max-width: 1650px)': {
                //     width: 620,
                // },
                // '@media only screen and (max-width: 1550px)': {
                //     width: 600,
                // }
            },
        }

            
    });
};