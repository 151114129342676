import { mergeStyleSets } from '@uifabric/merge-styles';

export interface IComponentClassNames {
    homeContainer: string,
    pieBox: string
    totalText: string
    //pieContainer: string
}
  
export const getClassNames = (largerPadding: boolean, loading: boolean, darkModeEnabled?: boolean): IComponentClassNames => {
    return mergeStyleSets({
        homeContainer: {
            paddingTop: 5,
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            flexWrap: 'wrap'
        },
        pieBox: {
            width: 450, 
            height: 400, 
            marginBottom: 15, 
            marginRight: 15,
            textAlign: 'center', 
            position: 'relative', 
            boxShadow: (!darkModeEnabled) ? '0px 0px 4px 2px rgba(0,0,0,.2)' : '0px 0px 4px 2px rgba(255,255,255,.2)',
            flexGrow: 1,
            display: (loading) ? 'flex' : undefined,
            justifyContent: (loading) ? 'center' : undefined
        },
        totalText: {
            position: 'absolute', 
            height: 20, 
            width: 50, 
            top: 'calc(50%)', 
            left: 'calc(50% - 105px)', 
            fontWeight: 'bold', 
            textAlign: 'center'
        },
        // pieContainer: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     width: 800,
        //     height: 400,
        //     position: 'relative',
        //     selectors:{
        //         '@media only screen and (max-height: 1350px)': {
        //             height: 450,
        //         },
        //         '@media only screen and (max-height: 1150px)': {
        //             height: 400,
        //         },
        //         '@media only screen and (max-height: 950px)': {
        //             height: 350,
        //         },
        //         '@media only screen and (max-height: 750px)': {
        //             height: 300,
        //         },

        //         '@media only screen and (max-width: 1850px)': {
        //             width: 750,
        //         },
        //         '@media only screen and (max-width: 1825px)': {
        //             width: 725,
        //         },
        //         '@media only screen and (max-width: 1800px)': {
        //             width: 700,
        //         },
        //         '@media only screen and (max-width: 1775px)': {
        //             width: 675,
        //         },
        //         '@media only screen and (max-width: 1750px)': {
        //             width: 650,
        //         },
        //         '@media only screen and (max-width: 1700px)': {
        //             width: 635,
        //         },
        //         // '@media only screen and (max-width: 1650px)': {
        //         //     width: 620,
        //         // },
        //         // '@media only screen and (max-width: 1550px)': {
        //         //     width: 600,
        //         // }
        //     },
        // }

            
    });
};